import React, { useState } from "react";
import styles from "./SubscriptionReviewStep.module.css";
import { Button } from "../../shared";
import { BillingDetailsTable } from "../components/BillingDetailsTable";
import { SubscriptionComponent } from "../components/SubscriptionComponent";
import { ActiveSubscription, BillingInformation, SubscriptionPlan } from "../models";
import { createSubscription, updateSubscription } from "../services";
import { InputError } from "../../shared/input/InputError";

interface Props {
  plan: SubscriptionPlan;
  billing: BillingInformation;
  activeSubscription?: ActiveSubscription;
  onBack: () => void;
  onComplete: () => void;
}

export const SubscriptionReviewStep: React.FC<Props> = ({ plan, billing, activeSubscription, onBack, onComplete }) => {
  const [submitError, setSubmitError] = useState("");

  return (
    <div className={styles.root}>
      {submitError && <InputError error={submitError} />}
      <div className={styles.header}>
        <h1>Everything look good before you proceed to pay?</h1>
      </div>
      <div className={styles.container}>
        <SubscriptionComponent
          plan={plan}
          buttonText="Change plan"
          headerText={activeSubscription ? "Your new plan" : "Your plan"}
          onClick={() => onBack()}
          active
        />
        <BillingDetailsTable
          billing={billing}
          onClick={() => onBack()}
        />
      </div>
      <Button
        color="blue"
        title="Pay"
        fullWidth
        className={styles.button}
        onClick={async () => {
          try {
            setSubmitError("");
            if (activeSubscription?.id) {
              await updateSubscription(activeSubscription.id, plan.priceId);
            } else {
              await createSubscription(billing.card.paymentMethodId ?? "", plan.priceId);
            }

            onComplete();
          } catch (err) {
            console.error(err);
            setSubmitError(err.response.data.message ?? "Error occurred");
          }
        }}
      />
    </div>
  );
};
