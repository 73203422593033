import React, { useEffect } from "react";
import { BrowserRouter, Switch, Route, useLocation } from "react-router-dom";
import { ConfirmAccount } from "./app/confirmAccount/ConfirmAccount";
import { DownloadApp } from "./app/downloadApp/DownloadApp";
import { Auth } from "@aws-amplify/auth";
import environment from "./configurations/environment";
import { LandingPage } from "./app/landingPage/LandingPage";
import { MyAccount } from "./app/myAccount/MyAccount";
import { ResetPassword } from "./app/myAccount/ResetPassword";
import { AboutUs } from "./app/aboutUs/AboutUs";
import { Checkout } from "./app/preOrder/Checkout";
import { PreOrder } from "./app/preOrder/PreOrder";
import { NotFound } from "./app/notFound/NotFound";
import { Faq } from "./app/faq/Faq";
import { PrivacyPolicy } from "./app/privacyPolicy/PrivacyPolicy";
import { CookiePolicy } from "./app/cookiePolicy/CookiePolicy";
import { AcceptCookies } from "./app/acceptCookies/AcceptCookies";
import { IncludeScripts } from "./app/includeScripts/IncludeScripts";
import { TermsOfUse } from "./app/termsOfUse/TermsOfUse";
import { TermsOfSale } from "./app/termsOfSale/TermsOfSale";
import { Practitioners } from "./app/practitioners/Practitioners";

Auth.configure({ Auth: environment.amplify.users });

/**
 * Fix for scrolling to the top on route change.
 * Will only scroll to top when new route is not an anchor (e.g. /#subscribe).
 * https://github.com/ReactTraining/react-router/issues/2019#issuecomment-292711226
 */
const ScrollToTop = () => {
  if (!window.location.hash) {
    window.scrollTo(0, 0);
  }
  return null;
};

const Routes = () => {
  const { pathname } = useLocation();
  const userPool = pathname.startsWith("/practitioners") ? "specialists" : "users";

  useEffect(() => {
    Auth.configure({ Auth: environment.amplify[userPool] });
  }, [userPool]);
  
  return (
    <>
      <Route component={ScrollToTop} />
      <Route component={AcceptCookies} />
      <Route component={IncludeScripts} />
      <Switch>
        <Route exact path="/" component={LandingPage} />
        <Route exact path="/confirm" component={ConfirmAccount} />
        <Route exact path="/download" component={DownloadApp} />
        <Route exact path="/my-account/:filter?" component={MyAccount} />
        <Route exact path="/about-us" component={AboutUs} />
        <Route exact path="/faq" component={Faq} />
        <Route exact path="/checkout" component={Checkout} />
        <Route exact path="/order" component={PreOrder} />
        <Route exact path="/reset-password" component={ResetPassword} />
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        <Route exact path="/cookie-policy" component={CookiePolicy} />
        <Route exact path="/terms-use" component={TermsOfUse} />
        <Route exact path="/terms-sale" component={TermsOfSale} />
        <Route exact path="/practitioners/:tab?/:id?" component={Practitioners} />
        <Route component={NotFound} />
      </Switch>
    </>
  );
};

const App = () => (
  <BrowserRouter>
    <Routes />
  </BrowserRouter>
);

export default App;
