import { withFormsy, FormsyInjectedProps } from 'formsy-react';
import React, { useRef, useState } from 'react';
import styles from "./Input.module.css";
import { replaceDateRegexValues } from './utils';
import TooltipImage from "./static/tooltip.png";
import { InputError } from './InputError';

export type InputProps = FormsyInjectedProps<string> & {
  label: string;
  placeholder?: string;
  tooltip?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const FormDate = (props: InputProps) => {
  const [isTooltipOpen, setTooltipOpen] = useState(false);
  // An error message is passed only if the component is invalid
  const { value, errorMessage, label, isRequired, name, isFormSubmitted, placeholder, tooltip, onChange } = props;
  const error = isFormSubmitted && isRequired && !value ? "Info missing!" : isFormSubmitted && errorMessage ? errorMessage : undefined;
  const id = Math.random().toString(36).substring(7);
  let classes = `${styles.root}`;

  if (error) {
    classes += ` ${styles.error}`;
  }

  const ref = useRef(null);

  return (
    <div className={`${classes}`}>
      {label && (
        <div className={styles.label}>
          <label htmlFor={id}>{label}</label>
        </div>
      )}
      <div className={styles.buttonContainer}>
        <input
          ref={ref}
          onChange={(event) => {
            const value = event.currentTarget.value.replace(/\s{1,}/g, ""); // remove current spaces
            props.setValue(value);
            onChange?.(event);
          }}
          maxLength={14}
          placeholder={placeholder || "DD/MM/YYYY"}
          onKeyUp={event => props.setValue(replaceDateRegexValues(event.currentTarget.value))}
          onKeyDown={event => props.setValue(replaceDateRegexValues(event.currentTarget.value))}
          type="text"
          value={value || ''}
          name={name}
          id={id}
        />
        {tooltip && (
          <img
            src={TooltipImage}
            alt="tooltip"
            onClick={() => setTooltipOpen(!isTooltipOpen)}
            onMouseEnter={() => setTooltipOpen(true)}
            onMouseLeave={() => setTooltipOpen(false)}
          />
        )}
        {isTooltipOpen && <span className={styles.tooltipText}>{tooltip}</span>}
      </div>
      {error && <InputError error={error as string} className={styles.errorText} />}
    </div>
  );
};

export default withFormsy(FormDate);
