import React, { ReactNode } from "react";
import styles from "./AppContainer.module.css";
import { Header } from "../header/Header";
import { Container } from "../container/Container";
import { Footer } from "../footer/Footer";

interface Props {
  children: ReactNode;
  containerFullWidth?: boolean;
  className?: string;
}

export const AppContainer: React.FC<Props> = ({ children, containerFullWidth, className }) => {
  let classes = styles.root;

  if (className) {
    classes += ` ${className}`;
  }

  return (
    <div className={classes}>
      <Header />
      <Container fullWidth={containerFullWidth}>
        {children}
      </Container>
      <Footer />
    </div>
  );
};
