import React, { useState } from "react";
import styles from "./Practitioners.module.css";
import { Auth } from "aws-amplify";
import { AppContainer } from "../layout";
import { Button } from "../shared";
import { AmplifyAuthenticator, AmplifySignIn } from "@aws-amplify/ui-react";
import { RouteComponentProps } from "react-router-dom";
import { UsersList } from "./components/UsersList";
import { ScansList } from "./components/ScansLists";
import { ScanAnnotation } from "./components/ScanAnnotation";

interface Params {
  tab?: "scans" | "annotations";
  id?: string;
}


export const Practitioners: React.FC<RouteComponentProps<Params>> = ({ match }) => {
  const [authState, setAuthState] = useState<string>("");
  const { tab, id } = match.params;

  return (
    <AppContainer containerFullWidth={authState === "signedin" ? true : false} className={styles.root}>
      {authState === "signedin" ? (
        <div className={styles.content}>
          {tab === undefined && <UsersList />}
          {tab === "scans" && id && <ScansList userId={id} />}
          {tab === "annotations" && id && <ScanAnnotation userId={id.split("$")[0]} scanId={Number(id.split("$")[1])} />}
          {tab === undefined && (
            <div className={styles.logoutContainer}>
              <Button
                color="red"
                title="Logout"
                onClick={async () => {
                  await Auth.signOut();
                  setAuthState("");
                }}
              />
            </div>
          )}
        </div>
      ) : (
        <div className={styles.login}>
          <AmplifyAuthenticator
            handleAuthStateChange={(state: string, _: any) => {
              setAuthState(state);
            }}
            className={styles.amplify}
          >
            <AmplifySignIn
              headerText="Practitioner login"
              slot="sign-in"
              hideSignUp
              submitButtonText="LOG IN"
              formFields={[{ type: "email" }, { type: "password" }]}
            />
          </AmplifyAuthenticator>
        </div>
      )}
    </AppContainer>
  );
};
