import { default as Axios } from "axios";
import environment from "../configurations/environment";
import { Auth } from "aws-amplify";

export interface Pagination<T> {
  results: T[];
  count: number;
}

// Create axios instances
const configuration = {
  baseURL: `${environment.api}`,
  headers: {
    "Content-Type": "application/json"
  }
};

// Axios with interceptor that fills authorization token
export const axios = Axios.create(configuration);

axios.interceptors.request.use(
  config => {
    return new Promise(async resolve => {
      try {
        const currentSession = await Auth.currentSession();
        config.headers.Authorization = `Bearer ${currentSession.getIdToken().getJwtToken()}`;
        return resolve(config);
      } catch (err) {
        return resolve(config);
      }
    });
  },
  err => {
    return Promise.reject(err);
  }
);

export default axios;
