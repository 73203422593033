import React from "react";
import styles from "./TermsOfSale.module.css";
import { AppContainer } from "../layout";

export const TermsOfSale: React.FC = () => {
  const mailLink = <a href="mailto:hello@uunn.co.uk">hello@uunn.co.uk</a>;

  return (
    <AppContainer>
      <div className={styles.root}>
        <h1>Onaria Technologies Ltd - Terms and Conditions of Sale</h1>
        {/* Section 1 */}
        <div className={styles.section}>
          <p><i>These terms are drafted in English. If there is a conflict between a translated version and the English version of these terms then, to the extent permitted under applicable law, the English version shall prevail.</i></p>
          <p>We are Onaria Technologies Limited (“we/our/us”), a company registered in England and Wales with company number 09750427. Our registered office address is Cambridge House, 16 High Street, Saffron Walden, Essex, England, CB10 1AX. Our registered VAT number is 274 9141 84.</p>
          <p>These terms (the “Terms”), together with the information contained in our Privacy Policy and Terms and Conditions of Website and App Use set out the legal terms that apply when you use our uunn product (the “Product) which consists of a downloadable mobile software application (the “App”), used in conjunction with the uunn hardware device (the “Hardware”), and the legal terms that apply when you place an order for the Product through our Website. You must read these Terms carefully and agree to accept them by clicking “I Accept” before purchasing our Product. If you do not consent to these Terms, you may not purchase or use our Product.</p>
          <p>You can contact our customer service team by writing to us {mailLink}. If we have to contact you we will do so by writing to you at the email address or postal address you provided to us in your order.</p>
          <p><b>Our terms</b></p>
          <p><b>1. Understanding these terms and conditions</b></p>
          <p><b>1.1 Why you should read these Terms.</b> These Terms tell you who we are, how we will provide the Product to you, how you and we may change or end the contract, what to do if there is a problem and other important information. If there is something within these Terms which you don’t understand, please contact us for further information.</p>
          <p><b>1.2 Defined terms.</b> When certain words and phrases are used in these Terms, they have specific meanings (these are known as ‘defined terms’). These defined terms start with capital letters even if they are not at the beginning of a sentence.</p>
          <p><b>1.3 Headings and other information.</b> We have used headings to help you understand these Terms and to easily locate information. These Terms are only available in the English language. We will not file copies of the contract between us and you relating to our supply of the Product.</p>
          <p><b>2. Your orders for the Product and limitations on its use</b></p>
          <p><b>2.1 How we will accept your order request.</b> Our acceptance of your order will take place when we send an email confirming our acceptance of your order to the email address you have provided to us, at which point a contract will come into existence between you and us.</p>
          <p><b>2.2 If we cannot accept your order request.</b> If we are unable to accept your order, we will inform you in writing and will not charge you for the Product. We may reject an order request for several reasons including but not limited to if the Product is unavailable, because of unexpected limits on our resources which we could not reasonably plan for, because we have identified an error in the price or description of the Product, or because we are unable to meet a delivery deadline you have specified.</p>
          <p><b>2.3 Your order number.</b> We will assign an order number to your order and tell you what it is when we accept your order. It will help us if you can tell us the order number whenever you contact us about your order.</p>
          <p><b>2.4 The Product and Product packaging may vary slightly from pictures.</b> The images of the Product and Product packaging displayed on our website are for illustrative purposes only. We make every effort to display the colour, shape and size of the Product and Product packaging accurately but we cannot guarantee that this will be reflected by the display of the device through which you are browsing. As such, your Product may vary slightly to that shown.</p>
          <p><b>2.5 Limits on use of Hardware.</b> You agree that you will:</p>
          <ol type="a">
            <li>not resell the Hardware without our consent, including in conjunction with or as part of any other product;</li>
            <li>not use or access the Hardware to build any product that is similar to or competing with the Product;</li>
            <li>not attempt to copy, modify, duplicate, disassemble, or reverse engineer the Hardware; and</li>
            <li>use the Hardware only for the intended use in conjunction with the App and in the context of using the Product.</li>
          </ol>
          <p><b>3. Your rights to make changes</b></p>
          <p>If you wish to make a change to your order please contact us at {mailLink}. We will let you know if the change is possible. If the change is possible, the price of the Product, the timing of supply and other terms may also change. We will ask you to confirm whether you wish to go ahead with the change. If we cannot make the change or the consequences of making the change are unacceptable to you, you may want to end the contract.</p>
          <p><b>4. Our rights to make changes</b></p>
          <p><b>4.1 Minor changes to the Products.</b> We may change the Products:</p>
          <ol type="a">
            <li>to reflect changes in relevant laws and regulatory requirements, including but not limited to compliance with the EU General Data Protection Regulation, the Data Protection Act 2018, and any other applicable privacy and data protection laws; and</li>
            <li>to implement minor technical adjustments and improvements, for example to improve the functionality of our Product. These changes may affect your use of the Product, which we will endeavour to tell you about in advance.</li>
          </ol>
          <p><b>4.2 Significant changes to the Product and these Terms.</b> As stated in the description of the Product on our website, we may make changes to the Product, but if we do so we will notify you and you may then contact us to end the contract before the changes take effect and receive a refund for any Products paid for but not received.</p>
          <p><b>4.3 Updates to the App.</b> We may update or require you to update the App, provided that the digital content shall always match the description of it that we provided to you before you bought it.</p>
          <p><b>5. Providing the Products</b></p>
          <p><b>5.1 When we will provide the Product.</b> If we accept your order request, we will provide you with an estimated time frame within which the Product will be delivered to you (if you are ordering during out first pre-order campaign, please note that the Product will be delivered and made available to you in March 2020), in line with the following:</p>
          <ol type="a">
            <li>the Hardware – we will deliver the Hardware to you as soon as reasonably possible and in any event within 30 days after the day on which we accept your order; and</li>
            <li>we will make the App available for you to download as soon as we accept your order.</li>
          </ol>
          <p><b>5.2 We are not responsible for delays outside our control.</b> If our supply of the Product is delayed by an event outside our control then we will contact you as soon as possible to let you know and we will take steps to minimise the effect of the delay. Provided we do this we will not be liable for delays caused by the event, but if there is a risk of substantial delay you may contact us to end the contract and receive a refund for any Products you have paid for but not received.</p>
          <p><b>5.3 If you are not at home when the Hardware is delivered.</b> If no one is available at your address to take delivery and the Hardware cannot be posted through your letterbox, our delivery partner will follow the standard procedure it applies in the event that an order cannot be delivered (for instance they may leave you a note informing you of how to rearrange delivery or collect the Hardware from a local depot).</p>
          <p><b>5.4 If you do not re-arrange delivery.</b> If, despite our (or our delivery partner’s) reasonable efforts, we are unable to contact you or re-arrange delivery or collection we may end the contract. We may charge you for storage costs and any further delivery costs.</p>
          <p><b>5.5 Your legal rights if the Product is late.</b> You have legal rights if the Product is late. This could be either because we deliver the Hardware late, or are late in making available the App. If we miss the estimated delivery time frame agreed for the Hardware or do not make the App available within the agreed timeframe then you may treat the contract as at an end straight away if any of the following apply:</p>
          <ol type="a">
            <li>we have refused to deliver the Hardware or make the App available to you within an estimated timeframe agreed by us;</li>
            <li>delivery within the estimated delivery time frame was essential (taking into account all the relevant circumstances), and we agreed to deliver the Hardware and make the App available to you within that estimated timeframe; or</li>
            <li>you told us before we accepted your order that delivery within the estimated delivery time frame was essential and we agreed to deliver the Hardware and make the App available to you within that estimated timeframe.</li>
          </ol>
          <p>If you do choose to end the contract for late delivery under paragraph 5.5 (a) – (c) you can do so whether or not the Product has been delivered to you or the App has been made available. If the Hardware has been delivered to you, you must ensure that it is returned to us. After that we will refund any sums you have paid to us for the cancelled Product and its delivery. In the event that paragraph 5.5 (a) – (c) applies, you may choose to rearrange delivery instead of ending the contract. To rearrange delivery please contact us via email at {mailLink}.</p>
          <p><b>5.6 When you become responsible for the Hardware.</b> The Hardware will be your responsibility from the time we deliver it to the address provided to us. You will own the Hardware once we have received payment for the Product in full.</p>
          <p><b>5.7 What will happen if you do not give required information to us.</b> We may need certain information from you so that we can accept your order and provide the Product to you, for example, your name, your email address, telephone number, credit card number, the expiration date of your credit card, billing address and delivery address. Further information relating to how we collect, manage, store and process personal data can be found in our privacy policy. If you do not provide this information to us at the time of placing an order, or within a reasonable time of us asking for it, or if you give us incomplete or incorrect information, we may end the contract. We will not be responsible for supplying the Product late if this is caused by you not giving us the information we need within a reasonable time of us asking for it.</p>
          <p><b>5.8 Reasons we may delay providing the Product to you.</b> We may have to delay supplying the Product to you or suspend the availability of the App if:</p>
          <ol type="a">
            <li>you fail to make payment to us for the Product and we have reasonable belief that you may not pay the amounts you owe, and we have requested you to explain the position and you have failed to do so satisfactorily;</li>
            <li>if we suspect any order or transaction to be fraudulent or suspicious;</li>
            <li>we choose to deal with technical problems or make minor technical changes to the Product; and/or</li>
            <li>we choose to update the Product to reflect changes in relevant laws and regulatory requirements.</li>
          </ol>
          <p>We will contact you in advance to tell you we will be delaying supply of the Product or suspending availability of the App, unless the problem is urgent or an emergency. If we have to delay supplying you the Product or suspend the App for longer than 30 days you may contact us to end the contract, and we will refund any sums you have paid in advance for the Product in respect of the period after you end the contract (where this is because we are suspending the availability of the App, this will be a pro rata amount that takes into account the period for which you have had use of the Product).</p>
          <p><b>6. Your rights to end the contract</b></p>
          <p><b>6.1 You may end the contract with us for any of the following reasons:</b></p>
          <ol type="a">
            <li>the Product is faulty or not as described (see paragraph 6.2);</li>
            <li>we have done something or have told you we are going to do something listed in paragraph 6.3; or</li>
            <li>you have changed your mind about the Product (see paragraph 6.4).</li>
          </ol>
          <p>However, you don’t have the right to change your mind in relation to products sealed for health protection or hygiene purposes (such as the uunn lens), once this has been unsealed after you receive it.</p>
          <p><b>6.2 If the Product is faulty or not as described.</b> If you have any questions or complaints about the product, please contact us. You can write to us at {mailLink} AND Cambridge House, 16 High Street, Saffron Walden, Essex, England, CB10 1AX. We are under a legal duty to supply products that are in conformity with this contract. See below for a summary of your key legal rights in relation to the product. Nothing in these Terms will affect your legal rights.</p>
          <p><b>Summary of your key legal rights</b></p>
          <p>This is a summary of your key legal rights. These are subject to certain exceptions. For detailed information please visit the Citizens Advice website www.adviceguide.org.uk or call 03454 04 05 06.</p>
          <p>In respect of the Hardware, this would be classified as “goods” for the purposes of the Consumer Rights Act 2015, which says that goods must be as described, fit for purpose and of satisfactory quality. During the expected lifespan of your Hardware your legal rights entitle you to the following:</p>
          <ol type="a">
            <li>Up to 30 days: if your Hardware is faulty, then you can get an immediate refund.</li>
            <li>Up to six months: if your Hardware can't be repaired or replaced, then you're entitled to a full refund, in most cases.</li>
            <li>Up to six years: if your Hardware does not last a reasonable length of time you may be entitled to some money back.</li>
          </ol>
          <p>In respect of the App, this would be classified as “digital content” for the purposes of the Consumer Rights Act 2015, which says that digital content must be as described, fit for purpose and of satisfactory quality:</p>
          <ol type="a">
            <li>If the App is faulty, you're entitled to a repair or a replacement.</li>
            <li>If the fault can't be fixed, or if it hasn't been fixed within a reasonable time and without significant inconvenience, you can get some or all of your money back.</li>
            <li>If you can show the fault has damaged your device and we haven't used reasonable care and skill, you may be entitled to a repair or compensation.</li>
          </ol>
          <p>If you wish to exercise your legal rights to reject the Product you must post the Hardware back to us. We will pay the costs of postage or collection.</p>
          <p><b>6.3 Ending the contract because of something we have done or are going to do.</b> You may be entitled to end the contract immediately, and we may have to refund you in full for any Product which has not been provided if we have done or are going to do any of the following:</p>
          <ol type="a">
            <li>we have told you about an upcoming significant change to the Product or these Terms which you do not agree to;</li>
            <li>we have told you about an error in the price of the Product you have ordered and you do not wish to proceed;</li>
            <li>there is a risk that supply of the Product may be significantly delayed because of events outside our control;</li>
            <li>we have suspended or delayed supply of the Product or the availability of the App for technical reasons, or notify you we are going to suspend supply for technical reasons, in each case for a period of more than 30 days;</li>
            <li>you have a legal right to end the contract because we breached our contract with you for the supply of the Product.</li>
          </ol>
          <p><b>6.4 If you are exercising your right to change your mind under the Consumer Contract Regulations 2013.</b> For the majority of products purchased online (including the Product) you have a legal right to change your mind within 14 days of receipt, and are entitled to receive:</p>
          <ol type="a">
            <li>a full refund if you haven’t unsealed the uunn lens (Hardware).</li>
            <li>a part refund if you’ve only used 1 scan within 14 days of receiving The Starter Pack, however, we will deduct and retain the cost of the uunn lens (Hardware) and its postage back to us.</li>
          </ol>
          <p><b>7. How to end the contract with us</b></p>
          <p><b>7.1 Tell us you want to end the contract.</b> To end the contract with us, please let us know by emailing us at {mailLink}. Please provide your name, home address, details of the order and, where available, your phone number and email address.</p>
          <p><b>7.2 Returning products after ending the contract.</b> If you end the contract for any reason after Hardware has been dispatched to you or you have received it, you must return it to us by post. If you are exercising your right to change your mind you must send off the Hardware within 14 days of telling us you wish to end the contract.</p>
          <p><b>7.3 When we will pay the costs of return.</b> We will pay the costs of return:</p>
          <ol type="a">
            <li>if the Product is faulty or not as described; or</li>
            <li>if you are ending the contract because we have told you of an upcoming change to the Product or these Terms, an error in pricing or description, a delay in delivery due to events outside our control or because you otherwise have a legal right to do so.</li>
          </ol>
          <p>In all other circumstances you must pay the costs of return. In those circumstances, if we initially pay the cost of return, we may deduct that cost from any amount to be refunded to you.</p>
          <p><b>7.4 How we will refund you.</b> We will refund you the price you paid for the Product including delivery costs, by the method you used for payment. However, we may make deductions from the price, as described below.</p>
          <p><b>7.5 We may be entitled to make deductions to your refund if you are exercising your right to change your mind.</b> If you are exercising your right to change your mind:</p>
          <ol type="a">
            <li>We may reduce your refund of the price (excluding delivery costs) to reflect any reduction in the value of the Product, if this has been caused by your handling it in a way which would not be permitted. If we refund you the price paid before we are able to inspect the Hardware and later discover you have handled it in an unacceptable way, you must pay us an appropriate amount.</li>
            <li>The maximum refund for delivery costs will be the costs of delivery by the least expensive delivery method we offer.</li>
          </ol>
          <p><b>7.6 When your refund will be made.</b> We will make any refunds due to you as soon as possible. If you are exercising your right to change your mind then we will refund you within 14 days from the date on which we receive the Hardware back from you and, in all other cases, within 14 days of you telling us you have changed your mind.</p>
          <p><b>8. Our rights to end the contract</b></p>
          <p><b>8.1 We may end the contract if you break it.</b> We may end the contract for a Product at any time by writing to you if:</p>
          <ol type="a">
            <li>you do not make any payment to us when it is due and you still do not make payment within 14 days of us reminding you that payment is due;</li>
            <li>you do not, within a reasonable time of us asking for it, provide us with information that is necessary for us to provide the Product, for example, your delivery address; or</li>
            <li>you do not, within a reasonable time, allow our delivery partner to deliver the Product to you.</li>
          </ol>
          <p><b>8.2 You must compensate us if you break the contract.</b> If we end the contract in the situations set out in paragraph 8.1 we will refund any money you have paid in advance for the Product where we have not provided the Product but we may deduct or charge reasonable compensation for the net costs we will incur as a result of your breaking the contract.</p>
          <p><b>9. Price and payment</b></p>
          <p><b>9.1 Where to find the price for the Product.</b> The price of the Product will be the price indicated on the order page on our website when you place your order.</p>
          <p><b>9.2 Changes to the price.</b> We take all reasonable care to ensure that the price of the Product advised to you is correct but it is always possible that, despite our best efforts, the Product may be incorrectly priced. If the Product’s correct price at your order date is higher than the price stated to you we will contact you for your instruction before accepting the order request.</p>
          <p><b>9.3 We will pass on changes in the rate of VAT</b>. If the rate of VAT changes or the rate of sales tax changes between your order date and the date we supply the Product, we will adjust the rate of VAT or the sales taxes that you pay, unless you have already paid for the Product in full before the change in the rate of VAT or the sales tax takes effect.</p>
          <p><b>9.4 When you must pay and how you must pay.</b> All payments for the Product through our website are processed through Stripe, a third party payment provider unconnected to our Product or website. You must make payment for the Product prior to us providing it to you. You represent and warrant that i) you have the legal right to use any credit card(s) or other payment methods in connection with the purchase of the Product, and ii) that the information you supply to us is true, correct and complete. You hereby agree that we are not responsible for any loss or damage arising from submission of false or inaccurate information.</p>
          <p><b>10. Our responsibility for loss or damage suffered by you</b></p>
          <p><b>10.1 We are responsible to you for foreseeable loss and damage caused by us.</b> If we fail to comply with these Terms, we are responsible for loss or damage you suffer that is a foreseeable result of our breaking this contract or our failing to use reasonable care and skill, but we are not responsible for any loss or damage that is not foreseeable. Loss or damage is foreseeable if either it is obvious that it will happen or if, at the time the contract was made, both we and you knew it might happen, for example, if you discussed it with us during the sales process.</p>
          <p><b>10.2 We do not exclude or limit in any way our liability to you where it would be unlawful to do so.</b> This includes, but may not be limited to, liability for death or personal injury caused by our negligence or the negligence of our employees, agents or subcontractors; for fraud or fraudulent misrepresentation; for breach of your legal rights in relation to the Product as summarised at paragraph 6.2.</p>
          <p><b>10.3 When we are liable for damage to your property.</b> If the App is defective and damages a device or digital content belonging to you and this is caused by our failure to use reasonable care and skill we will either repair the damage or pay you compensation. However, we will not be liable for damage which you could have avoided by following our advice to apply an update offered to you free of charge or for damage which was caused by you failing to correctly follow installation instructions or to have in place the minimum system requirements advised by us.</p>
          <p><b>10.4 We are not liable for business losses.</b> We only supply the Product for domestic and private use. If you use the Product for any commercial, business or re-sale purpose we will have no liability to you for any loss of profit, loss of business, business interruption, or loss of business opportunity.</p>
          <p><b>10.5 Cap on damages.</b> Except where otherwise provided under applicable law, our aggregate liability for damages under these Terms to you or any third party, regardless of the nature of the claim, shall not exceed the fees paid or payable by you for the Product under these Terms.</p>
          <p><b>10.6 Limitations on use of the Product.</b> We care about your health and all the information we provide is intended to help you make the best decisions about your own care and wellbeing.</p>
          <p>The Product aims to enable customers to better understand how well they are brushing their teeth. It does this by identifying any teeth that have early-level plaque located on them, quantifying the percentage of plaque on the teeth on the facial labial and facial buccal surfaces that can be photographed using the Hardware at that time, as well as providing an aggregate of these plaque percentages. The sole purpose of this is to help the user understand possible lifestyle interventions that may help to reduce plaque levels.</p>
          <p>However, no two customers are the same, and advice that may be right for one user may not be right for another. There is no replacement for specialist medical advice from a qualified professional and we strongly recommend you seek such advice before using the Product, or relying on the information provided via our App. This is particularly important if you have any known pre-existing conditions.</p>
          <p>The information provided by the App does not constitute medical advice and/or recommendations for dental/medical treatment, but instead focuses on general dental care and lifestyle advice.</p>
          <p>The Product should only be used by adults over 16 years old and residents in the UK.</p>
          <p>By agreeing to these Terms you agree that you will seek specialist medical advice from a qualified professional before using the Product and before relying on information provided via the App.</p>
          <p><b>11. Other important terms</b></p>
          <p><b>11.1 We may transfer this agreement to someone else.</b> Subject to the terms of the Privacy Policy, we may transfer our rights and obligations under these Terms to another organisation. We will always tell you in writing if this happens and we will ensure that the transfer will not affect your rights under the contract.</p>
          <p><b>11.2 You need our consent to transfer your rights to someone else.</b> You may only transfer your rights or your obligations under these Terms to another person or entity if we agree to this in writing.</p>
          <p><b>11.3 Nobody else has any rights under this contract.</b> This contract is between you and us. No other person shall have any rights to enforce any of these Terms.</p>
          <p><b>11.4 If a court finds part of this contract illegal, the rest will continue in force.</b> Each of the paragraphs of these Terms operates separately. If any court or relevant authority decides that any of them are unlawful, the remaining paragraphs will remain in full force and effect.</p>
          <p><b>11.5 Even if we delay in enforcing this contract, we can still enforce it later.</b> If we do not insist immediately that you do anything you are required to do under these Terms, or if we delay in taking steps against you in respect of your breaking this contract, that will not mean that you do not have to do those things and it will not prevent us taking steps against you at a later date.</p>
          <p><b>11.6 Which laws apply to this contract and where you may bring legal proceedings.</b> These Terms are governed by English law and you can bring legal proceedings in respect of the products in the English courts. If you live in Scotland you can bring legal proceedings in respect of the products in either the Scottish or the English courts. If you live in Northern Ireland you can bring legal proceedings in respect of the products in either the Northern Irish or the English courts.</p>
        </div >
      </div >
    </AppContainer >
  );
};
