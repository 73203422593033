import React, { ReactNode, useState } from "react";
import { Popup } from "../../shared";
import { BillingDetailsForm } from "./BillingDetailsForm";
import { CognitoUser } from "amazon-cognito-identity-js";
import { updateCardNumber } from "../services";
import { SUB_FIELD } from "../models";
import { InputError } from "../../shared/input/InputError";

interface Props {
  user: CognitoUser;
  trigger: ReactNode;
  attributes: { [key: string]: string };
  onComplete: () => void;
}

export const BillingDetailsPopup: React.FC<Props> = ({ user, trigger, attributes, onComplete }) => {
  const [submitError, setSubmitError] = useState("");

  return (
    <Popup
      trigger={trigger}
      headerText="Update your billing details below"
    >
      {close => (
        <>
          {submitError && <InputError error={submitError} />}
          <BillingDetailsForm
            user={user}
            attributes={attributes}
            onComplete={async billing => {
              try {
                setSubmitError("");
                await updateCardNumber(attributes[SUB_FIELD], billing.card.paymentMethodId ?? "");
                onComplete();
                close();
              } catch (err) {
                console.error(err);
                setSubmitError(err.response.data.message ?? "Error occurred");
              }
            }}
          />
        </>
      )}
    </Popup>
  );
};
