import React from "react";
import { AppContainer } from "../layout";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { default as CheckoutForm } from "./components/CheckoutForm";
import environment from "../../configurations/environment";
import { useAppSelector } from "../../store/hooks";
import styles from "./Checkout.module.css";
import { Link } from "react-router-dom";

const stripePromise = loadStripe(environment.stripeKey);

export const Checkout = () => {
  const cartCounter = useAppSelector(state => state.cartCounter.value);

  return (
    <AppContainer containerFullWidth>
      {cartCounter === 0 ? (
        <div className={styles.root}>
          <h1>Your cart</h1>
          <h3 className={styles.lightBlue}>You haven’t added any <Link to="/order" className={styles.link}>starter packs</Link> to your cart!</h3>
        </div>
      ) : (
        <Elements stripe={stripePromise}>
          <CheckoutForm />
        </Elements>
      )}
    </AppContainer>
  );
};
