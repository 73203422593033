export const replaceDateRegexValues = (value: string) => {
  const newValue = value
    .replace(/\s{1,}/g, "") // remove current spaces
    .replace(/(\d+)(\/)$/g, "$1") // remove ending /
    .replace(/^(\d\d)(\d)$/g, "$1/$2")
    .replace(/^(\d\d\/\d\d)(\d+)$/g, "$1/$2")
    .replace(/[^\d/]/g, "");

  return newValue;
};
