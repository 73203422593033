import { Component } from "react";
import environment from "../../configurations/environment";

export class IncludeScripts extends Component {
  constructor(props) {
    super(props);

    // Include scripts
    this.includeStatisticScripts();
  }

  includeStatisticScripts() {
    if (environment.googleTagManagerKey) {
      const scriptGoogleTagManager = document.createElement("script");
      scriptGoogleTagManager.text = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${environment.googleTagManagerKey}');`;
      document.head.appendChild(scriptGoogleTagManager);
    }
    if (environment.hotjarKey) {
      const script = document.createElement("script");
      script.text = `(function(h,o,t,j,a,r){ h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)}; h._hjSettings={hjid:${environment.hotjarKey},hjsv:6}; a=o.getElementsByTagName('head')[0]; r=o.createElement('script');r.async=1; r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv; a.appendChild(r); })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;
      document.head.appendChild(script);
    }
  }

  render() {
    return null;
  }
};
