import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface CartCounterState {
  value: number;
}

const initialState: CartCounterState = {
  value: 0
};

export const cartCounterSlice = createSlice({
  name: "cartCounter",
  initialState,
  reducers: {
    set: (state, action: PayloadAction<number>) => {
      state.value = action.payload;
    },
    reset: state => {
      state.value = 0;
    },
  },
});

export const { set, reset } = cartCounterSlice.actions;

export default cartCounterSlice.reducer;
