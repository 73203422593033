import React, { useEffect, useState } from "react";
import { BillingDetailsPopup } from "./forms/BillingDetailsPopup";
import styles from "./BillingDetails.module.css";
import { CognitoUser } from "amazon-cognito-identity-js";
import { BillingDetailsTable } from "./components/BillingDetailsTable";
import { ActiveSubscription, ADDRESS_FIELD, CARD_FIELD, CITY_FIELD, COUNTRY_FIELD, NAME_FIELD, POSTAL_CODE_FIELD, STATE_FIELD } from "./models";
import { getBalance } from "./services";

interface Props {
  user: CognitoUser;
  attributes: { [key: string]: string };
  refreshAttributes: () => void;
}

export const BillingDetails: React.FC<Props> = ({ user, attributes, refreshAttributes }) => {
  const [subscription, setSubscription] = useState<ActiveSubscription | undefined>(undefined);

  useEffect(() => {
    const fetchBalance = async () => {
      const { subscription } = await getBalance();
      setSubscription(subscription);
    };
    fetchBalance();
  }, []);
  const formatBillingDate = (date: string): string => {
    const d = new Date(date);
    return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
  };

  return (
    <BillingDetailsPopup
      trigger={
        <div className={styles.root}>
          <h1>Billing details</h1>
          {subscription && (
            <div className={styles.subscription}>
              {subscription.nextBillingDate && (subscription.status === "Active" || subscription.status === "PaymentFailed") && (
                <h4 style={{ color: subscription.status === "PaymentFailed" ? "#BD0000" : undefined }}>Your next charge date: <b>{formatBillingDate(subscription.nextBillingDate)}</b></h4>
              )}
              {subscription.status === "PaymentFailed" && (
                <div className={styles.paymentFailed}>Your scan plan isn’t currently active. To continue receiving scans each month, please check that your payment card has sufficient funds, if it's still valid or whether your details need updating.</div>
              )}
            </div>
          )}
          <BillingDetailsTable
            billing={{
              name: attributes[NAME_FIELD],
              address: {
                line: attributes[ADDRESS_FIELD],
                city: attributes[CITY_FIELD],
                stateProvence: attributes[STATE_FIELD],
                postalCode: attributes[POSTAL_CODE_FIELD],
                country: attributes[COUNTRY_FIELD]
              },
              card: {
                last4: attributes[CARD_FIELD]
              }
            }}
          />
        </div>
      }
      user={user}
      attributes={attributes}
      onComplete={() => refreshAttributes()}
    />
  );
};
