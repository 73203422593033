import React, { Component } from "react";
import styles from "./SubscribeNewsletterForm.module.css";
import { Button, Input } from "../../shared";
import { subscribeNewsletter } from "../services";
import { validEmail } from "../../utils";

export class SubscribeNewsletterForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      disabled: false,
      showSuccessMessage: false,
      email: "",
      error: "",
    };

    this.handleChange = this.handleChange.bind(this);
  }

  async handleSubmit() {
    const { email } = this.state;

    if (this.checkValidForm()) {
      this.setState({ disabled: true }, () => {
        subscribeNewsletter(email, undefined, undefined, true)
          .then(() => this.setState({ disabled: false, showSuccessMessage: true, error: "", email: "" }))
          .catch((error) => {
            console.error("error", error);
            const errorMessage = "You’ve already signed up. Hurrah!"; // error.response && error.response.data ? error.response.data.message : error.message;
            this.setState({ disabled: false, error: errorMessage });
          });
      });
    }
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value, showSuccessMessage: false });
    return true;
  }

  checkValidForm() {
    const { email } = this.state;

    if (email.length === 0 || !validEmail(email)) {
      return false;
    }

    return true;
  }

  render() {
    const { error, email, disabled, showSuccessMessage } = this.state;
    return (
      <div className={styles.root}>
        <div className={styles.form}>
          <div>
            <Input
              name="email"
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={this.handleChange}
              error={error}
            />
            {showSuccessMessage && <span>Thank you</span>}
          </div>
          <Button
            color="blue"
            variant="borderless"
            title="Sign me up"
            onClick={() => this.handleSubmit()}
            disabled={disabled}
            fullWidth
            className={styles.button}
          />
        </div>
      </div>
    );
  }
}
