module.exports = {
  production: false,
  protocol: "https",
  host: "0.0.0.0",
  port: "80",
  api: "https://38quddlk07.execute-api.eu-west-1.amazonaws.com/staging",
  amplify: {
    users: {
      region: "eu-west-1",
      userPoolId: "eu-west-1_ZvLRKOyEY",
      userPoolWebClientId: "6uqmjc0gc459bsmvl8tuojp6ln",
    },
    specialists: {
      region: "eu-west-1",
      userPoolId: "<REPLACE>",
      userPoolWebClientId: "<REPLACE>",
    }
  },
  stripeKey: "pk_test_7l5hpeDbjZVPd7TJs9DwHWn000Nus9E5gV",
  subscriptionPlanIds: {
    plan1: "<REPLACE>",
    plan2: "<REPLACE>",
    plan3: "<REPLACE>"
  },
  //Intentionally left blank
  googleTagManagerKey: "",
  hotjarKey: ""
};
