import Formsy from "formsy-react";
import React, { ReactNode } from "react";
import styles from "./Forms.module.css";
import { Button, Popup } from "../../shared";
import { Auth } from "aws-amplify";
import FormPassword from "../../shared/input/FormPassword";
import { validPasswordRegex } from "../../utils";
import { CognitoUser } from "amazon-cognito-identity-js";

interface Props {
  user: CognitoUser;
  trigger: ReactNode;
}

export const PasswordPopup: React.FC<Props> = ({ user, trigger }) => {
  return (
    <Popup
      trigger={trigger}
      headerText="Update your password below"
    >
      {close => (
        <Formsy
          onValidSubmit={async (model, _, invalidate) => {
            try {
              await Auth.changePassword(user, model.currentPassword, model.password);
              close();
            } catch (err) {
              console.error(err);
              invalidate({ currentPassword: err.message ?? "Error" });
            }
          }}
        >      
          <div className={styles.root}>  
            <div className={styles.halfWidth}>
              <FormPassword
                name="currentPassword"
                label="Current password"
                required
              />
            </div>
            <Button color="transparent" title="Forgot password?" to="/reset-password" className={styles.marginBottom} />
            <div className={styles.halfWidth}>
              <FormPassword
                name="password"
                label="Password"
                validations={{
                  matchRegexp: validPasswordRegex()
                }}
                validationError="Please choose an 8-character password with one upper case letter, one lower case letter, one number and one special character."
                required
              />
              <FormPassword
                name="confirmPassword"
                label="Repeat password"
                validations={{ 
                  equalsField: "password",
                  matchRegexp: validPasswordRegex()
                }}
                validationErrors={{ 
                  equalsField: "Passwords don't match",
                  matchRegexp: "Please choose an 8-character password with one upper case letter, one lower case letter, one number and one special character."
                }}
                required
              />
            </div>
            <div className={styles.buttons}>
              <Button
                type="submit"
                color="blue"
                title="Set new password"
              />
            </div>
          </div>
        </Formsy>
      )}
    </Popup>
  );
};
