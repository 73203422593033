import React, { ReactNode } from "react";
import styles from "./Container.module.css";

interface Props {
  children: ReactNode;
  fullWidth?: boolean;
}

export const Container: React.FC<Props> = ({ children, fullWidth }) => {
  let classes = styles.root;

  if (fullWidth) {
    classes += ` ${styles.fullWidth}`;
  } else {
    classes += ` ${styles.fixedWidth}`;
  }

  return (
    <div className={classes}>
      {children}
    </div>
  );
};
