import Formsy from "formsy-react";
import React from "react";
import { useAppDispatch } from "../../../store/hooks";
import { set } from "../../layout/header/reducers/cartCounterSlice";
import { Button } from "../../shared";
import FormCheckBox from "../../shared/checkbox/FormCheckBox";
import styles from "./CartStep.module.css";
import QuantitySelector from "./QuantitySelector";

export interface Product {
  name: string,
  value: string,
  amount: number,
  price: number
}

type CartStepProps = {
  loading: boolean,
  products: Array<Product>,
  selectedProduct?: Product,
  submitStep: (step: number) => void,
  updateProduct: (product: Product) => void
}

const getProductByName = (value: string, products: Array<Product>) => {
  let product = products.filter((product) => product.value === value)[0];

  return product;
};

const CartStep = (props: CartStepProps) => {
  const dispatch = useAppDispatch();

  return (
    <Formsy
      onValidSubmit={() => {
        props.submitStep(1);
      }}
    >
      <div className={styles.root}>
        {props.selectedProduct && (
          <QuantitySelector
            selectedProduct={props.selectedProduct}
            products={props.products}
            onChange={productId => {
              const product = getProductByName(productId, props.products);
              props.updateProduct(product);
              dispatch(set(product.amount));
            }}
            disabled={props.loading}
          />
        )}

        <hr />

        <div className={styles.subTotal}>
          <div>
            <h3>Sub total</h3>
            <p className={styles.lightBlue}>Shipping not yet calculated</p>
          </div>
          <h3>
            £{props.selectedProduct && props.selectedProduct.price / 100}
          </h3>
        </div>

        <hr />

        <div className={styles.checkout}>
          <div>
            <h4>Before you click next please confirm the below</h4>
            <FormCheckBox
              name="wearBracesCheck"
              validations="isTrue"
              validationError="You must agree">
              <span className={styles.lightBlue}>I am aware that uunn isn’t compatible with people who wear braces.</span>
            </FormCheckBox>
            <FormCheckBox
              name="modelsCheck"
              validations="isTrue"
              validationError="You must agree">
              <span className={styles.lightBlue}>I am aware of the phone models that uunn is compatible with.</span>
            </FormCheckBox>
            <FormCheckBox
              name="over16Check"
              validations="isTrue"
              validationError="You must agree">
              <span className={styles.lightBlue}>I am aware that you can only use uunn if you are over 16.</span>
            </FormCheckBox>
          </div>
          <Button
            type="submit"
            color="blue"
            title="Checkout"
            fullWidth
          />
        </div>

        <hr />
      </div>
    </Formsy>
  );
};

export default CartStep;
