import React, { Component } from "react";
import styles from "./Header.module.css";
import "./animations.css";
import { Visibility } from "../../layout";
import { Button } from "../../shared";
import Popup from "reactjs-popup";
import { ReactComponent as LogoImage } from "./static/uunn_logo_white.svg";
import { ReactComponent as InstagramImage } from "./static/instagram.svg";
import { ReactComponent as LinkedinImage } from "./static/linkedin.svg";
// import { ReactComponent as TwitterImage } from "./static/twitter.svg";
import { ReactComponent as AccountImage } from "./static/account.svg";
import { MenuButton } from "./components/MenuButton";
import { CartButton } from "./components/CartButton";

export class Header extends Component {
  state = {
    isMenuActive: false,
  };

  setMenuVisibility(isMenuActive) {
    const { onMenuVisibilityChange } = this.props;

    this.setState({ isMenuActive }, () => {
      if (onMenuVisibilityChange) {
        onMenuVisibilityChange(isMenuActive);
      }
    });
  }

  render() {
    const { className, color } = this.props;
    const { isMenuActive } = this.state;
    let classes = styles.root;

    if (className) {
      classes += ` ${className}`;
    }
    if (color === "white" || isMenuActive) {
      classes += ` ${styles.navbarWhite}`;
    }

    return (
      <>
        <div id="navbar" className={classes}>
          <div className={styles.left}>
            <MenuButton
              active={isMenuActive}
              color={color === "white" || isMenuActive ? "white" : "blue"}
              onClick={() => this.setMenuVisibility(!isMenuActive)}
            />
          </div>
          <div className={styles.center}>
            {(color !== "white" || isMenuActive) && (
              <Button
                color="transparent"
                icon={LogoImage}
                to="/"
                onClick={() => this.setMenuVisibility(false)}
              />
            )}
          </div>
          <div className={styles.right}>
            <Visibility size="small" type="hidden">
              <Button
                color="cyan"
                variant="borderless"
                title="I want uunn"
                to="/order"
                className={styles.button}
                onClick={() => this.setMenuVisibility(false)}
              />
            </Visibility>
            <div className={styles.actions}>
              <Button
                color="transparent"
                icon={AccountImage}
                to="/my-account"
              />
              <CartButton />
            </div>
          </div>
        </div>
        <Popup
          open={isMenuActive}
          onClose={() => this.setMenuVisibility(false)}
          modal
          closeOnDocumentClick
          closeOnEscape
          overlayStyle={{
            marginLeft: 0,
            zIndex: 98
          }}
          contentStyle={{
            background: "#201574",
            width: "100%",
            height: "100%",
            border: 0,
            animation: "fadein 0.4s",
          }}
        >
          {_ => (
            <div className={styles.menuPopup}>
              <div className={styles.container}>
                <div className={styles.navigation}>
                  <Button
                    color="transparent"
                    title="Home"
                    to="/"
                    className={styles.button}
                    onClick={() => this.setMenuVisibility(false)}
                  />
                  <Button
                    color="transparent"
                    title="About Us"
                    to="/about-us"
                    className={styles.button}
                    onClick={() => this.setMenuVisibility(false)}
                  />
                  <Button
                    color="transparent"
                    title="My Account"
                    to="/my-account"
                    className={styles.button}
                    onClick={() => this.setMenuVisibility(false)}
                  />
                  <Button
                    color="transparent"
                    title="FAQ's"
                    to="/faq"
                    className={styles.button}
                    onClick={() => this.setMenuVisibility(false)}
                  />
                  <Button
                    color="transparent"
                    title="Contact"
                    to="mailto:hello@uunn.co.uk"
                    className={styles.button}
                    onClick={() => this.setMenuVisibility(false)}
                    newWindow
                  />
                </div>
                <div className={styles.social}>
                  <Button
                    color="transparent"
                    icon={InstagramImage}
                    to="https://www.instagram.com/we.are.uunn"
                    className={styles.button}
                    newWindow
                  />
                  <Button
                    color="transparent"
                    icon={LinkedinImage}
                    to="https://www.linkedin.com/company/uunn"
                    className={styles.button}
                    newWindow
                  />
                  {/* // TODO: Add on v2
                <Button
                  color="transparent"
                  icon={TwitterImage}
                  to="https://twitter.com/we_are_uunn"
                  className={styles.button}
                  newWindow
                /> */}
                </div>
              </div>
            </div>  
          )}
        </Popup>
      </>
    );
  }
}
