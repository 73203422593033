import { useStripe, useElements, CardNumberElement } from "@stripe/react-stripe-js";
import Formsy from "formsy-react";
import React, { ReactNode } from "react";
import FormInput from "../../shared/input/FormInput";
import FormSelect from "../../shared/select/FormSelect";
import styles from "./Forms.module.css";
import { Button } from "../../shared";
import CountryOptions from "../../shared/select/models/countries.json";
import { Auth } from "aws-amplify";
import { ADDRESS_FIELD, BillingInformation, CARD_FIELD, CITY_FIELD, COUNTRY_FIELD, NAME_FIELD, POSTAL_CODE_FIELD, STATE_FIELD } from "../models";
import FormCardNumber from "../../shared/input/FormCardNumber";
import { CognitoUser } from "amazon-cognito-identity-js";
import { Elements } from "@stripe/react-stripe-js";
import environment from "../../../configurations/environment";
import { loadStripe } from "@stripe/stripe-js";
import FormCardExpiry from "../../shared/input/FormCardExpiry";
import FormCardCvc from "../../shared/input/FormCardCvc";

interface Props {
  user: CognitoUser;
  attributes: { [key: string]: string };
  onComplete: (billing: BillingInformation) => void;
  actions?: ReactNode;
}

const BillingDetailsFormBase: React.FC<Props> = ({ user, attributes, onComplete, actions }) => {
  const stripe = useStripe();
  const elements = useElements();

  return (
    <Formsy
      onValidSubmit={async (model, _, invalidate) => {
        if (!stripe || !elements) {
          // Stripe.js has not loaded yet. Make sure to disable
          // form submission until Stripe.js has loaded.
          return invalidate({ cardNumber: "Error" });
        }

        const cardNumberElement = elements.getElement(CardNumberElement);

        if (!cardNumberElement) {
          return invalidate({ cardNumber: "No card element found" });
        }

        // @ts-ignore
        const { token, error } = await stripe.createToken(cardNumberElement, {
          "name": model.nameCard,
          "address_line1": model.line1,
          "address_city": model.city,
          "address_zip": model.postalCode,
          "address_country": model.country
        });
        
        if (error) {
          console.error("Error creating stripe token", error);

          if (error.code?.includes("number")) {
            return invalidate({ cardNumber: error.message, cardExpiry: undefined, cardCvc: undefined });
          }
          else if (error.code?.includes("expiry")) {
            return invalidate({ cardNumber: undefined, cardExpiry: error.message, cardCvc: undefined });
          }
          else if (error.code?.includes("cvc")) {
            return invalidate({ cardNumber: undefined, cardExpiry: undefined, cardCvc: error.message });
          }

          return invalidate({ cardNumber: error.message ?? "Error" });
        } 

        if (token?.id) {
          const { paymentMethod } = await stripe.createPaymentMethod({
            type: "card",
            card: { token: token.id }
          });

          if (paymentMethod?.id) {
            try {
              const last4 = token.card?.last4 ?? "****";
              await Auth.updateUserAttributes(user, {
                [NAME_FIELD]: model.nameCard,
                [ADDRESS_FIELD]: model.line1,
                [CITY_FIELD]: model.city,
                [STATE_FIELD]: model.stateProvence || "",
                [POSTAL_CODE_FIELD]: model.postalCode,
                [COUNTRY_FIELD]: model.country
              });
              onComplete({
                name: model.nameCard,
                address: {
                  line: model.line1,
                  city: model.city,
                  stateProvence: model.stateProvence,
                  postalCode: model.postalCode,
                  country: model.country
                },
                card: {
                  last4,
                  tokenId: token.id,
                  paymentMethodId: paymentMethod.id
                }
              });
            } catch (err) {
              console.error(err);
            }
          }
        }
      }}
    >      
      <div className={styles.root}>
        <div className={styles.halfWidth}>
          <FormInput
            name="nameCard"
            label="Name on card *"
            required
            value={attributes[NAME_FIELD]}
          />          
          <FormCardNumber
            name="cardNumber"
            label="Card Number *"
            placeholder={attributes[CARD_FIELD]?.length ? `**** **** **** ${attributes[CARD_FIELD]}` : undefined}
          />
        </div>
        <div className={styles.halfWidth}>
          <FormCardExpiry
            name="cardExpiry"
            label="Expiry Date *"
            placeholder={attributes[CARD_FIELD]?.length ? "**/**" : undefined}
          />
          <FormCardCvc
            name="cardCvc"
            label="CVC / CVV *"
            placeholder={attributes[CARD_FIELD]?.length ? "***" : undefined}
          />
        </div>        
        <div className={styles.halfWidth}>
          <FormSelect
            name="country"
            label="Country *"
            className={styles.selectWithNoArrow}
            value={attributes[COUNTRY_FIELD]}
            options={CountryOptions.map((country) => {
              return { value: country["alpha-2"], name: country.name };
            })}
          />
          <FormInput
            name="line1"
            label="Street address *"
            required
            value={attributes[ADDRESS_FIELD]}
          />
        </div>
        <div className={styles.halfWidth}>
          <FormInput
            name="city"
            label="Town/city *"
            required
            value={attributes[CITY_FIELD]}
          />
          <FormInput
            name="stateProvence"
            label="State/province (optional)"
            value={attributes[STATE_FIELD]}
          />
        </div>
        <div className={styles.halfWidth}>
          <FormInput
            name="postalCode"
            label="Post code / ZIP code *"
            required
            value={attributes[POSTAL_CODE_FIELD]}
          />
        </div>
        {actions ? (
          actions
        ) : (
          <div className={styles.buttons}>
            <Button
              type="submit"
              color="blue"
              title="Update my details"
            />
          </div>
        )}
      </div>
    </Formsy>
  );
};

const stripePromise = loadStripe(environment.stripeKey);

export const BillingDetailsForm: React.FC<Props> = props => (
  <Elements stripe={stripePromise}>
    <BillingDetailsFormBase {...props} />
  </Elements>
);
