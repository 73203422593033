import React, { Component } from "react";
import styles from "./Testimonials.module.css";
import { ReactComponent as LeftArrowImage } from "../static/left-arrow.svg";
import { ReactComponent as RightArrowImage } from "../static/right-arrow.svg";

export class Testimonials extends Component {
  state = {
    page: 0
  }

  changePage(number) {
    const { testimonials } = this.props;
    let page = this.state.page + number;

    if (page >= 0 && page < testimonials.length ) {
      this.setState({ page });
    }
  }

  render() {
    const { page } = this.state;
    const { maxNumber, testimonials, title, centerText } = this.props;
    const pageFrom = page * maxNumber;
    const pageTo = (page * maxNumber) + maxNumber;
    let classes = "";

    if (centerText) {
      classes +=  `${styles.center}`;
    }

    return (
      <div className={`${styles.root} ${classes}`}>
        <h1>{title}</h1>
        <div className={styles.container}>
          {testimonials.slice(pageFrom, pageTo).map((testimonial, index) => {
            return (
              <div
                key={index}
                className={styles.testimonial}
                style={{ width: `calc(100% / ${maxNumber})`, maxWidth: "600px" }}
              >
                <p>{testimonial.text}</p>
                <p><b>{testimonial.name}</b></p>
              </div>
            );
          })}
        </div>
        <div>
          {pageFrom > 0 &&
            <LeftArrowImage
              className={styles.left}
              onClick={() => this.changePage(-1)}
            />
          }
          {(pageTo + 1) <= testimonials.length &&
            <RightArrowImage
              className={styles.right}
              onClick={() => this.changePage(1)}
            />
          }
        </div>
      </div>
    );
  }
};
