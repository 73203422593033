import React from 'react';
import styles from "./InputError.module.css";
import ErrorImage from "./static/error.png";

interface Props {
  error: string;
  className?: string;
}

export const InputError: React.FC<Props> = ({ error, className }) => (
  <div className={`${styles.root} ${className}`}>
    <img src={ErrorImage} alt="error" /> {error}
  </div>
);
