import React, { Component } from "react";
import { Link as ReactLink } from "react-router-dom";
import PropTypes from "prop-types";
import styles from "./Button.module.css";
import { animateOnAnchorClick } from "./utils";

/**
 * Using <a /> elements for anchors because <ReactLink /> does not seem to work.
 */
const isLinkInternal = (to) => {
  if (to.startsWith("/") && !to.startsWith("/#")) {
    return true;
  }
  return false;
};

const Link = (props) => {
  if (isLinkInternal(props.to)) {
    return (
      <ReactLink
        to={props.to}
        onClick={e => {
          if (props.disabled) {
            e.preventDefault();
          } else if (props.onClick) {
            props.onClick();
          }
        }}
        className={styles.defaultLinksDecoration}
      >
        {props.children}
      </ReactLink>
    );
  } else {
    return (
      <a
        href={props.to}
        target={props.newWindow ? "_blank" : "_self"}
        rel={props.newWindow ? "noopener noreferrer" : ""}
        onClick={(e) => {
          if (props.disabled) {
            e.preventDefault();
          } else if (props.onClick) {
            if (props.animateAnchorClick) {
              animateOnAnchorClick(e);
            }
            props.onClick();
          }
        }}
        className={styles.defaultLinksDecoration}
      >
        {props.children}
      </a>
    );
  }
};

const DefaultContainer = (props) => {
  if (props.disableOnClickEventInline) {
    return (
      <div onClick={props.onClick}>
        {props.children}
      </div>
    );
  } else if (props.type === "submit") {
    return (
      <button type="submit" className={styles.submitButton}>
        {props.children}
      </button>
    );
  } else {
    return (
      <div onClick={() => !props.disabled && props.onClick && props.onClick()}>
        {props.children}
      </div>
    );
  }
};

const ButtonContainer = (props) => {
  if (props.to) {
    return <Link {...props}>{props.children}</Link>;
  } else {
    return <DefaultContainer {...props}>{props.children}</DefaultContainer>;
  }
};

/**
 * Using Component instead of FC to fix using Button as a trigger to reactjs-popup.
 * React.FC throws "Warning: Function components cannot be given refs": // https://github.com/yjose/reactjs-popup/issues/128
 */
export class Button extends Component {
  render() {
    const { title, color, className, disabled, fullWidth, short, variant } = this.props;
    const Icon = this.props.icon;
    let classes = `${styles.button} ${styles[color]}`;
  
    if (className) {
      classes += ` ${className}`;
    }
    if (disabled) {
      classes += ` ${styles.disabled}`;
    }
    if (short || Icon) {
      classes += ` ${styles.short}`;
    }
    if (fullWidth) {
      classes += ` ${styles.fullWidth}`;
    }
    if (variant) {
      classes += ` ${styles.borderless}`;
    }
  
    return (
      <ButtonContainer {...this.props}>
        <div className={`${classes}`}>
          <div className={styles.container}>
            {this.props.children ? (
              this.props.children
            ) : (
              <>
                {Icon && <Icon />}
                {title && <span style={{ marginLeft: Icon ? "15px": undefined }}>{title}</span>}
              </>
            )}
          </div>
        </div>
      </ButtonContainer>
    );
  }
}

Button.propTypes = {
  animateAnchorClick: PropTypes.bool,
  children: PropTypes.element,
  className: PropTypes.string,
  color: PropTypes.string,
  disableOnClickEventInline: PropTypes.bool,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  icon: PropTypes.object,
  onClick: PropTypes.func,
  short: PropTypes.bool,
  title: PropTypes.string,
  to: PropTypes.string,
  type: PropTypes.string,
  variant: PropTypes.string,
};

Button.defaultProps = {
  color: "blue",
};

export default Button;
