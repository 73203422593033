import { FormsyInjectedProps, withFormsy } from "formsy-react";
import React from "react";
import styles from "./Checkbox.module.css";
import { ReactComponent as SelectedImage } from "./static/selected.svg";
import { ReactComponent as UnselectedImage } from "./static/unselected.svg";

type CheckBoxProps = FormsyInjectedProps<boolean> & {
  children: any;
  disabled?: boolean;
}

const FormCheckBox = (props: CheckBoxProps) => {
  const { children, isFormSubmitted, errorMessage, disabled } = props;

  const selected = props.value || false;

  let classes = styles.root;

  if (children) {
    classes += ` ${styles.children}`;
  }
  if (isFormSubmitted && errorMessage) {
    classes += ` ${styles.error}`;
  }

  return (
    <div
      className={classes} onClick={() => {
        if (!disabled) {
          props.setValue(!selected);
        }
      }
      }
    >
      {selected
        ? <SelectedImage />
        : <UnselectedImage />
      }
      {children}
    </div>
  );
};

export default withFormsy(FormCheckBox);
