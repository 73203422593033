import React from "react";
import styles from "./Footer.module.css";
import { Button } from "../../shared";
import { ReactComponent as InstagramImage } from "./static/instagram.svg";
import { ReactComponent as LinkedinImage } from "./static/linkedin.svg";
import { ReactComponent as LogoImage } from "./static/uunn_logo_white.svg";
// import { ReactComponent as TwitterImage } from "./static/twitter.svg";
import { SubscribeNewsletterForm } from "../../newsletters/components/SubscribeNewsletterForm";
import { Link } from "react-router-dom";
import { Container } from "../container/Container";
import { Visibility } from "..";

interface Props {
  className?: string;
}

export const Footer: React.FC<Props> = ({ className }) => {
  const newsletterSection = (
    <div className={`${styles.links} ${styles.newsletter}`}>
      <h5>Sign up to our newsletter for content that you and your smile will love</h5>
      <p>Receive the latest happenings, exclusive deals and top-tips in your inbox. No spam, we promise. Unsubscribe anytime. For more information, see our <Link to="/privacy-policy" className={styles.link}>Privacy Policy</Link>.</p>
      <SubscribeNewsletterForm />
    </div>
  );

  return (
    <div id="footer" className={`${styles.root} ${className}`}>
      <Container fullWidth>
        <div className={styles.top}>
          <div className={styles.logo}>
            <Button
              color="transparent"
              icon={LogoImage}
              to="/"
            />
          </div>
          <Visibility size="small" type="visible">
            {newsletterSection}
          </Visibility>
          <div className={styles.links}>
            <h5>Where next?</h5>
            <Button
              color="transparent"
              title="Home"
              to="/"
              className={styles.white}
            />
            <Button
              color="transparent"
              title="About Us"
              to="/about-us"
              className={styles.white}
            />
            <Button
              color="transparent"
              title="I want uunn"
              to="/order"
              className={styles.white}
            />
            <Button
              color="transparent"
              title="My Account"
              to="/my-account"
              className={styles.white}
            />
            <Button
              color="transparent"
              title="FAQ's"
              to="/faq"
              className={styles.white}
            />
          </div>
          <div className={styles.links} id="contact">
            <h5>Contact</h5>
            <Button
              color="transparent"
              title="hello@uunn.co.uk"
              to="mailto:hello@uunn.co.uk"
              newWindow
              className={styles.white}
            />
            <div className={styles.social}>
              <h5>Follow us</h5>
              <Button
                color="transparent"
                icon={InstagramImage}
                to="https://www.instagram.com/we.are.uunn"
                className={styles.white}
                newWindow
              />
              <Button
                color="transparent"
                icon={LinkedinImage}
                to="https://www.linkedin.com/company/uunn"
                className={styles.white}
                newWindow
              />
              {/* // TODO: Add on v2
              <Button
                color="transparent"
                icon={TwitterImage}
                to="https://twitter.com/we_are_uunn"
                className={styles.white}
                newWindow
              /> */}
            </div>
          </div>
          <Visibility size="small" type="hidden">
            {newsletterSection}
          </Visibility>
        </div>
        <div className={styles.bottom}>
          <div />
          <div className={styles.copyright}>
            <span>&#169; uunn {new Date().getFullYear()}</span>
            <Button
              color="transparent"
              title="Privacy Policy"
              to="/privacy-policy"
              className={styles.white}
            />
            <Button
              color="transparent"
              title="Cookie Policy"
              to="/cookie-policy"
              className={styles.white}
            />
            <Button
              color="transparent"
              title="Terms of Use"
              to="/terms-use"
              className={styles.white}
            />
            <Button
              color="transparent"
              title="Terms of Sale"
              to="/terms-sale"
              className={styles.white}
            />
          </div>
          <Button
            color="transparent"
            title="Back to top"
            to="#"
            className={styles.white}
          />
        </div>
      </Container>
    </div>
  );
};
