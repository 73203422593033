import { withFormsy, FormsyInjectedProps } from 'formsy-react';
import React, { useState } from 'react';
import styles from "./Input.module.css";
import { InputError } from './InputError';
import TooltipImage from "./static/tooltip.png";

export type InputProps = FormsyInjectedProps<string> & {
  label: string;
  placeholder?: string;
  tooltip?: string;
  error?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const FormInput = (props: InputProps) => {
  const [isTooltipOpen, setTooltipOpen] = useState(false);
  // An error message is passed only if the component is invalid
  const { value, errorMessage, label, isRequired, name, isFormSubmitted, placeholder, tooltip, onChange } = props;
  const error = props.error || (isFormSubmitted && isRequired && !value ? "Info missing!" : isFormSubmitted && errorMessage ? errorMessage : undefined);
  const id = Math.random().toString(36).substring(7);
  let classes = `${styles.root}`;

  if (error) {
    classes += ` ${styles.error}`;
  }

  return (
    <div className={`${classes}`}>
      {label && (
        <div className={styles.label}>
          <label htmlFor={id}>{label}</label>
        </div>
      )}
      <div className={styles.buttonContainer}>
        <input
          onChange={(event) => {
            props.setValue(event.currentTarget.value);
            onChange?.(event);
          }}
          type="text"
          value={props.value || ''}
          placeholder={placeholder}
          name={name}
          id={id}
        />
        {tooltip && (
          <img
            src={TooltipImage}
            alt="tooltip"
            onClick={() => setTooltipOpen(!isTooltipOpen)}
            onMouseEnter={() => setTooltipOpen(true)}
            onMouseLeave={() => setTooltipOpen(false)}
          />
        )}
        {isTooltipOpen && <span className={styles.tooltipText}>{tooltip}</span>}
      </div>
      {error && <InputError error={error as string} className={styles.errorText} />}
    </div>
  );
};

export default withFormsy(FormInput);
