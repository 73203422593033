import React from "react";
import styles from "./DownloadApp.module.css";
import { Button } from "../shared";
import { ReactComponent as IconAppleImage } from "./static/icon-apple.svg";
import { ReactComponent as IconAndroidImage } from "./static/icon-android.svg";
import { AppContainer } from "../layout";

export const DownloadApp = () => {
  return (
    <AppContainer>
      <div className={styles.root}>
        <h1>You're all set!</h1>
        <p className={styles.lightBlue}>Go ahead and download the<br />uunn app onto your phone</p>
        <div className={styles.buttons}>
          <div>
            <Button
              color="white"
              variant="borderless"
              className={styles.button}
              icon={IconAppleImage}
              title="App Store"
              to="https://itunes.apple.com/gb/app/uunn/id1460656212?mt=8"
              newWindow
            />
          </div>
          <div>
            <Button
              color="white"
              variant="borderless"
              className={styles.button}
              icon={IconAndroidImage}
              title="Google Play"
              to="https://play.google.com/store/apps/details?id=com.uunn.mobile.android"
              newWindow
            />
          </div>
        </div>
      </div>
    </AppContainer>
  );
};
