import React, { useEffect, useState } from "react";
import { FaqItem } from "../faq/components/FaqItem";
import { AppContainer, Visibility } from "../layout";
import { Button, Popup } from "../shared";
import styles from "./PreOrder.module.css";
import { set } from "../layout/header/reducers/cartCounterSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { ReactComponent as TickImage } from "./static/tick.svg";
import { getStock } from "./services";

export const PreOrder = () => {
  const cartCounter = useAppSelector(state => state.cartCounter.value);
  const dispatch = useAppDispatch();
  const [stock, setStock] = useState<boolean>(true);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => setOpen(true), 15000);

    const fetchStock = async () => {
      setStock((await getStock()).stock);
    };

    fetchStock();

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, []);

  const addToCartButton = (
    <Button
      color="blue"
      variant={cartCounter > 0 ? "borderless" : undefined}
      onClick={() => {
        if (cartCounter === 0) {
          dispatch(set(1));
        }
      }}
      className={styles.button}
      disabled={!stock}
      fullWidth
      short
    >
      {cartCounter === 0 ? (
        <p>Add to cart</p>
      ) : (
        <p>Added to cart <TickImage /></p>
      )}
    </Button>
  );
  const mailLink = <a href="mailto:hello@uunn.co.uk">hello@uunn.co.uk</a>;

  return (
    <AppContainer containerFullWidth>
      <div className={styles.root}>
        <div className={styles.header}>
          <h1>The starter pack</h1>
          <p className={styles.lightBlue}>uunn is designed to support you with improving &amp; maintaining the state and health of your teeth and gums.</p>
          <p className={styles.lightBlue}>You'll have access to our plaque detecting technology • gums and mouth reviewed by a dentist • access to dental expert support • exclusive rates with our partner clinics.</p>
          <p className={styles.lightBlue}>Please check phone compatiblity first.</p>
        </div>
        <div className={styles.starterPack}>
          <div className={styles.row}>
            <h2>Starter pack</h2>
            <h2>£32</h2>
          </div>
          <div className={styles.row}>
            <div>
              {stock ? (
                <>
                  <p className={styles.lightBlue}>1 x uunn lens</p>
                  <p className={styles.lightBlue}>1 x 12 scans on uunn app</p>
                </>
              ) : (
                <p className={styles.lightBlue}>We’re currently out of stock. If you sign up below, we’ll let you know once we’ve restocked.</p>
              )}
              <Visibility size="small" type="visible">
                {addToCartButton}
              </Visibility>
            </div>
            <Visibility size="small" type="hidden">
              {addToCartButton}
            </Visibility>
          </div>
        </div>
        <hr />
        {/* {stock && (
          <>
            <div className={styles.saveMoney}>
              <h1>Save money on each additional pack you buy</h1>
              <div className={styles.table}>
                <div className={styles.row}>
                  <div>
                    <h4>Quantity</h4>
                  </div>
                  <div>
                    <h4>Discount</h4>
                  </div>
                </div>
                <div className={styles.row}>
                  <div>
                    <p>1 x starter pack</p>
                  </div>
                  <div>
                    <p>£23</p>
                  </div>
                </div>
                <div className={styles.row}>
                  <div>
                    <p>2 x starter packs</p>
                  </div>
                  <div>
                    <p><span className={styles.lineThrough}>£46</span> <span className={styles.red}>£39</span></p>
                  </div>
                </div>
                <div className={styles.row}>
                  <div>
                    <p>3 x starter packs</p>
                  </div>
                  <div>
                    <p><span className={styles.lineThrough}>£69</span> <span className={styles.red}>£62</span></p>
                  </div>
                </div>
                <div className={styles.row}>
                  <div>
                    <p>4 x starter packs</p>
                  </div>
                  <div>
                    <p><span className={styles.lineThrough}>£92</span> <span className={styles.red}>£73</span></p>
                  </div>
                </div>
              </div>
            </div>
            <hr />
          </>
        )} */}
        <div className={styles.faqs}>
          <div className={styles.faqsHeader}>
            <h1>FAQs</h1>
            <p>We recommend you read these</p>
          </div>
          <hr />
          <FaqItem title="Who is uunn great for?">
            <ul>
              <li>If you want a 'judgment free zone' to review your teeth and begin taking steps to protect your teeth and for a healthier mouth. Then, uunn is for you.</li>
              <li>Worried or not sure if you are taking care of your teeth and gums well enough to avoid costly problems down the line. uunn is also for you.</li>
              <li>If you avoid the dentist for fear of bad news? You can start today with uunn.</li>
              <li>If you find it tricky to prevent plaque and gum problems arising between dental visits? uunn is designed just for that.</li>
              <li>If you are struggling to build the habits of more brushing and flossing in your routine? uunn is the perfect partner.</li>
            </ul>
            <p style={{ fontSize: "16px" }}>** uunn is not a medical diagnostic tool or service. We are not a replacement for a physical hygiene clean or a dental check-up.</p>
          </FaqItem>
          <FaqItem title="Is uunn compatible with my phone?">
            <p>It is important to be aware before you purchase uunn that currently not all of uunn’s features listed above in their full glory are available for all phone models, so please check the list below to ensure you are happy with your purchase or whether you’d prefer to wait.</p>
            <p>We are working hard on making sure all our features are compatible with all phones, but we are still on that journey.</p>
            <p><u>Phones compatible with all uunn’s features (i.e. you get: uunn AI plaque detecting algorithm - gums and mouth review by a dentist - access to dental expert support - exclusive treatment rates with uunn partner clinics):</u></p>
            <p>Google Pixel 2 all models • Google Pixel 3 all models • Google Pixel 4 all models • Google Pixel 5 all models • iPhone 7 plus • iPhone 8 • iPhone 8 + • iPhone X • iPhone 11 • Samsung Galaxy S8 • Samsung Galaxy S8 + • Samsung Galaxy S9 • Samsung Galaxy S9 + • Samsung Galaxy S10 • Samsung Galaxy S10 + • Samsung Galaxy S8/S9/S10 note • Xiomi Poco X3 NFC</p>
            <p><u>Phones partly compatible with uunn’s features (i.e. you get: uunn AI plaque detecting algorithm BETA* - gums and mouth reviewed by dentist every 3-4 weeks - access to dental expert support - exclusive treatment rates with uunn partner clinics):</u></p>
            <p>iPhone XS • iPhone XR • iPhone 12/12 pro • Samsung S20/S20 FE/S20 FE 5G/S20 Plus/S21/S21 Plus • Samsung S22 • Samsung S22 Plus/S22 ultra</p>
            <p>*BETA - this means the AI plaque detecting technology accuracy is in development, and so the technology can miss some teeth focus areas where plaque resides. However, it is good to remember that your scans are reviewed by a dentist too who will pinpoint gum focus areas which ensures you are provided with all the insights you need to tackle the teeth and gums you need to focus on. The difference is that our AI takes minutes whereas your review from the dentist takes longer. We check in every 3-4 weeks depending on your needs.</p>
            <p>If you own one of the plus sized versions of the phones from either of the lists (fully functioning or the BETA version) then you might find fitting uunn onto your phone a little bit more of a tug but it will fit. We appreciate that this isn’t quite the perfect experience, but it is doable to enjoy all the benefits of uunn.</p>
            <p>We are continuously working on releasing more phone models, with the aim to ensure iPhone 13 onwards becomes available very soon.</p>
            <p>Let us know if you want uunn available on your phone model by emailing {mailLink}</p>
          </FaqItem>
          <FaqItem title="How often do I need to use uunn?">
            <p>This will depend on your teeth and gums. We recommend that you start scanning once a week until you are in the zone with your teeth and gums. After which, you can scan every fortnight to maintain the state of your teeth and gums.</p>
          </FaqItem>
          <FaqItem title="Can anyone use uunn?">
            <p>Our algorithm is currently unable to detect your plaque if you wear metal braces, for the moment!</p>
            <p>If you wear clear aligners such as Invisalign, all you gotta do is take them off and you're ready to scan with uunn.</p>
            <p>Please also note that the uunn lens is not designed for people under the age of 16 (yet).</p>
          </FaqItem>
          <FaqItem title="What countries does uunn ship to?">
            <p>At the moment, we only ship to the UK but we’re working on going global!</p>
            <p>If you sign-up to our newsletter (scroll to the bottom of this page), you’ll be the first to know when we’re shipping to your country.</p>
          </FaqItem>
          <FaqItem title="What happens when I run out of scans?">
            <p>Our starter pack includes 12 scans, that you can choose to use once, twice or three times a week.</p>
            <p>Once you run out of these scans, you can upgrade to one of our monthly membership plans (we’ll even recommend the best plan for you).</p>
          </FaqItem>
          <FaqItem title="Who are our partner clinics?">
            <p>London:</p>
            <p>Bond Dental Bloomsbury • Acton Town Dental Practice • Bond Dental Harley Street • Serene Dental Knightsbridge • Twoth Dental + Facial Aesthetics</p>
          </FaqItem>
          <Button
            color="white"
            title="All FAQs"
            to="/faq"
            fullWidth
            className={styles.button}
          />
        </div>
      </div>

      <Popup
        open={open}
        headerText="Hello!"
        maxWidth="575px"
      >
        {_ => (
          <div style={{ textAlign: "center" }}>
            <p>We're hope you're considering getting yourself an uunn.<br />You won't regret it!<br />Use code <b>uunnStarter10</b> for 10% off your first purchase!</p>
          </div>
        )}
      </Popup>
    </AppContainer>
  );
};
