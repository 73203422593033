import React, { Component } from "react";
import styles from "./LandingPage.module.css";
import { Header, Container, Footer, Visibility } from "../layout";
import { Testimonials } from "./components/Testimonials";
// import { SubscribeProButtonductLaunchForm } from "../newsletters/components/SubscribeProductLaunchForm";
// import { PhoneModelPopupForm } from "../newsletters/components/PhoneModelPopupForm";
import { Waypoint } from "react-waypoint";
import { Button } from "../shared";
import {
  testimonialsExperts,
  //testimonialsPeople
} from "./models";
import WelcomeSection0Image1 from "./static/welcomeSection0Image1.jpg";
import WelcomeSection0Image2 from "./static/welcomeSection0Image2.png";
import WelcomeSection1Image1 from "./static/welcomeSection1Image1.gif";
import WelcomeSection3Image1 from "./static/welcomeSection3Image1.gif";
import { ReactComponent as WelcomeSection3Image2 } from "./static/welcomeSection3Image2.svg";
import HowSection2Image1 from "./static/howSection2Image1.gif";
import HowSection4Image1 from "./static/howSection4Image1.png";
import HowSection4Image2 from "./static/howSection4Image2.gif";
import HowSection3Image1 from "./static/howSection3Image1.gif";
// import { ReactComponent as HowSection4Image3 }  from "./static/howSection4Image3.svg";
import { ReactComponent as HowSection4Image4 } from "./static/howSection4Image4.svg";
import HowSection5Image1 from "./static/howSection5Image1.gif";
// import SubscribeSection3Image1 from "./static/subscribeSection3Image1.gif";
// import { ReactComponent as PriceSection2Image1 } from "./static/priceSection2Image1.svg";
// import { ReactComponent as PriceSection2Image2 } from "./static/priceSection2Image2.svg";
// import { ReactComponent as PriceSection3Image1 } from "./static/priceSection3Image1.svg";
import CoverLoopVideo from "./static/video_short.mp4";
import { PlayButton } from "./components/PlayButton";
import { ReactComponent as RightArrowImage } from "./static/right-arrow2.svg";

export class LandingPage extends Component {
  state = {
    coverOnViewPort: false,
  }

  render() {
    const { coverOnViewPort } = this.state;
    return (
      <div className={styles.root}>
        <Visibility size="small" type="visible">
          <Header className={styles.navbarMobile} />
          <div className={styles.headerButton}>
            <Button
              color="cyan"
              variant="borderless"
              title="I want uunn"
              to="/order"
              className={styles.button}
            />
          </div>
        </Visibility>
        <Visibility size="small" type="hidden">
          <Header color={coverOnViewPort ? "white" : "default"} />
        </Visibility>
        <Waypoint
          topOffset="90px"
          onEnter={() => this.setState({ coverOnViewPort: true })}
          onLeave={() => this.setState({ coverOnViewPort: false })}
        >
          <div id="cover" className={styles.cover}>
            <div className={styles.videoBackground}>
              <div className={styles.overlay}>
                <PlayButton className={styles.playButton} />
              </div>
              <video autoPlay loop muted>
                <source src={CoverLoopVideo} type="video/mp4" />
              </video>
            </div>
            <div className={styles.content}>
              <h1>Scan with uunn &amp; achieve healthier teeth.</h1>
              <p>Hello! We’re uunn (pronounced ooo-n).</p>
              <p>Our intelligent and simple-to-use app and qualified dental professionals,<br />support you with your teeth care through our revolutionary, new technology.</p>
              {/* // TODO: Add on v2
              <Button
                color="white"
                title="Order now"
                to="/order"
                className={styles.button}
              /> */}
              <Visibility size="small" type="hidden">
                <PlayButton className={styles.playButton} />
              </Visibility>
            </div>
          </div>
        </Waypoint>
        <Container fullWidth>
          <div className={styles.sections}>
            <div id="welcome" className={styles.section}>
              {/* Welcome section 0 */}
              <div className={styles.welcomeSection0}>
                <div className={styles.header}>
                  <h1>Want your very own uunn?</h1>
                  <p className={styles.lightBlue}>
                    <Visibility size="small" type="hidden">
                      Let us know if you’re new here or a happy user
                    </Visibility>
                    <Visibility size="small" type="visible">
                      Let us know if you’re new here<br />or a happy user
                    </Visibility>
                  </p>
                </div>
                <div className={styles.cards}>
                  <div className={styles.card} style={{ backgroundImage: `url(${WelcomeSection0Image1})` }}>
                    <div className={styles.container}>
                      <h2>I'm new<br />to uunn<br />&amp; want<br />a starter<br />pack</h2>
                      <Button
                        color="white"
                        title="Let's go"
                        to="/order"
                        className={styles.button}
                        short
                      >
                        <div className={styles.buttonContainer}>
                          <span>Let's go</span><RightArrowImage />
                        </div>
                      </Button>
                    </div>
                  </div>
                  <div className={styles.card} style={{ backgroundImage: `url(${WelcomeSection0Image2})` }}>
                    <div className={styles.container}>
                      <h2>I've been<br />here before<br />&amp; want<br />more<br />scans</h2>
                      <Button
                        color="white"
                        title="Let's go"
                        to="/my-account/scans-subscription"
                        className={styles.button}
                        short
                      >
                        <div className={styles.buttonContainer}>
                          <span>Let's go</span><RightArrowImage />
                        </div>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              {/* Welcome section 1 */}
              <div className={styles.welcomeSection1}>
                <div>
                  <div className={styles.content}>
                    <h1>Keep your total<br />plaque level under 1%</h1>
                    <p>To achieve problem-free &amp; brilliant-looking teeth!</p>
                  </div>
                  <div className={styles.image}>
                    <img src={WelcomeSection1Image1} alt="alt" />
                  </div>
                </div>
                <div />
              </div>
              {/* Welcome section 3 */}
              <div className={styles.welcomeSection3}>
                <div />
                <div>
                  <img src={WelcomeSection3Image1} alt="alt" />
                  <WelcomeSection3Image2 />
                  <div className={styles.content}>
                    <h1>See which teeth &amp; gums need your special attention.</h1>
                    <p>The uunn algorithm will detect and highlight any teeth that have plaque left-over, and our uunn dental experts will review your gums &amp; identify any gum focus areas to tackle.</p>
                  </div>
                </div>
              </div>
            </div>
            {/* <div id="subscribe" className={styles.section}>
              <div className={styles.subscribeSection2}>
                <h1>Tell me when I can order uunn</h1>
              </div>
              <div className={styles.subscribeSection3}>
                <div className={styles.content}>
                  <p>Pop your email below and we'll reach out when uunn is ready to purchase.</p>
                  <SubscribeProductLaunchForm />
                </div>
                <div className={styles.image}>
                  <img src={SubscribeSection3Image1} alt="alt" />
                </div>
              </div>
            </div> */}
            <div id="how" className={styles.section}>
              {/* How section 1 */}
              <div className={styles.howSection1}>
                <h1>How does uunn work?</h1>
              </div>
              {/* How section 2 */}
              <div className={styles.howSection2}>
                <div className={styles.image}>
                  <img src={HowSection2Image1} alt="alt" />
                </div>
                <div className={styles.content}>
                  <div className={styles.text}>
                    <p><span className={styles.numbered}>1</span></p>
                    <p>Slide the re-usable lens over your phone camera.</p>
                  </div>
                </div>
              </div>
              {/* How section 3 */}
              <div className={styles.howSection3}>
                <div className={styles.content}>
                  <div className={styles.text}>
                    <p><span className={styles.numbered}>2</span></p>
                    <p>Take photos of your teeth (front, left &amp; right); we call this a scan and recommend that you do it once a week at first.</p>
                  </div>
                </div>
                <div className={styles.image}>
                  <img src={HowSection3Image1} alt="alt" />
                </div>
              </div>
              {/* How section 4 */}
              <div className={styles.howSection4}>
                <div>
                  <div className={styles.content}>
                    <div className={styles.text}>
                      <p><span className={styles.numbered}>3</span></p>
                      <p>Let the magic happen. Our algorithm will highlight the teeth that you need to pay special attention to until your next scan.</p>
                    </div>
                    <Visibility size="small" type="visible" className={styles.containerImage1}>
                      <img src={HowSection4Image1} className={styles.image1} alt="alt" />
                    </Visibility>
                    <div className={styles.text}>
                      <p><span className={styles.numbered}>4</span></p>
                      <p>Sit back. As we send your scans to our dental experts for an in-depth review.</p>
                    </div>
                    <div className={styles.text}>
                      <p><span className={styles.numbered}>5</span></p>
                      <p>Last but not least. Keep scanning with uunn to track and monitor your teeth while our dental team will check in regularly on your scans and monitor your gums.</p>
                    </div>
                  </div>
                  <Visibility size="small" type="hidden">
                    <img src={HowSection4Image1} className={styles.image1} alt="alt" />
                  </Visibility>
                  <img src={HowSection4Image2} className={styles.image2} alt="alt" />
                  {/* // TODO: Add on v2
                  <Visibility size="large" type="visible">
                    <Button
                      color="transparent"
                      icon={HowSection4Image3}
                      to="/order"
                      className={styles.image3}
                    />
                  </Visibility> */}
                  <Visibility size="small" type="hidden">
                    <HowSection4Image4 className={styles.image4} />
                  </Visibility>
                </div>
                <div />
              </div>
              {/* How section 5 */}
              <div className={styles.howSection5}>
                <div />
                <div>
                  <div>
                    <img src={HowSection5Image1} alt="alt" />
                  </div>
                  <div className={styles.content}>
                    <h1>Our Mission</h1>
                    <p>We’re here to evolve the way that you take care of your teeth. To make flossing as enjoyable as a face mask and to make brushing as satisfying as a fresh haircut. We want to change the perception of ’checking your teeth’ from expecting bad news to expecting guidance on how you can keep your smile squeaky-clean, fresh-smelling, and bright. Our end goal? A world where teeth care is no longer associated with nagging, anxiety, or pain. Here's to making teeth-care, self-care.</p>
                  </div>
                </div>
              </div>
            </div>
            {/* // TODO: Add on v2
            <div id="price" className={styles.section}>
              Price section 1
              <div className={styles.priceSection1}>
                <h1>Order your uunn bundle now</h1>
              </div>
              Price section 2
              <div className={styles.priceSection2}>
                <div>
                  <Visibility size="small" type="hidden">
                    <div className={styles.image}>
                      <PriceSection2Image1 />
                    </div>
                  </Visibility>
                  <div className={styles.content}>
                    <div className={styles.text}>
                      <h1>£48.00</h1>
                      <h2>for one year (£4/month)</h2>
                      <h2 className={styles.lineThrough}><span>£59.99</span></h2>
                      <p>Be one of the first 1,000 people to experience the latest version of uunn. We know we can change your day, week and life for the better. Order now for special pre-launch pricing on your first year with us and be the first to download the uunn app in March 2020 (shipping is on us - we’ll cover the cost of sending you your uunn lens in the post)</p>
                    </div>
                    <PriceSection2Image2 />
                  </div>
                </div>
                <div>
                  <p>Ready to put your money where your mouth is?</p>
                  <Button
                    color="red"
                    title="Order now"
                    to="/order"
                  />
                </div>
              </div>
              Price section 3
              <div className={styles.priceSection3}>
                <Visibility size="small" type="hidden">
                  <div className={styles.image}>
                    <PriceSection3Image1 />
                  </div>
                </Visibility>
                <div className={styles.content}>
                  <div className={styles.text}>
                    <p>The uunn lens works with the following mobiles</p>
                    <p className={styles.blue}>iPhone 6, 7, 8, X, XS, XR</p>
                    <p className={styles.blue}>Samsung Galaxy S8, S9, S10</p>
                    <p className={styles.blue}>Google Pixel 2, 3</p>
                    <p>“I’d love uunn but my phone isn’t here”</p>
                    <PhoneModelPopupForm
                      trigger={
                        <Button
                          color="blue"
                          title="Let us know"
                          disableOnClickEventInline
                        />
                      }
                    />
                  </div>
                </div>
              </div>
            </div> */}
            <div id="testimonials" className={styles.section}>
              {/* Testimonials section 1 */}
              <div className={styles.testimonialsSection1}>
                <div>
                  <Visibility size="large" type="hidden">
                    <Testimonials
                      title={testimonialsExperts.title}
                      testimonials={testimonialsExperts.testimonials}
                      maxNumber={1}
                    />
                  </Visibility>
                  <Visibility size="large" type="visible">
                    <Testimonials
                      title={testimonialsExperts.title}
                      testimonials={testimonialsExperts.testimonials}
                      maxNumber={4}
                    />
                  </Visibility>
                </div>
                <div />
              </div>
              {/* Testimonials section 2 */}
              {/* // TODO: Add on v2
              <div className={styles.testimonialsSection2}>
                <Testimonials
                  title={testimonialsPeople.title}
                  testimonials={testimonialsPeople.testimonials}
                  maxNumber={1}
                  centerText
                />
              </div> */}
            </div>
          </div>
        </Container>
        <Footer />
      </div>
    );
  }
};
