
import React, { ReactNode } from "react";
import { default as ReactPopup } from "reactjs-popup";
import styles from "./Popup.module.css";
import { Button } from "../../shared";
import { ReactComponent as CloseImage } from "./static/close.svg";
import { Visibility } from "../../layout";

interface Props {
  trigger?: any;
  open?: boolean;
  children: (close: () => void) => ReactNode;
  headerText: string;
  cancelText?: string;
  cancelCallback?: (close: () => void) => void;
  submitText?: string;
  submitCallback?: (close: () => void) => void;
  submitDisabled?: boolean;
  onClose?: () => void;
  maxWidth?: string;
}

export const Popup: React.FC<Props> = ({ trigger, open, children, headerText, cancelText, cancelCallback, submitText, submitCallback, submitDisabled, onClose, maxWidth }) => {
  return (
    <ReactPopup
      open={open}
      trigger={trigger}
      modal
      closeOnDocumentClick
      closeOnEscape
      contentStyle={{
        width: "80%",
        maxWidth: maxWidth ?? "1000px",
        borderRadius: "16px",
      }}
      overlayStyle={{
        overflow: "auto"
      }}
      onClose={onClose}
    >
      {(close) => (
        <div className={styles.modal}>
          <Visibility size="small" type="hidden">
            <button className={styles.close} onClick={close}>
              <CloseImage />
            </button>
          </Visibility>
          <div className={styles.header}><h2>{headerText}</h2></div>
          <div className={`${styles.content} ${styles.fullWidth}`}>
            {children(close)}
          </div>
          {(cancelCallback || submitCallback) && (
            <div className={styles.actions}>
              <Button
                color="white"
                title={cancelText || "Cancel"}
                onClick={() => {
                  if (cancelCallback) {
                    cancelCallback(close);
                  } else {
                    close();
                  }
                }}
              />
              <Button
                color="blue"
                title={submitText || "Save"}
                onClick={() => {
                  if (submitCallback) {
                    submitCallback(close);
                  } else {
                    close();
                  }
                }}
                disabled={submitDisabled}
              />
            </div>
          )}
        </div>
      )}
    </ReactPopup>
  );
};
