import axios, { Pagination } from "../../../network";
import Axios from "axios";
import { UserSpecialist, UserScanList, UserScan, UserScanUpload } from "../models";

export const getCheckInUsers = async (page: number, size: number): Promise<Pagination<UserSpecialist>> => {
  const res = await axios.get(`/checkin/users?page=${page}&size=${size}`)
  return {
    results: res.data,
    count: Number(res.headers["x-total-count"] ?? res.data.length)
  };
};

export const getCheckInUserScans = async (userId: string, page: number, size: number): Promise<Pagination<UserScanList>> => {
  const res = await axios.get(`/checkin/users/${userId}/scans?page=${page}&size=${size}`);
  return {
    results: res.data,
    count: Number(res.headers["x-total-count"] ?? res.data.length)
  };
};

export const getCheckInUserScan = async (userId: string, scanId: number): Promise<UserScan> => {
  return (await axios.get(`/checkin/users/${userId}/scans/${scanId}`)).data;
};

export const postCheckInUserScan = async (userId: string, scanId: number): Promise<UserScanUpload> => {
  return (await axios.post(`/checkin/users/${userId}/scans/${scanId}`)).data;
};

export const putCheckInUserScan = async (userId: string, scanId: number, positions: string[], message: string): Promise<void> => {
  return axios.put(`/checkin/users/${userId}/scans/${scanId}`, { positions, message });
};

/**
 * Axios instance without authentication
 */
export const putS3File = async (url: string, file: Buffer, contentType: string): Promise<void> => {
  return Axios.create().put(url, file, { headers: { "Content-Type": contentType }});
};
