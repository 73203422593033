import React, { ReactNode } from "react";
import styles from "./Tabs.module.css";

interface Props {
  tabs: ReactNode[];
  currentTab: number;
  onClick: (tab: number) => void;
  className?: string;
  disabled?: boolean;
}

export const Tabs: React.FC<Props> = ({ className, tabs, currentTab, disabled, onClick }) => {
  let classes = `${styles.root}`;

  if (className) {
    classes += ` ${className}`;
  }

  return (
    <div className={classes}>
      {tabs.map((tab: string | ReactNode, index: number) => {
        let classes = styles.tab;

        if (index === currentTab) {
          classes += ` ${styles.active}`;
        }

        return (
          <div
            key={index}
            className={classes}
            onClick={() => !disabled && onClick(index)}
            style={{ width: `calc(100% / ${tabs.length})` }}
          >
            <div className={styles.container}>
              {tab}
            </div>
          </div>
        );
      })}
    </div>
  );
};
