import React from "react";
import styles from "./SubscriptionComponent.module.css";
import { Button } from "../../shared";
import { SubscriptionPlan } from "../models";

interface Props {
  plan: SubscriptionPlan;
  buttonText: string;
  onClick: (plan: SubscriptionPlan) => void;
  headerText?: string;
  active?: boolean;
  disabled?: boolean;
}

export const SubscriptionComponent: React.FC<Props> = ({ plan, buttonText, onClick, headerText, active, disabled }) => {
  let classes = styles.root;

  if (active) {
    classes += ` ${styles.active}`;
  }

  return (
    <div className={classes}>
      {headerText && <h6 className={styles.header}>{headerText}</h6>}
      <div className={styles.container}>
        <h3>{plan.amount} scans a month</h3>
        <p className={styles.lightBlue}>{plan.price} per month</p>
        <Button
          color="blue"
          title={buttonText}
          fullWidth
          short
          onClick={() => onClick(plan)}
          disabled={disabled}
        />
      </div>
    </div>
  );
};
