import React from "react";
import styles from "./NotFound.module.css";
import { AppContainer } from "../layout";
import { Button } from "../shared";

export const NotFound = () => {
  return (
    <AppContainer>
      <div className={styles.root}>
        <div className={styles.content}>
          <h1>404</h1>
          <h1>Page not found</h1>
        </div>
        <div className={styles.buttons}>
          <Button
            color="blue"
            title="Return home"
            to="/"
          />
        </div>
      </div>
    </AppContainer>
  );
};
