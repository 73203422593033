import React, { Component } from "react";
import styles from "./MyAccount.module.css";
import { Auth } from "aws-amplify";
import { AppContainer, Visibility } from "../layout";
import { Input, Button, Tabs } from "../shared";
import { validEmail } from "../utils";
import { BillingDetails } from "./BillingDetails";
import { PersonalDetails } from "./PersonalDetails";
import { ScanBalance } from "./scans/ScanBalance";
import { ReactComponent as BillingDetailsImage } from "./static/billingDetails.svg";
import { ReactComponent as PersonalDetailsImage } from "./static/personalDetails.svg";
import { ReactComponent as YourScansImage } from "./static/yourScans.svg";
import { ReactComponent as ArrowRightImage } from "./static/arrowRight.svg";
import { RouteComponentProps } from "react-router-dom";
import { ScanSubscription } from "./scans/ScanSubscription";
import { CognitoUser } from "amazon-cognito-identity-js";

type TabName = "scans-balance" | "scans-subscription" | "personal-details" | "billing-details";

interface Params {
  filter?: TabName;
}

type Props = RouteComponentProps<Params>;

interface State {
  user?: CognitoUser;
  loading: boolean;
  attributes: { [key: string]: string };
  disabled: boolean;
  email: string;
  password: string;
  emailError?: string;
  passwordError?: string;
  tab?: TabName;
}

export class MyAccount extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      user: undefined,
      loading: true,
      attributes: {},
      disabled: false,
      email: "",
      password: "",
      emailError: undefined,
      passwordError: undefined,
      tab: props.match.params.filter
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.checkValidForm = this.checkValidForm.bind(this);
    this.refreshAttributes = this.refreshAttributes.bind(this);
  }

  componentDidMount() {
    Auth.currentAuthenticatedUser()
      .then((user: CognitoUser) => this.refreshAttributes(user))
      .catch(_ => this.setState({ loading: false }));
  }

  componentDidUpdate() {
    const { tab } = this.state;

    if (this.props.match.params.filter !== tab) {
      this.setState({ tab: this.props.match.params.filter });
    }
  }

  handleChange(event: any) {
    this.setState<any>({ [event.target.name]: event.target.value });
    return true;
  }

  checkValidForm() {
    const { email, password } = this.state;

    if (email.length === 0 || !validEmail(email) || password.length === 0) {
      return false;
    }

    return true;
  }

  async handleSubmit() {
    if (this.checkValidForm()) {
      this.setState({ disabled: true, emailError: undefined, passwordError: undefined }, async () => {
        const { email, password } = this.state;

        try {
          const user = await Auth.signIn(email, password);
          this.refreshAttributes(user);
        } catch (err) {
          console.error("Failure", err);
          const inputError = "Oops! We don’t recognise this email or password.";
          this.setState({ disabled: false, emailError: inputError, passwordError: inputError });
        }
      });
    }
  }

  async handleLogout() {
    await Auth.signOut();
    this.setState({
      user: undefined,
      disabled: false,
      attributes: {}
    });
  }

  async refreshAttributes(authenticatedUser?: CognitoUser) {
    const user = authenticatedUser ?? this.state.user;

    if (user) {
      // Get attributes
      const attributes: { [key: string]: string } = {};
      (await Auth.userAttributes(user)).forEach((attr: any) => {
        attributes[attr.Name] = attr.Value;
      });
      this.setState({ attributes, user, loading: false });
    }
  }

  getTabNumber(tab: TabName): number {
    if (tab === "scans-balance" || tab === "scans-subscription") {
      return 0;
    }
    else if (tab === "personal-details") {
      return 1;
    }
    else {
      return 2;
    }
  }

  onTabChange(tabIndex: number) {
    let tab: TabName | undefined;

    if (tabIndex === 0) {
      tab = "scans-balance";
    }
    else if (tabIndex === 1) {
      tab = "personal-details";
    }
    else if (tabIndex === 2) {
      tab = "billing-details";
    }

    this.setTab(tab);
  }

  setTab(tab?: TabName) {
    this.props.history.push(`/my-account/${tab ?? ""}`);
  }

  render() {
    const { user, attributes, tab, loading, emailError, passwordError } = this.state;

    if (loading) {
      return null;
    };

    return (
      <AppContainer containerFullWidth={user ? true : false}>
        {user ? (
          <div className={styles.account}>
            {tab === undefined ? (
              <div className={styles.dashboard}>
                <div className={styles.title}>
                  <h1>Hello, {attributes["name"]}</h1>
                  <h2>
                    Welcome to your account,
                    <br />
                    where would you like to go?
                  </h2>
                </div>
                <Visibility size="large" type="visible">
                  <div className={styles.menuDesktop}>
                    <div onClick={() => this.setTab("scans-balance")}>
                      <div className={styles.container}>
                        <YourScansImage />
                        <h3>Your scans</h3>
                      </div>
                    </div>
                    <div onClick={() => this.setTab("personal-details")}>
                      <div className={styles.container}>
                        <PersonalDetailsImage />
                        <h3>Personal details</h3>
                      </div>
                    </div>
                    <div onClick={() => this.setTab("billing-details")}>
                      <div className={styles.container}>
                        <BillingDetailsImage />
                        <h3>Billing details</h3>
                      </div>
                    </div>
                  </div>
                </Visibility>
                <Visibility size="large" type="hidden">
                  <div className={styles.menuMobile}>
                    <div onClick={() => this.setTab("scans-balance")}>
                      <p>Your scans</p>
                      <ArrowRightImage />
                    </div>
                    <div onClick={() => this.setTab("personal-details")}>
                      <p>Personal details</p>
                      <ArrowRightImage />
                    </div>
                    <div onClick={() => this.setTab("billing-details")}>
                      <p>Billing details</p>
                      <ArrowRightImage />
                    </div>
                    <div onClick={() => this.handleLogout()}>
                      <p>Log out</p>
                      <ArrowRightImage />
                    </div>
                  </div>
                </Visibility>
                <div className={styles.buttons}>
                  <Button color="white" title="Contact us" to="mailto:hello@uunn.co.uk" newWindow fullWidth />
                  <Visibility size="large" type="visible">
                    <Button color="red" title="Logout" fullWidth onClick={() => this.handleLogout()} />
                  </Visibility>
                </div>
              </div>
            ) : (
              <div className={styles.content}>
                <h6 className={styles.textCenter}>Account</h6>
                <Tabs
                  tabs={[
                    "Your scans",
                    "Personal details",
                    "Billing details",
                    <Button
                      key="logoutButton"
                      color="transparent"
                      title="Logout"
                      className={styles.logoutButton}
                      onClick={() => this.handleLogout()}
                    />
                  ]}
                  onClick={tabIndex => this.onTabChange(tabIndex)}
                  currentTab={this.getTabNumber(tab)}
                />
                <div className={styles.container}>
                  {tab === "scans-balance" && (
                    <ScanBalance />
                  )}
                  {tab === "scans-subscription" && (
                    <ScanSubscription
                      user={user}
                      attributes={attributes}
                      refreshAttributes={this.refreshAttributes}
                    />
                  )}
                  {tab === "personal-details" && (
                    <PersonalDetails
                      user={user}
                      attributes={attributes}
                      refreshAttributes={this.refreshAttributes}
                    />
                  )}
                  {tab === "billing-details" && (
                    <BillingDetails
                      user={user}
                      attributes={attributes}
                      refreshAttributes={this.refreshAttributes}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className={styles.login}>
            <h1>Log in</h1>
            <Input name="email" type="email" label="Email Address" onChange={this.handleChange} error={emailError} />
            <Input
              name="password"
              type="password"
              label="Password"
              onChange={this.handleChange}
              error={passwordError}
            />
            <Button color="transparent" title="Forgotten your password?" to="/reset-password" />
            <Button
              color="blue"
              title="Log in"
              onClick={() => this.handleSubmit()}
              disabled={!this.checkValidForm() || this.state.disabled}
            />
          </div>
        )}
      </AppContainer>
    );
  }
}
