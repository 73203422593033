import React, { useEffect, useState } from "react";
import styles from "./ScansLists.module.css";
import { UserScanList, UserScan } from "../models";
import { getCheckInUserScans, getCheckInUserScan } from "../services";
import { Arrows, Button, Tabs } from "../../shared";

interface Props {
  userId: string;
}

export const ScansList: React.FC<Props> = ({ userId }) => {
  const [scans, setScans] = useState<UserScanList[]>([]);
  const size = 15;
  const [page, setPage] = useState<number>(0);
  const [maxPages, setMaxPages] = useState<number>(1);
  const [scan, setScan] = useState<UserScan | undefined>(undefined);
  const [tab, setTab] = useState<number>(0);
  const [annotationButtonDisabled, setAnnotationButtonDisabled] = useState<boolean>(false);

  const user = scans.length ? `${scans[0].name} (${scans[0].email})` : undefined;

  useEffect(() => {
    getCheckInUserScans(userId, page, size).then(async res => {
      setScans(res.results);
      setMaxPages(Math.ceil(res.count / size));

      if (res.results.length) {
        setScan(await getCheckInUserScan(userId, res.results[0].id));
      }

      // Disable annotations if user is already checked in in the last 30 days
      if (page === 0) {
        const lastAnnotationAt = res.results.find(it => it.lastAnnotationAt !== undefined)?.lastAnnotationAt;

        if (lastAnnotationAt) {
          const oneDay = (60 * 60 * 24 * 1000);
          const shouldDisableAnnotations = (new Date().getTime() - new Date(lastAnnotationAt).getTime()) <= (oneDay * 30);
          setAnnotationButtonDisabled(shouldDisableAnnotations);
        }
      }
    });
  }, [userId, page]);

  return (
    <div className={styles.root}>
      <div className={styles.navbar}>
        <Button
          color="transparent"
          title="< Go to users"
          to="/practitioners"
        />
        <h6 className={styles.textCenter}>Scans {user}</h6>
        <div />
      </div>
      <div className={styles.container}>
        <div className={styles.scans}>
          {scans.map(item => {
            const isUnderline = item.lastAnnotationAt ? true : false;

            let classes = styles.scan;

            if (isUnderline) {
              classes += ` ${styles.underline}`;
            }

            if (item.id === scan?.id) {
              classes += ` ${styles.active}`;
            }

            return (
              <Button
                key={item.id}
                fullWidth
                color="transparent"
                title={`Scan #${item.id} - ${new Date(item.createdAt).toLocaleString()}`}
                className={classes}
                onClick={async () => {
                  setScan(await getCheckInUserScan(userId, item.id));
                  setTab(0);
                }}
              />
            );
          })}
        </div>
        <div className={styles.results}>
          {scan && (
            <>
              <Tabs
                tabs={[
                  "Inputs",
                  scan.annotations.length ? "Annotations" : undefined
                ].filter(it => it)}
                onClick={tabIndex => setTab(tabIndex)}
                currentTab={tab}
                className={styles.tabs}
              />
              {tab === 0 && (
                <Arrows>
                  {scan.inputs.sort((a, b) => a.position.localeCompare(b.position)).map(input => {
                    const output = scan.outputs.resultImages.find(image => image.position === input.position);

                    return (
                      <div key={input.position} className={styles.image}>
                        <img src={input.link} alt={input.position} />
                        <p><b>Plaque Coverage:</b> {output?.plaqueCoverage ?? "-"}</p>
                        <p><b>Global Plaque Coverage:</b> {scan.outputs.plaqueCoverage}</p>
                        <p><b>Brushing:</b> {scan.brushing ?? "-"}</p>
                        <p><b>Flossing:</b> {scan.flossing ?? "-"}</p>
                      </div>
                    );
                  })}
                </Arrows>
              )}
              {tab === 1 && (
                <Arrows>
                  {scan.annotations.sort((a, b) => a.position.localeCompare(b.position)).map(annotation => (
                    <div key={annotation.position}  className={styles.image}>
                      <img src={annotation.link} alt={annotation.position} />
                      <p style={{ maxWidth: "300px" }}><b>Message:</b> {annotation.message}</p>
                    </div>
                  ))}
                </Arrows>
              )}
              <Button
                fullWidth
                color="blue"
                className={styles.annotationButton}
                title="Annotate this scan"
                to={`/practitioners/annotations/${scan.userId}$${scan.id}`}
                disabled={scan.annotations.find(it => it.state === "Sent") !== undefined || annotationButtonDisabled}
              />
            </>
          )}
        </div>
      </div>
      <div className={styles.pagination}>
        <Button
          color="transparent"
          title="< Previous page"
          disabled={page <= 0}
          onClick={() => setPage(page - 1)}
        />
        <Button
          color="transparent"
          title="Next page >"
          disabled={(page + 1) >= maxPages}
          onClick={() => setPage(page + 1)}
        />
      </div>
    </div>
  );
};
