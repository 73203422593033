import Formsy from "formsy-react";
import React, { useEffect, useState } from "react";
import { Button, Input } from "../../shared";
import { Product } from "./CartStep";
import { FormModel } from "./CheckoutForm";
import styles from "./ReviewStep.module.css";
import FormCheckBox from "../../shared/checkbox/FormCheckBox";
import QuantitySelector from "./QuantitySelector";
import { ReactComponent as EditImage } from "./static/edit.svg";
import { ReactComponent as CloseImage } from "./static/cross.svg";
import { applyPromo, getShippingRate, updatePayment } from "../services";
import { set } from "../../layout/header/reducers/cartCounterSlice";
import { useAppDispatch } from "../../../store/hooks";
import { InputError } from "../../shared/input/InputError";

type ReviewProps = {
  selectedProduct?: Product,
  products: Array<Product>,
  paymentId: string,
  goToStep: (step: number) => void,
  submitStep: (step: number) => void,
  updateProduct: (product: Product) => void,
  setFormModel: (formModel: FormModel) => void,
  formModel: FormModel
}

const getProductByName = (value: string, products: Array<Product>) => {
  let product = products.filter((product) => product.value === value)[0];

  return product;
};

const ReviewStep = (props: ReviewProps) => {
  const dispatch = useAppDispatch();
  const [discount, setDiscount] = useState("");
  const [productId, setProductId] = useState(props.selectedProduct?.value);
  const [disabled, setDisabled] = useState(false);
  const [submitError, setSubmitError] = useState("");
  const [couponError, setCouponError] = useState("");
  const [shippingRate, setShippingRate] = useState(395);
  const billingAddress = props.formModel.billing?.sameAsShipping ? props.formModel.shippingAddress : props.formModel.billing?.address;
  const shippingAddress = props.formModel.shippingAddress;

  useEffect(() => {
    if (shippingAddress?.country) {
      getShippingRate(shippingAddress.country).then(res => {
        if (res.rate !== shippingRate) {
          setShippingRate(res.rate);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shippingAddress])

  return (
    <Formsy
      onValidSubmit={async () => { 
        setDisabled(true);
        try {
          await updatePayment(
            (props.formModel.firstName + " " + props.formModel.lastName),
            props.formModel.email!,
            props.paymentId,
            props.formModel.shippingAddress!,
            productId,
            props.formModel.coupon?.code
          );
          props.submitStep(4);
        } catch(err) {
          console.error(err);
          setSubmitError(err.response.data.message ?? "Error occurred");
        }
      }}
    >
      <div className={styles.root}>
        {submitError && <InputError error={submitError} />}
        {props.selectedProduct && (
          <QuantitySelector
            selectedProduct={props.selectedProduct}
            products={props.products}
            onChange={async productId => {
              const product = getProductByName(productId, props.products);
              props.updateProduct(product);
              setProductId(productId);
              dispatch(set(product.amount));
            }}
          />
        )}

        <hr />

        <div className={styles.summary}>
          {billingAddress && (
            <div>
              <h4 className={styles.title}>
                <span>Billing Address</span>
                <EditImage onClick={() => props.goToStep(2)} />
              </h4>
              <p className={styles.lightBlue}>{billingAddress?.line}</p>
              <p className={styles.lightBlue}>{billingAddress?.city}, {billingAddress?.postalCode}</p>
              <p className={styles.lightBlue}>{billingAddress?.country}</p>
            </div>
          )}

          {shippingAddress && (
            <div>
              <h4 className={styles.title}>
                <span>Shipping Address</span>
                <EditImage onClick={() => props.goToStep(2)} />
              </h4>
              <p className={styles.lightBlue}>{shippingAddress?.line}</p>
              <p className={styles.lightBlue}>{shippingAddress?.city}, {shippingAddress?.postalCode}</p>
              <p className={styles.lightBlue}>{shippingAddress?.country}</p>
            </div>
          )}

          <div>
            <h4 className={styles.title}>
              <span>Shipping</span>
              <span />
            </h4>
            <p className={styles.lightBlue}>Standard UK</p>
            <p className={styles.lightBlue}>second class</p>
            <p className={styles.lightBlue}>2-3 days, £{shippingRate / 100}</p>
          </div>
        </div>

        <hr />

        <div className={styles.subTotal}>
          <div>
            <h3>Sub total</h3>
            <p>(includes taxes)</p>
          </div>
          <h3>
            £{props.selectedProduct && props.selectedProduct.price / 100}
          </h3>
        </div>

        <hr /> 

        {couponError && <InputError error={couponError} className={styles.couponError} />}
        <div className={`${styles.subTotal} ${styles.coupon}`}>
          <div>
            {props.formModel.coupon ? (
              <Button
                color="red"
                variant="borderless"
                title="Apply"
                className={styles.couponButton}
                onClick={() => {
                  props.setFormModel({
                    coupon: undefined
                  });
                }}
              >
                <div className={styles.container}>{props.formModel.coupon?.code} <CloseImage /></div>
              </Button>
            ) : (
              <div className={styles.couponInput}>
                <Input 
                  name="discount"
                  type="text"
                  placeholder="Enter discount code"
                  className={styles.input}
                  onChange={(event: any) => {
                    setDiscount(event.target.value);
                  }}
                />
                <Button
                  color="red"
                  title="Apply"
                  className={styles.button}
                  onClick={async () => {
                    try {
                      setCouponError("");
                      const promoCode = await applyPromo(discount);

                      if (promoCode.found) {
                        props.setFormModel({
                          coupon: {
                            code: discount,
                            id: promoCode.coupon,
                            amount: promoCode.amount
                          }
                        });
                      } else {
                        setCouponError("Oops, this discount code isn’t valid");  
                      }
                    } catch {
                      setCouponError("Oops, this discount code isn’t valid");
                    }
                  }}
                />
              </div>
            )}
          </div>

          <h3>
            {props.formModel.coupon && (
              "- £" + props.formModel.coupon.amount / 100
            )}
          </h3>            
        </div>

        <hr />

        <div className={styles.subTotal}>
          <h3>Shipping</h3>
          <h3>£{shippingRate / 100}</h3>
        </div>

        <hr />

        <div className={styles.subTotal}>
          <h3>Total</h3>
          {/* TODO: Add calculate shipping endpoint */}
          <h3>£{(((props.selectedProduct?.price || 0) + shippingRate - (props.formModel.coupon?.amount || 0)) / 100)}</h3>
        </div>

        <hr />  

        <div className={styles.buttons}>
          <FormCheckBox
            name="termsOfService"
            validations="isTrue"
            validationError="You must agree to terms of service"
          >
            <span className={styles.lightBlue}>I agree to the <a href="/terms-sale" target="_blank">Terms of Sale</a> and acknowledge that I have read the <a href="/privacy-policy" target="_blank">Privacy Policy</a>.</span>
          </FormCheckBox>
          <Button
            type="submit"
            color="blue"
            title="Purchase"
            disabled={disabled}
            fullWidth
          />
        </div>
      </div>
    </Formsy>
  );
};

export default ReviewStep;
