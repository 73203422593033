import React, { ReactNode, useState } from "react";
import styles from "./Arrows.module.css";
import Button from "../button/Button";

interface Props {
  children: ReactNode[];
  defaultPage?: number;
  className?: string;
  disabled?: boolean;
  onChange?: (index: number) => void;
}

export const Arrows: React.FC<Props> = ({ children, defaultPage, className, disabled, onChange }) => {
  const [page, setPage] = useState<number>(defaultPage ?? 0);
  const maxPages = children.length;
  let classes = `${styles.root}`;

  if (className) {
    classes += ` ${className}`;
  }

  const changePage = (index: number) => {
    setPage(index);

    if (onChange) {
      onChange(index);
    }
  };

  return (
    <div className={classes}>
      <div className={styles.container}>
        {children[page]}
        <Button
          color="transparent"
          className={styles.left}
          title="<"
          disabled={disabled || page <= 0}
          onClick={() => changePage(page - 1)}
        />
        <Button
          color="transparent"
          className={styles.right}
          title=">"
          disabled={disabled || (page + 1) >= maxPages}
          onClick={() => changePage(page + 1)}
        />
      </div>
    </div>
  );
};
