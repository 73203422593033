import React from "react";
import styles from "./Visibility.module.css";

/*
 * Object options explained:
 * {
 *   // options: visible, hidden
 *   {Type}: {
 *     // options: small, medium, large
 *     {size}: [...],
 *   },
 * }
*/
const classes = {
  hidden: {
    large: [styles.hiddenLarge],
    medium: [styles.hiddenMedium],
    small: [styles.hiddenSmall],
  },
  visible: {
    large: [styles.visibleLarge],
    medium: [styles.visibleMedium],
    small: [styles.visibleSmall],
  },
};

/**
 *  Visibility component that apply media queries to hide or show elements
 */
export const Visibility = (props) => {
  let classNames = classes[props.type][props.size].join(" ");
    
  if (props.className) {
    classNames += ` ${props.className}`;
  }

  return (
    <div className={classNames}>
      {props.children}
    </div>
  );
};

Visibility.defaultProps = {
  size: "small",
  type: "visible",
};
