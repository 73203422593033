import React from "react";
import { useAppSelector } from "../../../../store/hooks";
import { Button } from "../../../shared";
import { ReactComponent as CartImage } from "../static/cart.svg";
import styles from "./CartButton.module.css";

export const CartButton: React.FC = () => {
  const cartCounter = useAppSelector(state => state.cartCounter.value);

  return (
    <div className={styles.root}>
      <Button
        color="transparent"
        icon={CartImage}
        to="/checkout"
      />
      {cartCounter > 0 && (
        <span className={styles.counter}>{cartCounter}</span>
      )}
    </div>
  );
};
