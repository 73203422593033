import React, { Component } from "react";
import styles from "./ScanSubscription.module.css";
import { Button, Steps } from "../../shared";
import { CognitoUser } from "amazon-cognito-identity-js";
import { SubscriptionPlanStep } from "./SubscriptionPlanStep";
import { SubscriptionBillingStep } from "./SubscriptionBillingStep";
import { SubscriptionReviewStep } from "./SubscriptionReviewStep";
import { BillingInformation, EMAIL_FIELD, AVAILABLE_PLAN_SUBSCRIPTIONS, SubscriptionPlan, ActiveSubscription, NAME_FIELD, ADDRESS_FIELD, CITY_FIELD, STATE_FIELD, POSTAL_CODE_FIELD, COUNTRY_FIELD, CARD_FIELD } from "../models";
import { getBalance } from "../services";

interface Props {
  user: CognitoUser;
  attributes: { [key: string]: string };
  refreshAttributes: () => void;
}

interface State {
  step: number;
  plan?: SubscriptionPlan;
  billing?: BillingInformation;
  activeSubscription?: ActiveSubscription;
}

export class ScanSubscription extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      step: 1,
      plan: undefined,
      billing: {
        name: props.attributes[NAME_FIELD],
        address: {
          line: props.attributes[ADDRESS_FIELD],
          city: props.attributes[CITY_FIELD],
          stateProvence: props.attributes[STATE_FIELD],
          postalCode: props.attributes[POSTAL_CODE_FIELD],
          country: props.attributes[COUNTRY_FIELD]
        },
        card: {
          last4: props.attributes[CARD_FIELD] ?? "****"
        }
      },
      activeSubscription: undefined
    };
  }

  componentDidMount() {
    getBalance().then(balance => this.setState({ activeSubscription: balance.subscription }));
  }

  render() {
    const { user, attributes, refreshAttributes } = this.props;
    const { step, plan, billing, activeSubscription } = this.state;
    const steps = activeSubscription ? ["Your plan", "Review"] : ["Your plan", "Payment", "Review"];

    return (
      <div className={styles.root}>
        <div className={styles.header}>
          <div className={styles.header}>
            {step <= 3 && (
              <>
                <Steps
                  steps={steps}
                  onClick={step => this.setState({ step })}
                  currentStep={activeSubscription && step === 3 ? 2 : step}
                />
                <hr />
              </>
            )}
          </div>
          <div className={styles.content}>
            {step === 1 && (
              <SubscriptionPlanStep
                plans={AVAILABLE_PLAN_SUBSCRIPTIONS}
                activeSubscription={activeSubscription}
                onComplete={plan => this.setState({ plan, step: activeSubscription ? 3 : 2 })}
              />
            )}
            {step === 2 && plan && (
              <SubscriptionBillingStep
                user={user}
                attributes={attributes}
                onBack={() => this.setState({ step: 1 })}
                onComplete={(billing: BillingInformation) => this.setState({ billing, step: 3 }, () => refreshAttributes())}
              />
            )}
            {step === 3 && plan && billing && (
              <SubscriptionReviewStep
                plan={plan}
                billing={billing}
                activeSubscription={activeSubscription}
                onBack={() => this.setState({ step: 1 })}
                onComplete={() => this.setState({ step: 4 })}
              />
            )}
            {step === 4 && (
              <div className={styles.step4}>
                <h1>Hurrah!</h1>
                <h2>{activeSubscription ? "you’ve updated your scan plan" : "you’ve subscribed to a scan plan" }</h2>
                <p>A confirmation email with more details is on its way to {attributes[EMAIL_FIELD]}</p>
                <p>Be sure to check your spam, in case it ends up in there.</p>
                <p>See you on the email side!</p>
                <p>Team uunn.</p>
                <Button
                  color="white"
                  title="Take me home"
                  to="/"
                  fullWidth
                  className={styles.button}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
