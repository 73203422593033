import React from "react";
import styles from "./Select.module.css";
import { FormsyInjectedProps, withFormsy } from "formsy-react";
import { ReactComponent as ArrowDownImage } from "./static/arrowDown.svg";
import { InputError } from "../input/InputError";

type FormSelectProps = FormsyInjectedProps<string> & {
  label: string,
  options: Array<{ name: string, value: string }>,
  className?: string,
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void
}

const FormSelect = (props: FormSelectProps) => {
  const { options, label, errorMessage, name, className, value, onChange } = props;
  const id = Math.random().toString(36).substring(7);
  let classes = `${styles.root}`;

  if (className) {
    classes += ` ${className}`;
  }
  if (errorMessage) {
    classes += ` ${styles.error}`;
  }
  if (options.length === 1) {
    props.setValue(options[0].value);
  }

  return (
    <div className={`${classes}`}>
      <div className={styles.label}>
        <label htmlFor={id}>{label}</label>
      </div>
      <div className={styles.buttonContainer}>
        <select
          onChange={(event) => {
            props.setValue(event.target.value);
            onChange?.(event);
          }}
          value={value}
          name={name}
          id={id}
        >
          {options && options.map((option, key) => {
            return (
              <option key={key} value={option.value}>
                {option.name}
              </option>
            );
          })}
        </select>
        <ArrowDownImage />
      </div>
      {errorMessage && <InputError error={errorMessage as string} className={styles.errorText} />}
    </div>
  );
};

export default withFormsy(FormSelect);
