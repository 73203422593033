import React from "react";
import styles from "./BillingDetailsTable.module.css";
import { ReactComponent as EditImage } from "../static/edit.svg";
import { BillingInformation } from "../models";

interface Props {
  billing: BillingInformation;
  onClick?: () => void;
}

export const BillingDetailsTable: React.FC<Props> = ({ billing, onClick }) => {
  return (
    <div className={styles.root} onClick={() => onClick?.()}>
      <div>
        <div className={styles.item}>
          <div className={styles.title}>
            <h4>Name</h4>
            <EditImage />
          </div>
          <p className={styles.lightBlue}>{billing.name}</p>
        </div>
        <div className={styles.item}>
          <div className={styles.title}>
            <h4>Billing card</h4>
            <EditImage />
          </div>
          <p className={styles.lightBlue}>{billing.card?.last4?.length ? `card ending ${billing.card.last4}` : "N/A"}</p>
        </div>
      </div>
      <div>
        <div className={styles.item}>
          <div className={styles.title}>
            <h4>Billing address</h4>
            <EditImage />
          </div>
          {billing.address?.line?.length && billing.address?.city?.length && billing.address?.postalCode?.length && billing.address?.country?.length ? (
            <>
              <p className={styles.lightBlue}>{billing.address.line}</p>
              <p className={styles.lightBlue}>{billing.address.city}, {billing.address.postalCode}</p>
              <p className={styles.lightBlue}>{billing.address.country}</p>
            </>
          ) : (
            <p className={styles.lightBlue}>N/A</p>
          )}
        </div>
      </div>
    </div>
  );
};
