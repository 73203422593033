import React from "react";
import styles from "./MenuButton.module.css";
import "./hamburgers.css";

/**
 * TODO: Check if adding animation instead of importing css is a better option.
 * Currently using css from: https://jonsuh.com/hamburgers
 */
export const MenuButton = ({ active, color, ...rest }) => {
  let classes = styles.root;

  if (color === "blue") {
    classes += ` ${styles.blue}`;
  }
  if (color === "white") {
    classes += ` ${styles.white}`;
  }
  if (active) {
    classes += ` is-active`;
  }

  return (
    <div
      className={`${classes} hamburger hamburger--squeeze js-hamburger`}
      {...rest}
    >
      <div className="hamburger-box">
        <div className={`hamburger-inner ${styles.hamburgerInner}`} />
      </div>
    </div>
  );
};

MenuButton.defaultProps = {
  color: "blue",
};

