import React, { useEffect, useState } from "react";
import styles from "./UsersList.module.css";
import { UserSpecialist } from "../models";
import { getCheckInUsers } from "../services";
import { Button } from "../../shared";

export const UsersList = () => {
  const [users, setUsers] = useState<UserSpecialist[]>([]);
  const size = 18;
  const [page, setPage] = useState<number>(0);
  const [maxPages, setMaxPages] = useState<number>(1);

  useEffect(() => {
    getCheckInUsers(page, size).then(res => {
      setUsers(res.results);
      setMaxPages(Math.ceil(res.count / size));
    });
  }, [page]);

  return (
    <div className={styles.root}>
      <div className={styles.navbar}>
        <h6 className={styles.textCenter}>Users</h6>
      </div>
      <div className={styles.container}>
        {users.map(user => {
          const oneDay = (60 * 60 * 24 * 1000);
          const isUnderline = user.lastAnnotationAt ? (new Date().getTime() - new Date(user.lastAnnotationAt).getTime()) > (oneDay * 30) : true;

          return (
            <Button
              key={user.id}
              fullWidth
              color="transparent"
              title={user.userName}
              className={isUnderline ? `${styles.user} ${styles.underline}` : styles.user}
              to={`/practitioners/scans/${user.userId}`}
            />
          );
        })}
      </div>
      <div className={styles.pagination}>
        <Button
          color="transparent"
          title="< Previous page"
          disabled={page <= 0}
          onClick={() => setPage(page - 1)}
        />
        <Button
          color="transparent"
          title="Next page >"
          disabled={(page + 1) >= maxPages}
          onClick={() => setPage(page + 1)}
        />
      </div>
    </div>
  );
};
