import React, { Component } from "react";
import styles from "./SubscriptionBillingStep.module.css";
import { Button } from "../../shared";
import { CognitoUser } from "amazon-cognito-identity-js";
import { BillingDetailsForm } from "../forms/BillingDetailsForm";
import { BillingInformation } from "../models";

interface Props {
  user: CognitoUser;
  attributes: { [key: string]: string };
  onBack: () => void;
  onComplete: (billing: BillingInformation) => void;
}

export class SubscriptionBillingStep extends Component<Props> {
  render() {
    const { user, attributes, onBack, onComplete } = this.props;

    return (
      <div className={styles.root}>
        <div className={styles.header}>
          <h1>Please enter your card details here</h1>
          <p className={styles.lightBlue}>This card will be charged every month so that you can regularly scan your teeth with uunn. You can update these details at a later time, if needed.</p>
        </div>
        <BillingDetailsForm
          user={user}
          attributes={attributes}
          onComplete={billing => onComplete(billing)}
          actions={
            <div className={styles.buttons}>
              <Button
                color="white"
                title="Back"
                onClick={() => onBack()}
                fullWidth
              />
              <Button
                type="submit"
                color="blue"
                title="Next"
                fullWidth
              />
            </div>
          }
        />
      </div>
    );
  }
}
