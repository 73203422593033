import React from "react";
import styles from "./CookiePolicy.module.css";
import { AppContainer } from "../layout";

export const CookiePolicy = () => {
  return (
    <AppContainer>
      <div className={styles.root}>
        <h1>Onaria Technologies Ltd – Cookie Policy</h1>
        {/* Section 1 */}
        <div className={styles.section}>
          <p>Our website uses cookies to distinguish you from other users of our website. This helps us to provide you with a good experience when you browse our website and also allows us to improve our site.</p>
          <p>A cookie is a small file of letters and numbers that we store on your browser or the hard drive of your computer if you agree. Cookies contain information that is transferred to your computer's hard drive.</p>
          <p>We use the following cookies:</p>
          <ul>
            <li><b>Strictly necessary cookies.</b> These are cookies that are required for the operation of our website. They include, for example, cookies that enable you to log into secure areas of our website such as your account.</li>
            <li><b>Analytical or performance cookies.</b> These allow us to recognise and count the number of visitors and to see how visitors move around our website when they are using it. This helps us to improve the way our website works, for example, by ensuring that users are finding what they are looking for easily.</li>
            <li><b>Targeting cookies.</b> These cookies record your visit to our website, the pages you have visited and the links you have followed. We will use this information to make our website and the advertising displayed on it more relevant to your interests.</li>
          </ul>
          <p>We may use the following cookies:</p>
          <ul>
            <li><b>Functionality cookies.</b> These are used to recognise you when you return to our website. This enables us to personalise our content for you, greet you by name and remember your preferences (for example, your choice of language or region).</li>
          </ul>
          <p>You can find more information about the cookies we use and the purposes for which we use them in the table below:</p>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>Cookie Title</th>
                <th>Purpose</th>
                <th>More information</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><b>Strictly necessary cookies</b></td>
                <td />
                <td />
              </tr>
              <tr>
                <td><b>Analytical or performance cookies</b></td>
                <td>
                  <p>This cookie enables us to:</p>
                  <ol type="a">
                    <li>Estimate our audience size and usage pattern.</li>
                    <li>Speed up your searches.</li>
                    <li>Allow you to use our site in a way that makes your browsing experience more convenient, for example, by allowing you to store items in an electronic shopping basket between visits. If you register with us or complete our online forms, we will use cookies to remember your details during your current visit, and any future visits provided the cookie was not deleted in the interim.</li>
                  </ol>
                </td>
                <td>
                  <p>Learn more about Google’s practice: <a href="http://www.google.com/policies/privacy/partners">http://www.google.com/policies/privacy/partners</a></p>
                  <p>How to opt out: <a href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a></p>
                </td>
              </tr>
              <tr>
                <td><b>Targeting Cookies</b></td>
                <td>
                  <p>This cookie enables us to:</p>
                  <ol type="a">
                    <li>Market to you across various ad platforms across the web. This includes Facebook, Instagram and Google Ads.</li>
                    <li>Ensure that the advertising, offers and marketing messages sent to you are more relevant to your interests.</li>
                  </ol>
                </td>
                <td>
                  <p>Learn more about Google’s practice: <a href="http://www.google.com/policies/privacy/partners">http://www.google.com/policies/privacy/partners</a></p>
                  <p>How to opt out: <a href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a></p>
                </td>
              </tr>
            </tbody>
          </table>
          <p>You can block cookies by activating the setting on your browser that allows you to refuse the setting of all or some cookies. However, if you use your browser settings to block all cookies (including essential cookies) you may not be able to access all or parts of our website.</p>
        </div>
      </div>
    </AppContainer>
  );
};
