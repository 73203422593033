import React, { Component } from "react";
import styles from "./SubscriptionPlanStep.module.css";
import { Button } from "../../shared";
import { SubscriptionComponent } from "../components/SubscriptionComponent";
import { ActiveSubscription, SubscriptionPlan } from "../models";

interface Props {
  plans: SubscriptionPlan[];
  activeSubscription?: ActiveSubscription;
  onComplete: (plan: SubscriptionPlan) => void;
}

export class SubscriptionPlanStep extends Component<Props> {
  render() {
    const { plans, activeSubscription, onComplete } = this.props;

    return (
      <div className={styles.root}>
        <div className={styles.header}>
          {activeSubscription ? (
            <>
              <h1>Happy with your monthly subscription?</h1>
              <p>Every month, you’ll be able to use 2,4 or 8 scans depending on the scan plan you pick. Any scans not used, will roll over to the next month. Here to change your plan to another? Click the plan you would prefer!</p>
            </>
          ) : (
            <>
              <h1>You’re upgrading to a monthly subscription</h1>
              <p>Every month, you’ll be able to use 2,4 or 8 scans depending on the scan plan you pick. Any scans not used, will roll over to the next month and if you want to change your plan to another, it’s super simple.</p>
            </>
          )}
        </div>
        <div className={styles.table}>
          {plans.map(plan => {
            const activePlan = activeSubscription?.amount === plan.amount;
            const active = activeSubscription ? activePlan : plan.recommended;
            
            return (
              <SubscriptionComponent
                key={plan.priceId}
                plan={plan}
                buttonText={activePlan ? "This is your plan" : "I want this one"}
                headerText={active ? (activePlan ? "Your current plan" : "Our recommendation") : undefined}
                onClick={() => onComplete(plan)}
                active={active}
                disabled={activePlan && activeSubscription?.status !== "PendingCancel"}
              />
            );
          })}
        </div>
        <Button
          color="white"
          title={activeSubscription ? " I’m happy with my plan" : "I’m no longer interested"}
          to="/my-account/scans-balance"
          fullWidth
          className={styles.button}
        />
      </div>
    );
  }
}
