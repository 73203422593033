import React from "react";
import styles from "./AboutUs.module.css";
import {
  AppContainer,
  Visibility
} from "../layout";
import { Button } from "../shared";
import Section1Image1 from "./static/section1Image1.gif";
// import { ReactComponent as Section7Image1 } from "./static/section7Image1.svg";

export const AboutUs = () => {
  return (
    <AppContainer containerFullWidth>
      <div className={styles.root}>
        {/* Section 1 */}
        <div className={styles.section1}>
          <h1>About Us</h1>
          <h1><span className={styles.hi}>Hi,</span>Nice to meet you.</h1>
        </div>
        {/* Section 2 */}
        <div className={styles.section2}>
          <div className={styles.content}>
            <div className={styles.text}>
              <p>We’re a team of engineers who’ve dedicated the past 7 years to a mission; to make taking care of your teeth enjoyable, rewarding and meaningful.</p>
              <p>The rest of our team are a bunch of computer vision scientists, designers, and dental experts. Meaning that uunn is backed by science, delightful to use and designed to look at home in any bathroom or bedroom, anywhere.</p>
            </div>
          </div>
          <div className={styles.image}>
            <img src={Section1Image1} alt="alt" />
          </div>
        </div>
        {/* Section 5 */}
        <div className={styles.section5}>
          <div className={styles.content}>
            <Visibility size="large" type="hidden">
              <p>Teeth.</p>
              <p>Big and small. Gappy and gummy. And everything in between. They all have one goal; to keep them squeaky-clean. So, we’re adding a rewarding step into your self care routine. Taking the guesswork out of your flossing. And the boredom out of your brushing. The key is knowing your plaque’s location. We know we haven't been taught this kinda equation. There’s now a way to really track your cleaning. Did we mention it’ll make dental check-ups less demeaning?</p>
              <p>All it takes is placing uunn's lens onto your phone.</p>
              <p>And before you know it</p>
              <p>you’ll be moving into</p>
              <p>The Zone!</p>
            </Visibility>
            <Visibility size="large" type="visible">
              <p>Teeth.</p>
              <p>Big and small.</p>
              <p>Gappy and gummy.</p>
              <p>And everything in between.</p>
              <p>They all have one goal; to keep them squeaky-clean.</p>
              <p>So, we’re adding a rewarding step into your self care routine.</p>
              <p>Taking the guesswork out of your flossing.</p>
              <p>And the boredom out of your brushing.</p>
              <p>The key is knowing your plaque’s location.</p>
              <p>We know we haven't been taught this kinda equation.</p>
              <p>There’s now a way to really track your cleaning.</p>
              <p>Did we mention it’ll make dental check-ups less demeaning?</p>
              <p>All it takes is placing uunn's lens onto your phone.</p>
              <p>And before you know it</p>
              <p>you’ll be moving into</p>
              <p>The Zone!</p>
            </Visibility>
          </div>
        </div>
        {/* Section 8 */}
        <div className={styles.section8}>
          <div>
            <h1>The science of uunn</h1>
            <div className={styles.content}>
              <p>Before uunn, there was no real way of knowing what’s going with your teeth, without seeking professional advice. To be clear, uunn wasn’t and isn’t created as a replacement for your regular dental check-ups. We’re here to take care of you for the time in-between. To give you the thumbs up when your plaque level is low enough. To point out where your brush and floss has missed. And to give you truly personalised information so you can give your teeth the ultimate care.</p>
              <p>In order to reliably do this, we’ve committed years of R&amp;D to develop and train our image-processing and machine-learning algorithm using the latest research techniques. Our goal? To ensure that our algorithm is picking up plaque on your teeth, where disclosing dye would (dental professionals use these to stain bacterial biofilms). We didn’t want to launch until we achieved that and we did! It also means we’re continuously working to ensure our technology is accessible to as many phones as possible!</p>
            </div>
          </div>
          <div />
        </div>
        {/* Section 6 */}
        <div className={styles.section6}>
          <div>
            <h1>Our sustainability promise</h1>
            <div className={styles.content}>
              <p>We take full responsibility for doing our part for the planet. That's why we’ve meticulously designed the lens to be as small as possible, to use as little material as possible. This is in an effort to reduce our carbon footprint as well the cost of your postage.</p>
              <p>We’ve also designed the uunn lens to last as long as possible. And, when it does come to the end of its life- we're offering free postage back to us because it's our responsibility to make sure that the plastic we produce, is recycled responsibly.</p>
            </div>
          </div>
          <div>
            {/* // TODO: Add on v2
            <Visibility size="large" type="visible">
              <Button
                color="transparent"
                icon={Section7Image1}
                to="/order"
                className={styles.image}
              />
            </Visibility> */}
          </div>
        </div>
        {/* Section 7 */}
        <div className={styles.section7}>
          <div className={styles.buttons}>
            <Button
              color="blue"
              title="Return home"
              to="/"
            />
            <Button
              color="cyan"
              title="I want uunn"
              to="/order"
            />
          </div>
        </div>
      </div>
    </AppContainer>
  );
};
