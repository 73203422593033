import React, { Component } from "react";
import styles from "./AcceptCookies.module.css";
import { Link } from "react-router-dom";
import { Button } from "../shared";
import { ReactComponent as CloseImage } from "./static/close.svg";
import { Visibility } from "../layout";

const ACCEPTED_COOKIES_NAME = "acceptedCookies";

export class AcceptCookies extends Component {
  constructor(props) {
    super(props);

    this.state = {
      accepted: window.localStorage.getItem(ACCEPTED_COOKIES_NAME) === "true" ? true : false,
    };
  }

  handleOnAccept() {
    this.setState({ accepted: true }, () => {
      window.localStorage.setItem(ACCEPTED_COOKIES_NAME, "true");
    });
  }

  render () {
    const { accepted } = this.state;

    if (accepted) {
      return null;
    } else {
      return (
        <div className={styles.root}>
          <div className={styles.container}>
            <div>
              <p>We use cookies to give you the best, most relevant experience.</p>
              <p>Using this website means you’re ok with this. Read our cookie policy <Link to="/cookie-policy" className={styles.link}>here</Link>.</p>
            </div>
            <div>
              <Visibility size="small" type="hidden">
                <Button
                  color="blue"
                  title="Got it"
                  onClick={() => this.handleOnAccept()}
                  short
                />
              </Visibility>
              <Button
                color="transparent"
                icon={CloseImage}
                onClick={() => this.handleOnAccept()}
              />
            </div>
          </div>
        </div>
      );
    }
  }
}
