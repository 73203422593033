module.exports = {
  production: true,
  protocol: "https",
  host: "0.0.0.0",
  port: "80",
  api: "https://api.uunn.co.uk",
  amplify: {
    users: {
      region: "eu-west-1",
      userPoolId: "eu-west-1_zfMN4hovn",
      userPoolWebClientId: "1m7ub7f71mhsv5fpu9cfe25oj9",
    },
    specialists: {
      region: "eu-west-1",
      userPoolId: "eu-west-1_ys90sbvsT",
      userPoolWebClientId: "2nlemu61gdek8b7nq7paatk2qc",
    }
  },
  stripeKey: "pk_live_X0kRgCg6ndv7uqzUl3LeMEb200sGGHvtrZ",
  subscriptionPlanIds: {
    plan1: "price_1NiH7CHKR1ZQaWfBynpVlJge",
    plan2: "price_1NiH7CHKR1ZQaWfBqvVO2myW",
    plan3: "price_1NiH7CHKR1ZQaWfBHHehuE3Y"
  },
  googleTagManagerKey: "GTM-M5NJ9X9",
  hotjarKey: "2339646"
};
