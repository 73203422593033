module.exports = {
  production: false,
  protocol: "https",
  host: "0.0.0.0",
  port: "5000",
  api: "https://x525qvqh46.execute-api.eu-west-1.amazonaws.com/development",
  amplify: {
    users: {
      region: "eu-west-1",
      userPoolId: "eu-west-1_Q6mzr5sPB",
      userPoolWebClientId: "55pcql7ce9tn2j73g16h0oe36d",
    },
    specialists: {
      region: "eu-west-1",
      userPoolId: "eu-west-1_9W4d8RKAL",
      userPoolWebClientId: "5bc2dgga3e3u3pm0s4671dqms1",
    }
  },
  stripeKey: "pk_test_7l5hpeDbjZVPd7TJs9DwHWn000Nus9E5gV",
  subscriptionPlanIds: {
    plan1: "price_1IYrREHKR1ZQaWfBWrV1NJKx",
    plan2: "price_1PC15rHKR1ZQaWfBhoUuivuo",
    plan3: "price_1IYrREHKR1ZQaWfB7H8aKAPT"
  },
  //Intentionally left blank
  googleTagManagerKey: "",
  hotjarKey: ""
};
