import Formsy from "formsy-react";
import React, { ReactNode } from "react";
import FormInput from "../../shared/input/FormInput";
import styles from "./Forms.module.css";
import { Button, Popup } from "../../shared";
import { Auth } from "aws-amplify";
import { BIRTH_DATE_FIELD, NAME_FIELD } from "../models";
import FormDate from "../../shared/input/FormDate";
import { validDateRegex } from "../../utils";
import { CognitoUser } from "amazon-cognito-identity-js";

interface Props {
  user: CognitoUser;
  trigger: ReactNode;
  attributes: { [key: string]: string };
  onComplete: () => void;
}

export const PersonalDetailsPopup: React.FC<Props> = ({ user, trigger, attributes, onComplete }) => {
  return (
    <Popup
      trigger={trigger}
      headerText="Update your personal details below"
    >
      {close => (
        <Formsy
          onValidSubmit={async (model) => {
            try {
              await Auth.updateUserAttributes(user, {
                [NAME_FIELD]: model.name,
                [BIRTH_DATE_FIELD]: model.birthdate,
              });
              onComplete();
              close();
            } catch (err) {
              console.error(err);
            }
          }}
        >      
          <div className={styles.root}>  
            <div className={styles.halfWidth}>
              <FormInput
                name="name"
                label="Name *"
                required
                value={attributes[NAME_FIELD]}
              />
              <FormDate
                name="birthdate"
                label="Date of Birth *"
                required
                value={attributes[BIRTH_DATE_FIELD]}
                validations={{
                  matchRegexp: validDateRegex()
                }}
                validationError="Invalid DD/MM/YYYY format"
              />
            </div>
            <div className={styles.buttons}>
              <Button
                type="submit"
                color="blue"
                title="Update my details"
              />
            </div>
          </div>
        </Formsy>
      )}
    </Popup>
  );
};
