export const validPasswordRegex = () => { 
  return /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*:;,._~^+«»<>?'/%|ºª])(?=.{8,})/; 
};

/**
 * Returns if passwords checks with strong regex constraints
 * @param password string
 * @returns boolean
 */
export const validPasswordConstraint = (password: string) => {
  const expression = validPasswordRegex();
  return expression.test(password);
};

export const validDateRegex = () => { 
  return /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])\/(19|20)\d{2}$/; 
};

/**
 * Returns if date checks with regex constraints
 * @param date string in format DD/MM/YYYY
 * @returns boolean
 */
export const validDate = (date: string) => {
  const expression = validDateRegex();
  return expression.test(date);
};

export const validEmailRegex = () => { 
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; 
};

/**
 * Returns if email checks with regex constraints
 * @param email string
 * @returns boolean
 */
export const validEmail = (email: string) => {
  const expression = validEmailRegex();
  return expression.test(email.toLowerCase());
};
