import { CardNumberElement, useStripe, useElements } from "@stripe/react-stripe-js";
import Formsy from "formsy-react";
import React from "react";
import FormCheckBox from "../../shared/checkbox/FormCheckBox";
import FormInput from "../../shared/input/FormInput";
import FormSelect from "../../shared/select/FormSelect";
import { FormModel } from "./CheckoutForm";
import styles from "./PaymentStep.module.css";
import { Button } from "../../shared";
import FormCardNumber from "../../shared/input/FormCardNumber";
import FormCardExpiry from "../../shared/input/FormCardExpiry";
import FormCardCvc from "../../shared/input/FormCardCvc";

type PaymentStepProps = {
  billingCountries: Array<{ name: string, value: string }>,
  submitStep: (step: number) => void,
  previous: () => void,
  setFormModel: (formModel: FormModel) => void,
  formModel: FormModel
} 

const PaymentStep = (props: PaymentStepProps) => {
  const sameAsShipping = (props.formModel.billing && props.formModel.billing.sameAsShipping) || false;  
  const stripe = useStripe();
  const elements = useElements();

  return (
    <Formsy
      onValidSubmit={async (model, _, invalidate) => {      
        props.setFormModel({
          billing: {
            sameAsShipping: model.sameAsShipping,
            address: {
              line: model.step2Line1,
              city: model.step2City,
              country: model.step2Country,
              stateProvence: model.step2StateProvence,
              postalCode: model.step2PostalCode
            }
          }
        });

        if (!stripe || !elements) {
          // Stripe.js has not loaded yet. Make sure to disable
          // form submission until Stripe.js has loaded.
          return invalidate({ cardNumber: "Error" });
        }

        const cardNumberElement = elements.getElement(CardNumberElement);

        if (!cardNumberElement) {
          return invalidate({ cardNumber: "No card element found" });
        }

        // @ts-ignore
        const { token, error } = await stripe.createToken(cardNumberElement, {
          "name": model.nameCard,
          "address_line1": model.step2Line1,
          "address_city": model.step2City,
          "address_zip": model.step2PostalCode,
          "address_country": model.step2Country
        });                  

        if (error) {
          console.error("Error creating stripe token", error);

          if (error.code?.includes("number")) {
            return invalidate({ cardNumber: error.message, cardExpiry: undefined, cardCvc: undefined });
          }
          else if (error.code?.includes("expiry")) {
            return invalidate({ cardNumber: undefined, cardExpiry: error.message, cardCvc: undefined });
          }
          else if (error.code?.includes("cvc")) {
            return invalidate({ cardNumber: undefined, cardExpiry: undefined, cardCvc: error.message });
          }

          return invalidate({ cardNumber: error.message ?? "Error" });
        } 

        if (token && token.id) {
          props.setFormModel({
            billing: {
              ...props.formModel.billing,
              address: {
                line: model.step2Line1,
                city: model.step2City,
                country: model.step2Country,
                stateProvence: model.step2StateProvence,
                postalCode: model.step2PostalCode
              },
              card: {
                token: token.id,
                last4: token.card?.last4 || "****"             
              }
            }
          });

          props.submitStep(3);
        }
      }}
      onChange={(model, isChanged) => {
        props.setFormModel({
          billing: {
            ...props.formModel.billing,
            sameAsShipping: model.sameAsShipping
          }
        });
      }}
    >      
      <div className={styles.root}>
        <div className={styles.halfWidth}>
          <FormInput
            name="nameCard"
            label="Name on card *"
            required
          />          
          <FormCardNumber
            name="cardNumber"
            label="Card Number *"
          />
        </div>
        <div className={styles.halfWidth}>
          <FormCardExpiry
            name="cardExpiry"
            label="Expiry Date *"
            placeholder="MM/YY"
          />
          <FormCardCvc
            name="cardCvc"
            label="CVC / CVV *"
          />
        </div>        
        <div className={styles.marginBottom}>
          <FormCheckBox
            name="sameAsShipping" 
            value={sameAsShipping}           
          >
            <span className={styles.checkbox}>My billing address is the same as my shipping address</span>
          </FormCheckBox>
        </div> 
        {!sameAsShipping && (
          <>
            <div className={styles.halfWidth}>
              <FormSelect
                name="step2Country"
                label="Country *"
                className={styles.selectWithNoArrow}
                value={props.formModel.billing?.address?.country ?? props.formModel.shippingAddress?.country}
                options={props.billingCountries}
              />
              <FormInput
                name="step2Line1"
                label="Street address *"
                required={!sameAsShipping}
                value={props.formModel.billing?.address?.line}
              />
            </div>
            <div className={styles.halfWidth}>
              <FormInput
                name="step2City"
                label="Town/city *"
                required={!sameAsShipping}
                value={props.formModel.billing?.address?.city}
              />
              <FormInput
                name="step2StateProvence"
                label="State/province (optional)"
                value={props.formModel.billing?.address?.stateProvence}
              />
            </div>
            <div className={`${styles.halfWidth} ${styles.marginBottom}`}>
              <FormInput
                name="step2PostalCode"
                label="Post code / ZIP code *"
                required={!sameAsShipping}
                value={props.formModel.billing?.address?.postalCode}
              />
            </div>
          </>
        )}
        <div className={styles.buttons}>
          <Button
            color="white"
            title="Back"
            onClick={() => props.previous()}
            fullWidth
          />
          <Button
            type="submit"
            color="blue"
            title="Next"
            fullWidth
          />
        </div>
      </div>
    </Formsy>
  );
};

export default PaymentStep;
