import React from "react";
import styles from "./Faq.module.css";
import { AppContainer } from "../layout";
import { FaqItem } from "./components/FaqItem";
import { Link } from "react-router-dom";

export const Faq = () => {
  const mailLink = <a href="mailto:hello@uunn.co.uk">hello@uunn.co.uk</a>;
  return (
    <AppContainer>
      <div className={styles.root}>
        <div className={styles.header}>
          <h1>FAQs</h1>
          <h3>Our most asked questions</h3>
          <p>If your question hasn't been answered here, pop over an email to {mailLink}</p>
        </div>
        <div className={styles.section}>
          <hr />
          <FaqItem title="If I see my dentist and hygienist regularly, why do I need uunn?">
            <p>In short, we’re not replacements for each other...</p>
            <p>Visiting your dentist and hygienist regularly is crucial but the problem is that we only see them a handful of days in a year (at most).</p>
            <p>So, for the other 300 or so days, none of us actually know if we’re removing enough plaque or whether we’re missing any areas of plaque, to prevent tooth decay, gum disease and so much more.</p>
            <p>That’s why we created uunn.</p>
            <p>So you can check your teeth on a regular basis to know if your plaque level is low enough each week AND to detect which teeth need your special attention, to achieve a problem-free and brilliant-looking smile.</p>
          </FaqItem>
          <FaqItem title="Why is the goal to have less than 1% plaque?">
            <p>When it comes to our teeth, the ultimate goal is being able to clean them well, which means having very little plaque on our teeth. But how little? In order for us to put a number on this, we studied hundreds of images of teeth with varying degrees of plaque on them with dental experts. Our aim was to understand the % of plaque that is representative of someone who cleans their teeth well. The conclusive finding from the experts: having a total plaque level of under 1% means that you’re doing a good job at keeping your teeth clean, to prevent problems. We call this The Zone.</p>
          </FaqItem>
          <FaqItem title="Is uunn compatible with my phone?">
            <p>It is important to be aware before you purchase uunn that currently not all of uunn’s features listed above in their full glory are available for all phone models, so please check the list below to ensure you are happy with your purchase or whether you’d prefer to wait.</p>
            <p>We are working hard on making sure all our features are compatible with all phones, but we are still on that journey.</p>
            <p><u>Phones compatible with all uunn’s features (i.e. you get: uunn AI plaque detecting algorithm - gums and mouth review by a dentist - access to dental expert support - exclusive treatment rates with uunn partner clinics):</u></p>
            <p>Google Pixel 2 all models • Google Pixel 3 all models • Google Pixel 4 all models • Google Pixel 5 all models • iPhone 7 plus • iPhone 8 • iPhone 8 + • iPhone X • iPhone 11 • Samsung Galaxy S8 • Samsung Galaxy S8 + • Samsung Galaxy S9 • Samsung Galaxy S9 + • Samsung Galaxy S10 • Samsung Galaxy S10 + • Samsung Galaxy S8/S9/S10 note • Xiomi Poco X3 NFC</p>
            <p><u>Phones partly compatible with uunn’s features (i.e. you get: uunn AI plaque detecting algorithm BETA* - gums and mouth reviewed by dentist every 3-4 weeks - access to dental expert support - exclusive treatment rates with uunn partner clinics):</u></p>
            <p>iPhone XS • iPhone XR • iPhone 12/12 pro • Samsung S20/S20 FE/S20 FE 5G/S20 Plus/S21/S21 Plus • Samsung S22 • Samsung S22 Plus/S22 ultra</p>
            <p>*BETA - this means the AI plaque detecting technology accuracy is in development, and so the technology can miss some teeth focus areas where plaque resides. However, it is good to remember that your scans are reviewed by a dentist too who will pinpoint gum focus areas which ensures you are provided with all the insights you need to tackle the teeth and gums you need to focus on. The difference is that our AI takes minutes whereas your review from the dentist takes longer. We check in every 3-4 weeks depending on your needs.</p>
            <p>If you own one of the plus sized versions of the phones from either of the lists (fully functioning or the BETA version) then you might find fitting uunn onto your phone a little bit more of a tug but it will fit. We appreciate that this isn’t quite the perfect experience, but it is doable to enjoy all the benefits of uunn.</p>
            <p>We are continuously working on releasing more phone models, with the aim to ensure iPhone 13 onwards becomes available very soon.</p>
            <p>Let us know if you want uunn available on your phone model by emailing {mailLink}</p>
          </FaqItem>
          <FaqItem title="Can anyone use uunn?">
            <p>Our algorithm is currently unable to detect your  plaque if you wear metal braces, for the moment!</p>
            <p>If you wear clear aligners such as Invisalign, all you gotta do is take them off and you're ready to scan with uunn.</p>
            <p>Please also note that the uunn lens is not designed for people under the age of 16 (yet).</p>
          </FaqItem>
          <FaqItem title="How does uunn’s technology work?">
            <p>No x-rays, dyes, special lighting or anything like that.</p>
            <p>All you need to do is take three photos of your teeth using just your phone camera and our magical phone attachment, which we call ‘the lens’.</p>
            <p>Then, let our artificially intelligent, image-processing algorithm in the uunn app do the rest of the work, to detect and quantify your plaque, so you can know if it’s low enough (in The Zone) and know where it is, so you can brush and floss it away.</p>
          </FaqItem>
          <FaqItem title="What camera does uunn use to scan my teeth?">
            <p>The lens that you get in your starter pack slides over your phone's camera; this is the camera that’s used to take photos of your teeth so no additional cameras are needed nor is there a camera in our lens either.</p>
            <p>You know what that means? Our lens doesn’t need any Bluetooth pairing, any charging or batteries. Hurrah!</p>
          </FaqItem>
          <FaqItem title="Wil uunn work if my phone’s camera is scratched or cracked?">
            <p>The camera we use to scan your teeth is the one at the back of your phone (in other words, not the selfie camera). If you’re unable to take photos with this ‘back’ camera or if the photos are affected in any way, then it will affect uunn’s accuracy or the ability to actually use uunn. If you’re unsure, feel free to send us a photo to {mailLink} and we’ll share what we think.</p>
          </FaqItem>
          <FaqItem title="What’s included in the ‘starter pack’?">
            <p>Our starter pack includes a re-usable lens that you should only need to purchase once and an uunn app account that includes 12 scans, that you can choose to use once, twice or three times a week.</p>
          </FaqItem>
          <FaqItem title="What happens when I run out of scans?">
            <p>Our starter pack includes 12 scans, that you can choose to use once, twice or three times a week.</p>
            <p>Once you run out of these scans, you can upgrade to one of our monthly membership plans (we’ll even recommend the best plan for you).</p>
          </FaqItem>
          <FaqItem title="Do I only need to buy the lens once?">
            <p>If well cared for, your lens should last a lifetime because we designed it to last as long as possible.</p>
            <p>If your lens gets a micro dent or a teeny spec, we’d urge you to continue to use it with pride!  We don’t believe in discarding the imperfect. However, in the unlikely event that your lens develops a functionality fault, pop over an email to hello@uunn.co.uk and we’ll arrange for you to get another.</p>
            <p>You can read about how we’ll be recycling any unfortunately-faulty lenses here.</p>
          </FaqItem>
          <FaqItem title="How should I care for my lens?">
            <p>Just rinse your lens with cold water after each use, just like your toothbrush. It’s that simple.</p>
          </FaqItem>
          <FaqItem title="Is the lens environmentally friendly?">
            <p>We take full responsibility for doing our part for the planet. That's why we’ve meticulously designed the lens to be as small as possible, to use as little material as possible. This is in effort to reduce our carbon footprint as well as your postal costs.</p>
            <p>We’ve also designed the uunn lens to last as long as possible. And, when it does come to the end of its life (if it develops a functional fault)- we're offering free postage back to us because it's our responsibility to make sure that the plastic we produce, is recycled responsibly.</p>
          </FaqItem>
          <FaqItem title="What countries does uunn ship to?">
            <p>At the moment, we only ship to the UK but we’re working on going global!</p>
            <p>If you sign-up to our newsletter (scroll to the bottom of this page), you’ll be the first to know when we’re shipping to your country.</p>
          </FaqItem>
          <FaqItem title="Can I get a refund on my starter pack?">
            <p>It’d break our hearts knowing that your experience with uunn wasn’t great but we do of course accept refunds.</p>
            <p>If you’ve only used one scan within 14 days of receiving the starter pack, we will issue a part refund (we will deduct and retain the cost of the lens and postage). This is for hygiene reasons, since the lens goes into your mouth.</p>
            <p>If you haven’t unsealed the uunn lens, then we can issue a full refund, if you request it within 14 days of receiving it.</p>
            <p>To read more about our refund policy, <Link to="/terms-sale">click here</Link>.</p>
            <p>To request a refund, please pop over an email to {mailLink}.</p>
          </FaqItem>
          <FaqItem title="Who are our partner clinics?">
            <p>London:</p>
            <p>Bond Dental Bloomsbury • Acton Town Dental Practice • Bond Dental Harley Street • Serene Dental Knightsbridge • Twoth Dental + Facial Aesthetics</p>
          </FaqItem>
        </div>
      </div>
    </AppContainer>
  );
};
