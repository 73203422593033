import React, { Component } from "react";
import styles from "./Input.module.css";
import { ReactComponent as ShowTextImage } from "./static/show-text.svg";
import { ReactComponent as HideTextImage } from "./static/hide-text.svg";
import { replaceDateRegexValues } from './utils';
import TooltipImage from "./static/tooltip.png";
import { InputError } from './InputError';

const MIN_DATE_LENGTH = 14;

interface Props {
  name: string;
  type: string;
  label?: string;
  placeholder?: string;
  tooltip?: string;
  error?: string;
  defaultValue?: string;
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
}

interface State {
  showText: boolean;
  isTooltipOpen: boolean;
}

class Input extends Component<Props, State> {
  private inputRef = React.createRef<HTMLInputElement>();

  constructor(props: Props) {
    super(props);

    this.state = {
      showText: false,
      isTooltipOpen: false,
    };

    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleOnKeyChange = this.handleOnKeyChange.bind(this);
  }

  handleOnChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (this.props.onChange) {
      const inputEvent = {
        target: {
          name: event.target.name,
          value: event.target.value,
        },
      };

      // Remove date spaces before onChange
      if ( this.props.type === "date" ) {
        inputEvent.target.value = event.target.value.replace(/\s{1,}/g, ""); // remove current spaces,
      }

      this.props.onChange(inputEvent as React.ChangeEvent<HTMLInputElement>);
    }
  }

  handleOnKeyChange() {
    // Add / between numbers
    if (this.props.type === "date" && this.inputRef.current) {
      this.inputRef.current.value = replaceDateRegexValues(this.inputRef.current.value);
    }
  }

  render() {
    const { label, name, type, error, className, defaultValue, tooltip, ...rest } = this.props;
    const { showText, isTooltipOpen } = this.state;
    const showInputText = showText || type === "date";
    const id = Math.random().toString(36).substring(7);
    let classes = `${styles.root}`;

    if (className) {
      classes += ` ${className}`;
    }
    if (error) {
      classes += ` ${styles.error}`;
    }

    return (
      <div className={`${classes}`}>
        {label && (
          <div className={styles.label}>
            <label htmlFor={id}>{label}</label>
          </div>
        )}
        <div className={styles.buttonContainer}>
          <input
            ref={this.inputRef}
            type={showInputText ? "text": type}
            maxLength={type === "date" ? MIN_DATE_LENGTH : undefined}
            name={name}
            id={id}
            onKeyUp={this.handleOnKeyChange}
            onKeyDown={this.handleOnKeyChange}
            onChange={this.handleOnChange}
            onBlur={this.props.onBlur}
            defaultValue={defaultValue}
            {...rest}
          />
          {(type === "password" && !showText) && <ShowTextImage onClick={() => this.setState({ showText: true })} />}
          {(type === "password" && showText) && <HideTextImage onClick={() => this.setState({ showText: false })} />}
          {tooltip && (
            <img
              src={TooltipImage}
              alt="tooltip"
              onClick={() => this.setState({ isTooltipOpen: !isTooltipOpen })}
              onMouseEnter={() => this.setState({ isTooltipOpen: true })}
              onMouseLeave={() => this.setState({ isTooltipOpen: false })}
            />
          )}
          {isTooltipOpen && <span className={styles.tooltipText}>{tooltip}</span>}
        </div>
        {error && <InputError error={error} className={styles.errorText} />}
      </div>
    );
  }
}

export default Input;
