import axios from "../../../network";

export const confirmAccount = async (
  activationCode: string,
  email: string,
  name: string,
  dateOfBirth: string,
  password: string
): Promise<void> => {
  return axios.post(`/accounts`, { activationCode, email, name, dateOfBirth, password });
};
