import React from "react";
import { Button, Popup } from "../shared";
import { PersonalDetailsPopup } from "./forms/PersonalDetailsPopup";
import { ReactComponent as EditImage } from "./static/edit.svg";
import styles from "./PersonalDetails.module.css";
import { BIRTH_DATE_FIELD, NAME_FIELD } from "./models";
import { PasswordPopup } from "./forms/PasswordPopup";
import { CognitoUser } from "amazon-cognito-identity-js";

interface Props {
  user: CognitoUser;
  attributes: { [key: string]: string };
  refreshAttributes: () => void;
}

export const PersonalDetails: React.FC<Props> = ({ user, attributes, refreshAttributes }) => {
  return (
    <div className={styles.root}>
      <h1>Personal details</h1>
      <div className={styles.table}>
        <div>
          <PersonalDetailsPopup
            trigger={
              <div className={styles.item}>
                <div className={styles.title}>
                  <h4>Name</h4>
                  <EditImage />
                </div>
                <p className={styles.lightBlue}>{attributes[NAME_FIELD]}</p>
              </div>
            }
            user={user}
            attributes={attributes}
            onComplete={() => refreshAttributes()}
          />
          <PersonalDetailsPopup
            trigger={
              <div className={styles.item}>
                <div className={styles.title}>
                  <h4>Date of birth</h4>
                  <EditImage />
                </div>
                <p className={styles.lightBlue}>{attributes[BIRTH_DATE_FIELD]}</p>
              </div>
            }
            user={user}
            attributes={attributes}
            onComplete={() => refreshAttributes()}
          />
        </div>
        <div>
          <PasswordPopup
            trigger={
              <div className={styles.item}>
                <div className={styles.title}>
                  <h4>Password</h4>
                  <EditImage />
                </div>
                <p className={styles.lightBlue}>••••••••••••</p>
              </div>
            }
            user={user}
          />
        </div>
      </div>
      <Popup
        trigger={
          <Button
            color="red"
            title="Delete account"
            fullWidth
            className={styles.deleteButton}
            disableOnClickEventInline
          />
        }
        headerText="We’re sad to see you go but the time we spent together was great"
        maxWidth="500px"
      >
        {close => (
          <div className={styles.deletePopup}>
            <p>If you’re certain you want to delete your account and all associated data permanently, please email us to let us know.</p>
            <Button
              color="red"
              title="Contact to delete account"
              to="mailto:hello@uunn.co.uk?subject=I want to delete my account"
              newWindow
              fullWidth
              onClick={() => close()}
            />
            <Button
              color="blue"
              variant="borderless"
              title="No I’ve changed my mind"
              fullWidth
              onClick={() => close()}
            />
          </div>
        )}
      </Popup>
    </div>
  );
};
