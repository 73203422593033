import React, { Component } from "react";
import { Auth } from "@aws-amplify/auth";
import styles from "./ResetPassword.module.css";
import { AppContainer } from "../layout";
import { Input, Button } from "../shared";
import { validEmail, validPasswordConstraint } from "../utils";

export class ResetPassword extends Component {
  constructor(props) {
    super(props);

    const splitCode = window.location.href.split("?code="); // some codes may come with & as special character so queryString do not work

    if ( splitCode && splitCode.length > 1 ) {
      // Get code
      this.urlCode = decodeURIComponent(splitCode[1]);
    }

    this.state = {
      // control fields
      step: this.urlCode && this.urlCode.length ? 3 : 1,
      disabled: false,
      // form fields
      email: "",
      password: "",
      confirmPassword: "",
      // form errors
      errors: {
        email: undefined,
        password: undefined,
        confirmPassword: undefined,
        urlCode: undefined,
      },
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
    return true;
  }

  checkValidForm(step) {
    const errors = {
      email: undefined,
      password: undefined,
      confirmPassword: undefined,
      urlCode: undefined,
    };

    if (step === 1) {
      const { email } = this.state;

      if (email.length === 0 || !validEmail(email)) {
        errors.email = "Email is not valid";
      }
    } else if (step === 3) {
      const { email, password, confirmPassword } = this.state;

      if (email.length === 0 || !validEmail(email)) {
        errors.email = "Email is not valid";
      }
      if (!this.urlCode || this.urlCode.length === 0) {
        errors.urlCode = "No code defined";
      }
      if (password.length < 8 || !validPasswordConstraint(password)) {
        errors.password = "Please choose an 8-character password with one upper case letter, one lower case letter, one number and one special character.";
      }
      if (password !== confirmPassword) {
        errors.confirmPassword = "Passwords do not match";
      }
    }

    this.setState({ errors });
    return Object.values(errors).filter(it => it).length ? false : true;
  }

  async handleSubmit(step) {
    if (this.checkValidForm(step)) {
      this.setState({ disabled: true, errors: {} }, async () => {

        if (step === 1) {
          const { email } = this.state;

          Auth.forgotPassword(email)
            .finally(() => this.setState({ step: 2 }))
            .catch(err => this.setState({ errors: { email: this.parseCognitoErrorMessages(err) }, disabled: false }));
        } else if (step === 3) {
          const { email, password } = this.state;

          Auth.forgotPasswordSubmit(email, this.urlCode, password)
            .then(() => this.setState({ step: 4 }))
            .catch(err => this.setState({ errors: { email: this.parseCognitoErrorMessages(err) }, disabled: false }));
        }
      });
    }
  }

  parseCognitoErrorMessages(err) {
    let error = err.message ?? "Error occurred";

    if (err.code === "ExpiredCodeException") {
      error = "Oops, this reset password link was valid for 24 hours and has now expired. Please request a new password reset link.";
    }

    return error;
  }

  render() {
    const { step, disabled } = this.state;

    return (
      <AppContainer>
        <div className={styles.root}>
          {step === 1 && (
            <div className={styles.form}>
              <h1>Forgotten your password?</h1>
              <p>Don’t worry, just pop your email address below and</p>
              <p>we’ll send you instructions to reset it.</p>
              <div className={styles.inputs}>
                <Input
                  name="email"
                  type="email"
                  label="Email Address"
                  onChange={this.handleChange}
                  error={this.state.errors.email}
                />
              </div>
              <Button
                color="blue"
                title="Send"
                onClick={() => this.handleSubmit(step)}
                disabled={disabled}
              />
            </div>
          )}
          {step === 2 && (
            <div className={styles.messages}>
              <h1>Check your inbox!</h1>
              <p>We’ve sent you an email to reset your password.</p>
            </div>
          )}
          {step === 3 && (
            <div className={styles.form}>
              <h1>New password</h1>
              <div className={styles.inputs}>
                <Input
                  name="email"
                  type="email"
                  label="Email Address"
                  onChange={this.handleChange}
                  error={this.state.errors.email}
                />
                <Input
                  name="password"
                  type="password"
                  label="New password"
                  onChange={this.handleChange}
                  error={this.state.errors.password}
                />
                <Input
                  name="confirmPassword"
                  type="password"
                  label="Re-enter password"
                  onChange={this.handleChange}
                  error={this.state.errors.confirmPassword}
                />
              </div>
              <Button
                color="blue"
                title="Update"
                onClick={() => this.handleSubmit(step)}
                disabled={this.state.disabled}
              />
            </div>
          )}
          {step === 4 && (
            <div className={styles.messages}>
              <h1> It’s as easy as that! We’ve reset your password.</h1>
            </div>
          )}
        </div>
      </AppContainer>
    );
  }
}
