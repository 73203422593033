import React, { useState, useEffect } from "react";
import styles from "./ScanBalance.module.css";
import { Button, Popup } from "../../shared";
import { ReactComponent as ArrowRightImage } from "../static/arrowRight2.svg";
import { deleteSubscription, getBalance } from "../services";
import { ActiveSubscription } from "../models";

interface State {
  balance: number;
  loading: boolean;
  subscription?: ActiveSubscription;
}

export const ScanBalance: React.FC = () => {
  const [state, setState] = useState<State>({
    balance: -1,
    loading: true,
    subscription: undefined
  });
  const [isCancelPopupOpen, setCancelPopupOpen] = useState(false);
  const { balance, loading, subscription } = state;

  useEffect(() => {
    const fetchBalance = async () => {
      const response = await getBalance();

      setState({
        balance: response.balance,
        loading: false,
        subscription: response.subscription
      });
    };

    fetchBalance();
  }, []);
  const formatBillingDate = (date: string): string => {
    const d = new Date(date);
    return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
  };

  if (loading) {
    return <p>Loading your balance...</p>;
  }

  return (
    <div className={styles.root}>
      {!subscription ? (
        <div className={styles.pack}>
          <h1 className={styles.badge}>You have <span className={styles.red}>{balance}/{balance > 12 ? balance : 12}</span> scans left in your pack</h1>
          <div className={styles.container}>
            {balance === 0 ? (
              <>
                <p>Want to continue to keep your mouth problem free and looking brilliant?</p>
                <p>Based on your journey with us so far, we’ve selected a monthly subscription plan that’s best for you.</p>
                <Button color="blue" title="Let me see" to="/my-account/scans-subscription" fullWidth className={styles.button} />
              </>
            ) : (
              <>
                <p>We hope you’re loving uunn so far!</p>
                <p>If you have any issues, please do send us a message.</p>
                <Button color="white" title="Contact us" to="#contact" fullWidth className={styles.button} />
              </>
            )}
          </div>
        </div>
      ) : (
        <div className={styles.subscription}>
          <h1 className={styles.badge}>
            {balance === 0 ? (
              <>You have <span className={styles.red}>{balance}</span> scans until {formatBillingDate(subscription.nextBillingDate)}</>
            ) : (
              <>You have <span className={styles.red}>{balance}</span> scans in your account</>
            )}
          </h1>
          <div className={styles.container}>
            <Button
              color="transparent"
              to="/my-account/scans-subscription"
              fullWidth
            >
              <div className={styles.button}>
                <div>
                  <h4>Your scan plan</h4>
                  {(subscription.status === "Active" || subscription.status === "PaymentFailed") ? (
                    <p>{subscription.perMonth} for {subscription.amount} scans</p>
                  ) : (
                    <p className={styles.red}>Ending on {formatBillingDate(subscription.nextBillingDate)}</p>
                  )}
                </div>
                <ArrowRightImage />
              </div>
            </Button>
            {/* <Button color="transparent" to="#" fullWidth>
              <div className={styles.button}>
                <div>
                  <h4>Order history</h4>
                  <p>See every purchase you've made here</p>
                </div>
                <ArrowRightImage />
              </div>
            </Button> */}
            {subscription.id && (subscription.status === "Active" || subscription.status === "PaymentFailed") && (
              <Button color="transparent" fullWidth onClick={() => setCancelPopupOpen(true)}>
                <div className={styles.button}>
                  <div>
                    <h4>Want to cancel your plan?</h4>
                  </div>
                  <ArrowRightImage />
                </div>
              </Button>
            )}
          </div>
          <Popup
            open={isCancelPopupOpen}
            headerText="We’re sad to see you go but the time we spent together was great"
            maxWidth="500px"
            onClose={() => setCancelPopupOpen(false)}
          >
            {close => (
              <div className={styles.cancelPopup}>
                <p>Cancelling your plan means you no longer have access to scanning with uunn and any scans on your account will unfortunately be deleted.</p>
                <p>Your plan will be cancelled on the {formatBillingDate(subscription.nextBillingDate)} and you will not be charged.</p>
                <p>You sure you want to cancel your scan plan?</p>
                <Button
                  color="red"
                  title="Yes, cancel my plan"
                  fullWidth
                  onClick={async () => {
                    await deleteSubscription(subscription.id);
                    setState({
                      balance,
                      loading,
                      subscription: { ...subscription, status: "PendingCancel" }
                    });
                    close();
                  }}
                />
                <Button
                  color="blue"
                  variant="borderless"
                  title="No I’ve changed my mind"
                  fullWidth
                  onClick={() => close()}
                />
              </div>
            )}
          </Popup>
        </div>
      )}
    </div>
  );
};
