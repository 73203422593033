import axios from "../../../network";

/**
 * Endpoint to subscribe users to newsletter mailchimp list.
 * By default the subscribe to newsletter field is set as true.
 */
export const subscribeNewsletter = (
  email: string,
  phoneModel: string,
  name: string,
  subscribeNewsletter: boolean = true
): Promise<void> => {
  return axios.post(`/newsletters`, {
    email,
    phoneModel,
    name,
    subscribeNewsletter,
  });
};
