import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./Checkbox.module.css";
import { ReactComponent as SelectedImage } from "./static/selected.svg";
import { ReactComponent as UnselectedImage } from "./static/unselected.svg";

class Checkbox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: props.defaultValue || false,
    };
  }

  onClick(selected) {
    this.setState({ selected });

    if ( this.props.onChange ) {
      this.props.onChange({
        target: {
          name: this.props.name,
          value: selected,
        },
      });
    }
  }

  render() {
    const { children, className, disabled } = this.props;
    const { selected } = this.state;
    let classes = styles.root;

    if (className) {
      classes += ` ${className}`;
    }
    if (children) {
      classes += ` ${styles.children}`;
    }

    return (
      <div className={classes} onClick={() => !disabled && this.onClick(!selected)}>
        {selected
          ? <SelectedImage />
          : <UnselectedImage />
        }
        {children}
      </div>
    );
  }
}

Checkbox.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
};

export default Checkbox;
