import React from "react";
import styles from "./Steps.module.css";

interface Props {
  steps: string[];
  currentStep: number;
  onClick: (step: number) => void;
  className?: string;
  disabled?: boolean;
}

export const Steps: React.FC<Props> = ({ className, steps, currentStep, disabled, onClick }) => {
  let classes = `${styles.root}`;

  if (className) {
    classes += ` ${className}`;
  }

  return (
    <div className={classes}>
      {steps.map((step, index) => {
        const stepNumber = index + 1;
        const clickable = !disabled && stepNumber < currentStep;
        let classes = styles.step;

        if (stepNumber < currentStep) {
          classes += ` ${styles.completed}`;
        } else if (stepNumber === currentStep) {
          classes += ` ${styles.inProgress}`;
        }

        return (
          <div
            key={index}
            className={classes}
            onClick={() => clickable ? onClick(stepNumber) : undefined}
          >
            <div className={styles.node} />
            <h5>{step}</h5>
          </div>
        );
      })}
    </div>
  );
};
