import React from "react";
import styles from "./PrivacyPolicy.module.css";
import { AppContainer } from "../layout";

export const PrivacyPolicy = () => {
  return (
    <AppContainer>
      <div className={styles.root}>
        <h1>Onaria Technologies Ltd - Privacy Policy</h1>
        {/* Section 1 */}
        <div className={styles.section}>
          <p><b>1. Our approach to privacy</b></p>
          <p>1.1 The uunn product (“uunn”) is provided by Onaria Technologies Ltd, who is the controller and is responsible for your personal data (collectively referred to as Onaria, "we", "us" or "our" in this privacy policy).</p>
          <p>1.2 Onaria respects your privacy and is committed to protecting your personal data. This privacy policy will inform you how we look after your personal data when you visit our website (regardless of where you visit it from) or use uunn (which consists of a physical lens (the “Hardware”) and a mobile software application (the “App”). This policy will also tell you about your privacy rights and how the law protects you.</p>
          <p>1.3 It is important that you read this privacy policy together with any other privacy policy or fair processing policy we may provide on specific occasions when we are collecting, or processing, personal data about you, so that you are fully aware of how and why we are using your data. This privacy policy supplements other notices and privacy policies and is not intended to override them.</p>
          <p><b>2. Contact Details</b></p>
          <p>2.1 We have appointed a data privacy manager who is responsible for overseeing questions in relation to this privacy policy. If you have any questions about this privacy policy, including any requests to exercise your legal rights, please contact the data privacy manager using the details set out below.</p>
          <p>2.2 If you have any questions about this privacy policy or our privacy practices, please contact our data privacy manager in the following ways:</p>
          <p>Full name of contact: Gina Dorodvand<br />Email address: hello@uunn.co.uk<br />Postal address: City Launch Lab, Franklin Building, 124 Goswell Road, London, England, EC1V 7DP.<br />Telephone number: +447361626008</p>
          <p>2.3 You have the right to make a complaint at any time to the Information Commissioner's Office (ICO), the UK supervisory authority for data protection issues (www.ico.org.uk). We would, however, appreciate the chance to deal with your concerns before you approach the ICO so please contact us in the first instance.</p>
          <p><b>3. The personal data we collect about you</b></p>
          <p>3.1 Personal data, or personal information, means any information about an individual from which that person can be identified. It does not include data where the identity has been removed (anonymous data).</p>
          <p>3.2 We may collect, use, store and transfer different kinds of personal data about you which we have grouped together as follows:</p>
          <p><b>When you order and purchase the uunn product through our website or by communicating with us </b></p>
          <p>3.3 When you order the uunn product either through our website or by emailing us, you will be required to provide us with some basic information about you such as your first name, last name, contact details such as your home address, email address and telephone numbers, and your delivery address for the Hardware (<b>“Identity Data”</b>).</p>
          <p>3.4 You may also be required to provide us with your method of payment and billing address (<b>“Payment Data”</b>). However, this data may be collected by our third party payment services provider Stripe.</p>
          <p><b>When you set up an account with us</b></p>
          <p>3.5 Once you have purchased the uunn product, you will be required to set up an account in order to use the App. For this, we will need you to confirm your name, email address and date of birth, username and password (<b>“Profile Data”</b>).</p>
          <p><b>When you use the uunn product</b></p>
          <p>3.6 When you use the uunn product, you will need to use the Hardware to take photographs of your teeth, which will be used by the App to generate and detect information about your dental health (<b>“App Data”</b>).</p>
          <p><b>Other data that may be collected</b></p>
          <p>3.7 We may collect technical data automatically as you interact with our website and App including internet protocol (IP) address, your login data, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform, and other technology on the devices you use to access this website and our App (<b>“Technical Data”</b>). We may collect this personal data by using cookies, server logs and other similar technologies. We may also receive Technical Data about you if you visit other websites employing our cookies, or from third parties including analytics providers such as Google. Please see our <a href="/cookie-policy">cookie policy</a> for further details.</p>
          <p>3.8 We may collect usage data including information about how you use our website and App (<b>“Usage Data”</b>). We may collect this personal data by using cookies, server logs and other similar technologies. We may also receive Technical Data about you if you visit other websites employing our cookies, or from third parties including analytics providers such as Google.</p>
          <p>3.9 We may hold marketing and communications data through our website or by one of the other means of contact we make available to you, including your preferences in receiving marketing from us and our third parties and your communication preferences (<b>“Marketing and Communications Data”</b>).</p>
          <p>3.10 We may collect information about you from enquiries that you direct to us through our website or by one of the other means of contact we make available to you (<b>“Enquiry Data”</b>).</p>
          <p><b>Aggregated Data</b></p>
          <p>3.11 We also collect, use and share Aggregated Data such as statistical or demographic data for any purpose. Aggregated Data could be derived from your personal data but is not considered personal data in law as this data will not directly or indirectly reveal your identity. For example, we may aggregate your Usage Data to calculate the percentage of users accessing a specific website or service feature. However, if we combine or connect Aggregated Data with your personal data so that it can directly or indirectly identify you, we treat the combined data as personal data which will be used in accordance with this privacy policy.</p>
          <p><b>If you fail to provide personal data</b></p>
          <p>3.12 Where we need to collect personal data by law, or under the terms of a contract we have with you, and you fail to provide that data when requested, we may not be able to perform the contract we have or are trying to enter into with you. In this case, we may have to cancel a service you have with us but we will notify you if this is the case at the time.</p>
          <p><b>4. How we use your personal data</b></p>
          <p>4.1 We will only use your personal data when the law allows us to. Most commonly, we will use your personal data in the following circumstances:</p>
          <ol type="a">
            <li>Where we need to perform the contract we are about to enter into or have entered into with you.</li>
            <li>Where it is necessary for our legitimate interests (or those of a third party) and your interests and fundamental rights do not override those interests.</li>
            <li>Where we need to comply with a legal obligation.</li>
            <li>Where we have obtained your consent to do so.</li>
          </ol>
          <p><b>Purposes for which we use your personal data and our lawful basis</b></p>
          <p>4.2 We have set out below, in a table format, a description of all the ways we plan to use your personal data, and which of the legal bases we rely on to do so. We have also identified what our legitimate interests are where appropriate.</p>
          <p>4.3 Note that we may process your personal data for more than one lawful ground depending on the specific purpose for which we are using your data. Please contact us if you need details about the specific legal ground we are relying on to process your personal data where more than one ground has been set out in the table below.</p>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>Purpose/Activity</th>
                <th>Type of data</th>
                <th>Lawful basis for processing including basis of legitimate interest</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>To process your order when you purchase the uunn product</td>
                <td>
                  <ol type="a">
                    <li>Identity</li>
                    <li>Payment</li>
                  </ol>
                </td>
                <td>Performance of a contract with you</td>
              </tr>
              <tr>
                <td>To provide you with information and updates about your order (for instance relating to delivery and when you can activate your account)</td>
                <td>Identity</td>
                <td>Performance of a contract with you</td>
              </tr>
              <tr>
                <td>To set up your account</td>
                <td>
                  <ol type="a">
                    <li>Identity</li>
                    <li>Profile</li>
                  </ol>
                </td>
                <td>Performance of a contract with you</td>
              </tr>
              <tr>
                <td>
                  <p>To manage payments for our services including:</p>
                  <ol type="a">
                    <li>Manage payments, fees and charges</li>
                    <li>Collect and recover money owed to us</li>
                  </ol>
                </td>
                <td>
                  <ol type="a">
                    <li>Identity</li>
                    <li>Profile</li>
                    <li>Payment</li>
                  </ol>
                </td>
                <td>
                  <ol type="a">
                    <li>Performance of a contract with you</li>
                    <li>Necessary for our legitimate interests (to recover debts due to us)</li>
                  </ol>
                </td>
              </tr>
              <tr>
                <td>Collecting and generating data through your use of the uunn product</td>
                <td>
                  <ol type="a">
                    <li>Identity</li>
                    <li>Profile</li>
                    <li>App</li>
                  </ol>
                </td>
                <td>
                  <ol type="a">
                    <li>Performance of a contract with you</li>
                    <li>Consent (in respect of the conditions identified for processing sensitive personal data under Article 9 of the GDPR)</li>
                  </ol>
                </td>
              </tr>
              <tr>
                <td>
                  <p>To manage our relationship with you which will include:</p>
                  <ol type="a">
                    <li>Notifying you about changes to our terms or privacy policy</li>
                    <li>Asking you to leave a review or take a survey</li>
                  </ol>
                </td>
                <td>
                  <ol type="a">
                    <li>Identity</li>
                    <li>Profile</li>
                    <li>Marketing and Communications</li>
                  </ol>
                </td>
                <td>
                  <ol type="a">
                    <li>Performance of a contract with you</li>
                    <li>Necessary to comply with a legal obligation</li>
                    <li>Necessary for our legitimate interests (to keep our records updated and to study how customers use and rate our products and services)</li>
                  </ol>
                </td>
              </tr>
              <tr>
                <td>
                  <p>To enable you to partake in a prize draw, competition or complete a survey</p>
                  <ol type="a">
                    <li>Notifying you about changes to our terms or privacy policy</li>
                    <li>Asking you to leave a review or take a survey</li>
                  </ol>
                </td>
                <td>
                  <ol type="a">
                    <li>Identity</li>
                    <li>Profile</li>
                    <li>Usage</li>
                    <li>Marketing and Communications</li>
                  </ol>
                </td>
                <td>
                  <ol type="a">
                    <li>Performance of a contract with you</li>
                    <li>Necessary to comply with a legal obligation</li>
                    <li>Necessary for our legitimate interests (to study how customers use our products and services, to develop them and grow our business)</li>
                  </ol>
                </td>
              </tr>
              <tr>
                <td>To administer and protect our business and this website and our product (including troubleshooting, data analysis, testing, system maintenance, support, reporting and hosting of data)</td>
                <td>
                  <ol type="a">
                    <li>Identity</li>
                    <li>Technical</li>
                  </ol>
                </td>
                <td>
                  <ol type="a">
                    <li>Necessary for our legitimate interests (for running our business, provision of administration and IT services, network security, to prevent fraud and in the context of a business reorganisation or group restructuring exercise)</li>
                    <li>Necessary to comply with a legal obligation</li>
                  </ol>
                </td>
              </tr>
              <tr>
                <td>To deliver relevant website content and advertisements to you and measure or understand the effectiveness of the advertising we serve to you</td>
                <td>
                  <ol type="a">
                    <li>Identity</li>
                    <li>Profile</li>
                    <li>Usage</li>
                    <li>Marketing and Communications</li>
                    <li>Technical</li>
                  </ol>
                </td>
                <td>Necessary for our legitimate interests (to study how customers use our products services, to develop them, to grow our business and to inform our marketing strategy)</td>
              </tr>
              <tr>
                <td>To use data analytics to improve our website, products, services, marketing, customer relationships and experiences</td>
                <td>
                  <ol type="a">
                    <li>Technical</li>
                    <li>Usage</li>
                  </ol>
                </td>
                <td>Necessary for our legitimate interests (to define types of customers for our services, to keep our website updated and relevant, to develop our business and to inform our marketing strategy)</td>
              </tr>
              <tr>
                <td>To make suggestions and recommendations to you about products and services that may be of interest to you</td>
                <td>
                  <ol type="a">
                    <li>Identity</li>
                    <li>Technical</li>
                    <li>Usage</li>
                    <li>Profile</li>
                    <li>Marketing and Communications</li>
                  </ol>
                </td>
                <td>Necessary for our legitimate interests (to develop our products/services and grow our business)</td>
              </tr>
            </tbody>
          </table>
          <p><b>Marketing</b></p>
          <p>4.4 We strive to provide you with choices regarding certain personal data uses, particularly around marketing and advertising. We have established the following personal data control mechanisms:</p>
          <ol type="a">
            <li>We may use your Identity, Technical, Usage and Profile Data to form a view on what we think you may want or need, or what may be of interest to you. This is how we decide which products, services and offers may be relevant for you (we call this marketing).</li>
            <li>You will receive marketing communications from us if you have requested information from us or purchased products or services from us and you have not opted out of receiving that marketing.</li>
            <li>Usage</li>
            <li>Profile</li>
            <li>We will get your express opt-in consent before we share your personal data with any third party for marketing purposes.</li>
            <li>You can ask us or third parties to stop sending you marketing messages at any time by contacting us using the details above, or by clicking on the unsubscribe link in marketing emails.</li>
            <li>Where you opt out of receiving these marketing messages, this will not apply to personal data provided to us as a result of service or product purchase, service experience or other transactions.</li>
          </ol>
          <p><b>Cookies</b></p>
          <p>4.5 You can set your browser to refuse all or some browser cookies, or to alert you when websites set or access cookies. If you disable or refuse cookies, please note that some parts of this website may become inaccessible or not function properly. For more information about the cookies we use, please see our <a href="/cookie-policy">cookie policy</a>.</p>
          <p><b>Change of purpose</b></p>
          <p>4.6 We will only use your personal data for the purposes for which we collected it, unless we reasonably consider that we need to use it for another reason and that reason is compatible with the original purpose. If you wish to get an explanation as to how the processing for the new purpose is compatible with the original purpose, please contact us using the details provided above.</p>
          <p>4.7 If we need to use your personal data for an unrelated purpose, we will notify you and we will explain the legal basis which allows us to do so.</p>
          <p>4.8 Please note that we may process your personal data without your knowledge or consent, in compliance with the above rules, where this is required or permitted by law.</p>
          <p><b>5. How long will we store your personal information</b></p>
          <p>5.1 We will only retain your personal data for as long as reasonably necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, regulatory, tax, accounting or reporting requirements. We may retain your personal data for a longer period in the event of a complaint or if we reasonably believe there is a prospect of litigation in respect to our relationship with you.</p>
          <p>5.2 To determine the appropriate retention period for personal data, we consider the amount, nature and sensitivity of the personal data, the potential risk of harm from unauthorised use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal, regulatory, tax, accounting or other requirements.</p>
          <p>5.3 By law we may have to keep basic information about our customers (including Identity, Financial and Transaction Data) for six years after they cease being customers for tax purposes.</p>
          <p>5.4 In some circumstances you can ask us to delete your data: see your legal rights below for further information.</p>
          <p>5.5 In some circumstances we will anonymise your personal data (so that it can no longer be associated with you) for research or statistical purposes, in which case we may use this information indefinitely without further notice to you.</p>
          <p><b>6. Transfers of personal information</b></p>
          <p>6.1 We may share your personal data with the parties set out below, or alternatively they may collect and process your personal data on our behalf, for the purposes set out in the table above.</p>
          <ol type="a">
            <li>Stripe, who process online payments on our behalf and are based in the US. Stripe are GDPR compliant under the Privacy Shield.</li>
            <li>Professional advisers acting as processors or joint controllers including lawyers, bankers, auditors and insurers who provide consultancy, banking, legal, insurance and accounting services.</li>
            <li>HM Revenue &amp; Customs, regulators and other authorities acting as processors or joint controllers based in the United Kingdom who require reporting of processing activities in certain circumstances.</li>
            <li>Third parties to whom we may choose to sell, transfer or merge parts of our business or our assets. Alternatively, we may seek to acquire other businesses or merge with them. If a change happens to our business, then the new owners may use your personal data in the same way as set out in this privacy policy.</li>
          </ol>
          <p>6.2 We require all third parties to respect the security of your personal data and to treat it in accordance with the law. We do not allow our third-party service providers to use your personal data for their own purposes and only permit them to process your personal data for specified purposes and in accordance with our instructions.</p>
          <p><b>7. International Transfers</b></p>
          <p>7.1 Some of our external third parties are based outside the EEA (as set out above) so their processing of your personal data will involve a transfer of data outside the EEA.</p>
          <p>7.2 Whenever we transfer your personal data out of the EEA, we ensure a similar degree of protection is afforded to it by ensuring at least one of the following safeguards is implemented:</p>
          <ol type="a">
            <li>We will only transfer your personal data to countries that have been deemed to provide an adequate level of protection for personal data by the European Commission. </li>
            <li>Where we use certain service providers, we may use specific contracts approved by the European Commission which give personal data the same protection it has in Europe. </li>
            <li>Some service providers may be based in jurisdictions whereby compliance can be guaranteed if they are part of an approved certification mechanism. </li>
            <li>Where we use providers based in the US, we may transfer data to them if they are part of the Privacy Shield which requires them to provide similar protection to personal data shared between Europe and the US. </li>
          </ol>
          <p>Please contact us if you want further information on the specific mechanism used by us when transferring your personal data out of the EEA.</p>
          <p><b>8. Data Security</b></p>
          <p>8.1 We implement appropriate technical and organisational measures to protect your personal information against accidental or unlawful destruction, loss, change or damage. All personal information we collect will be stored on secure servers. We will never send you unsolicited emails or contact you by phone requesting your account ID, password, credit or debit card information or national identification numbers.</p>
          <p>8.2 We limit access to your personal data to those employees, agents, contractors and other third parties who have a business need to know. They will only process your personal data on our instructions and they are subject to a duty of confidentiality.</p>
          <p>8.3 We have put in place procedures to deal with any suspected personal data breach and will notify you and any applicable regulator of a breach where we are legally required to do so.</p>
          <p><b>9. Your rights in respect of your personal information</b></p>
          <p>9.1 In accordance with applicable privacy law, you have the following rights in respect of your personal information that we hold:</p>
          <p><b>a) Right of access.</b> You have the right to obtain:</p>
          <p>i) confirmation of whether, and where, we are processing your personal information;</p>
          <p>ii) information about the categories of personal information we are processing, the purposes for which we process your personal information and information as to how we determine applicable retention periods;</p>
          <p>iii) information about the categories of recipients with whom we may share your personal information; and</p>
          <p>iv) a copy of the personal information we hold about you. </p>
          <p><b>b) Right of portability.</b> You have the right, in certain circumstances, to receive a copy of the personal information you have provided to us in a structured, commonly used, machine-readable format that supports re-use, or to request the transfer of your personal data to another person.</p>
          <p><b>c) Right to rectification.</b> You have the right to obtain rectification of any inaccurate or incomplete personal information we hold about you without undue delay.</p>
          <p><b>d) Right to erasure.</b> You have the right, in some circumstances, to require us to erase your personal information without undue delay if the continued processing of that personal information is not justified.</p>
          <p><b>e) Right to restriction.</b> You have the right, in some circumstances, to require us to limit the purposes for which we process your personal information if the continued processing of the personal information in this way is not justified, such as where the accuracy of the personal information is contested by you.</p>
          <p>9.2 You also have the right to object to any processing based on our legitimate interests where there are grounds relating to your particular situation. There may be compelling reasons for continuing to process your personal information, and we will assess and inform you if that is the case. You can object to marketing activities for any reason.</p>
          <p>9.3 If you wish to exercise one of these rights, please contact us using the contact details at the start of this Privacy Policy.</p>
          <p>9.4 You may also review and edit some of the personal information you have submitted to us through the appropriate functionality on the Apps.</p>
          <p>9.5 Due to the confidential nature of data processing we may ask you to provide proof of identity when exercising the above rights. This can be done by providing a scanned copy of a valid identity document or a signed photocopy of a valid identity document.</p>
          <p>9.6 We will seek to respond to any request relating to your rights within one month of receipt of such request.</p>
          <p>9.7 Where, given the complexity of the claim or the number of requests received, the above deadline cannot be met, we will inform you of the extended deadline in which we will respond to your request. Such extension may not be more than two months from the date on which we notify you that an extension is required.</p>
          <p>9.8 Where we do not follow up on your request, we will inform you within the one month deadline of the grounds on which we have based our decision and of you right to refer a complaint to your national data protection authority.</p>
          <p>9.9 You will not have to pay a fee to access your personal data (or to exercise any of the other rights). However, we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive. Alternatively, we could refuse to comply with your request in these circumstances.</p>
          <p><b>10. Third Party Links</b></p>
          <p>This website and the App may include links to third-party websites, plug-ins and applications. Clicking on those links or enabling those connections may allow third parties to collect or share data about you. We do not control these third-party websites and are not responsible for their privacy statements. When you leave our website, we encourage you to read the privacy policy of every website you visit.</p>
          <p><b>11. Our policy towards children</b></p>
          <p>Our Site and Apps are not directed at persons under 18 and we do not knowingly collect personal information from any persons under 18. If you become aware that your child has provided us with personal information, without your consent, then please contact us using the details below so that we can take steps to remove such information and terminate any account your child has created with us.</p>
          <p><b>12. Changes to this policy</b></p>
          <p>12.1 We may update this Privacy Policy from time to time and so you should review this page periodically. When we change this Privacy Policy in a material way, we will update the "last modified" date at the end of this Privacy Policy. Changes to this Privacy Policy are effective when they are posted on this page.</p>
          <p>12.2 This Privacy Policy was last modified on 11th of July 2022.</p>
          <p><b>13. Notice to you</b></p>
          <p>If we need to provide you with information about something, whether for legal, marketing or other business related purposes, we will select what we believe is the best way to get in contact with you. We will usually do this through email or by placing a notice on our website or our App. The fact that we may send notices to you will not stop you from being able to opt out of certain types of contact as described in this Privacy Policy.</p>
        </div>
      </div>
    </AppContainer>
  );
};
