import React from "react";
import { Button, Select } from "../../shared";
import { Product } from "./CartStep";
import styles from "./QuantitySelector.module.css";
import StarterPackImage from "./static/starterPack.jpg";
import { ReactComponent as CloseImage } from "./static/cross.svg";
import { Visibility } from "../../layout";
import { reset } from "../../layout/header/reducers/cartCounterSlice";
import { useAppDispatch } from "../../../store/hooks";

interface Props {
  selectedProduct: Product;
  products: Product[];
  onChange: (productId: string) => void;
  disabled?: boolean;
}

const QuantitySelector: React.FC<Props> = ({ selectedProduct, onChange, products, disabled }) => {
  const dispatch = useAppDispatch();
  const starterPackPrice = <>£{selectedProduct.price / 100}</>;

  return (
    <div className={styles.root}>
      <div>
        <h2>Items</h2>
        <div className={styles.items}>
          <img src={StarterPackImage} alt="alt" />
          <div>
            <Visibility size="small" type="visible">
              <h3>{selectedProduct.amount} Starter Pack{selectedProduct.amount > 1 ? "s" : ""}</h3>
            </Visibility>
            <Visibility size="small" type="hidden">
              <h3>Starter Pack {starterPackPrice}</h3>
            </Visibility>
            <p className={styles.lightBlue}>{selectedProduct.amount} x uunn lens</p>
            <p className={styles.lightBlue}>{selectedProduct.amount} x 12 scans on uunn app</p>
            <Visibility size="small" type="visible">
              <h3>{starterPackPrice}</h3>
            </Visibility>
            {/* <Visibility size="small" type="hidden">
              <h4 className={styles.red}>The more starter packs you buy, the more money you save.</h4>
            </Visibility> */}
          </div>
        </div>
      </div>
      <div>
        <Visibility size="small" type="visible">
          <hr />
        </Visibility>
        <h3>Quantity</h3>
        <div className={styles.quantity}>
          <Select
            name="productId"
            className={styles.quantitySelector}
            disabled={disabled}
            options={products}
            defaultValue={(selectedProduct && selectedProduct.value) || undefined}
            onChange={event => onChange(event.target.value)}
          />
          <Button
            color="red"
            to="/"
            onClick={() => dispatch(reset())}
            fullWidth
            className={styles.quantityRemove}
          >
            <div className={styles.container}>
              <span>Remove</span>
              <Visibility size="large" type="visible">
                <CloseImage />
              </Visibility>
            </div>
          </Button>
        </div>
        {/* <Visibility size="small" type="visible">
          <h5 className={styles.red}>The more starter packs you buy,<br />the more money you save.</h5>
        </Visibility> */}
      </div>
    </div>
  );
};

export default QuantitySelector;
