import React from "react";
import { default as ReactPopup } from "reactjs-popup";
import styles from "./PlayButton.module.css";
import { Button } from "../../shared";
import { ReactComponent as PlayImage }  from "../static/play.svg";

export const PlayButton = ({ className }) => {
  let classes = styles.root;

  if (className) {
    classes += ` ${className}`;
  }

  return (
    <div className={classes}>
      <ReactPopup
        trigger={
          <Button
            color="transparent"
            icon={PlayImage}
            disableOnClickEventInline
          />
        }
        modal
        closeOnDocumentClick
        closeOnEscape
        contentStyle={{
          width: "95%",
          maxWidth: "1200px",
          background: "initial",
          border: "0"
        }}
      >
        <iframe
          src="https://player.vimeo.com/video/538761460"
          title="playButton"
          frameBorder="0"
          allow="fullscreen"
          allowFullScreen
        />
      </ReactPopup>
    </div>
  );
};
