import React, { Component } from "react";
import styles from "./Select.module.css";
import { ReactComponent as ArrowDownImage } from "./static/arrowDown.svg";
import { InputError } from "../input/InputError";

interface Props {
  name: string;
  options: Array<{ name: string, value: string }>;
  label?: string;
  error?: string;
  defaultValue?: string;
  className?: string;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

class Select extends Component<Props> {
  private selectRef = React.createRef<HTMLSelectElement>();

  constructor(props: Props) {
    super(props);

    this.handleOnChange = this.handleOnChange.bind(this);
  }

  handleOnChange(event: React.ChangeEvent<HTMLSelectElement>) {
    if (this.props.onChange) {
      const selectEvent = {
        target: {
          name: event.target.name,
          value: event.target.value,
        },
      };

      this.props.onChange(selectEvent as React.ChangeEvent<HTMLSelectElement>);
    }
  }

  render() {
    const { label, name, options, error, className, defaultValue, ...rest } = this.props;
    const id = Math.random().toString(36).substring(7);
    let classes = `${styles.root}`;

    if (className) {
      classes += ` ${className}`;
    }
    if (error) {
      classes += ` ${styles.error}`;
    }
    if (!Array.isArray(options)) {
      return false;
    }

    return (
      <div className={`${classes}`}>
        {label && (
          <div className={styles.label}>
            <label htmlFor={id}>{label}</label>
          </div>
        )}
        <div className={styles.buttonContainer}>
          <select
            ref={this.selectRef}
            name={name}
            id={id}
            onChange={this.handleOnChange}
            defaultValue={defaultValue}
            {...rest}
          >
            {options && options.map((option, key) => {
              return (
                <option key={key} value={option.value}>
                  {option.name}
                </option>
              );
            })}
          </select>
          <ArrowDownImage />
        </div>
        {error && <InputError error={error} className={styles.errorText} />}   
      </div>
    );
  }
}

export default Select;
