import axios from "../../../network";
import { Product } from "../components/CartStep";
import { Address } from "../components/CheckoutForm";

export const getProducts = async (): Promise<Product[]> => {
  return (await axios.get(`/products`)).data;
};

export const getShippingRate = async (countryCode: string): Promise<{ rate: number }> => {
  return (await axios.get(`/shipping/${countryCode}`)).data;
};

interface StockResponse {
  stock: boolean;
}

export const getStock = async (): Promise<StockResponse> => {
  return (await axios.get(`/stock`)).data;
};

interface CreatePaymentResponse {
  clientSecret: string;
  paymentId: string;
}

export const createPayment = async (itemId: string): Promise<CreatePaymentResponse> => {
  return (await axios.post(`/payment`, { itemId })).data;
};

interface PromoCodeResponse {
  found: boolean;
  coupon: string;
  amount: number;
};

export const updatePayment = async (
  name: string,
  email: string,
  paymentId: string,
  address: Address,
  priceId?: string,
  promoCode?: string
): Promise<PromoCodeResponse> => {
  return (await axios.put(`/payment`, {
    name,
    email,
    paymentId,
    shipping: { address },
    priceId,
    promoCode
  })).data;
};

export const applyPromo = async (discount: string): Promise<PromoCodeResponse> => {
  return (await axios.get(`/promoCodes/${discount}`)).data;
};
