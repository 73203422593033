import React, { useState } from "react";
import { validDateRegex, validPasswordRegex } from "../utils";
import styles from "./ConfirmAccount.module.css";
import { AppContainer } from "../layout";
import Formsy from "formsy-react";
import FormInput from "../shared/input/FormInput";
import FormCheckBox from "../shared/checkbox/FormCheckBox";
import FormPassword from "../shared/input/FormPassword";
import FormDate from "../shared/input/FormDate";
import { confirmAccount } from "./services";
import { Button } from "../shared";
import { Link } from "react-router-dom";
import { InputError } from "../shared/input/InputError";

export const ConfirmAccount = (props: any) => {
  const [isDisabled, setDisabled] = useState(false);
  const [submitError, setSubmitError] = useState<string | undefined>(undefined);
  const [activationCodeError, setActivationCodeError] = useState<string | undefined>(undefined);
  const [emailError, setEmailError] = useState<string | undefined>(undefined);

  return (
    <AppContainer containerFullWidth>
      <div className={styles.root}>
        <h1>Hurrah!</h1>
        <h1>It’s time to create<br />your account</h1>
        {submitError && <InputError error={submitError} />}
        <Formsy
          onValidSubmit={async (model) => {
            setDisabled(true);
            setSubmitError("");
            setActivationCodeError("");
            setEmailError("");

            try {
              await confirmAccount(
                model.activationCode,
                model.email,
                model.firstName + " " + model.lastName,
                model.birthDate,
                model.password
              );
              window.location.replace("/download");
            } catch (err) {
              console.error(err);

              if (err.response.data.errorCode === "AccountUserEmailAlreadyExists") {
                setEmailError("Oops! This email address is already associated with an account.");
              } else if (err.response.data.errorCode === "ActivationCodeNotFound") {
                setActivationCodeError("Oops! This activation code is incorrect.");
              } else {
                setSubmitError(err.response.data.message ?? "Error occurred");
              }

              setDisabled(false);
            }
          }}
        >
          <div className={styles.halfWidth}>
            <FormInput
              name="activationCode"
              label="Your unique activation code*"
              tooltip="We've printed your unique activation code onto a piece of card and placed it on top of your lens."
              error={activationCodeError}
              required
              onChange={() => setActivationCodeError("")}
            />

            <FormDate
              name="birthDate"
              label="Date of birth *"
              required
              validations={{
                matchRegexp: validDateRegex()
              }}
              validationError="Invalid DD/MM/YYYY format"
            />
          </div>
          <div className={styles.halfWidth}>
            <FormInput name="firstName" label="First name*" required />

            <FormInput name="lastName" label="Last name*" required />
          </div>
          <div className={styles.halfWidth}>
            <FormInput
              name="email"
              label="Enter email address*"
              validations="isEmail"
              validationError="Invalid email"
              error={emailError}
              required
              onChange={() => setEmailError("")}
            />

            <FormInput
              name="emailConfirmation"
              label="Confirm email address*"
              validations={{ isEmail: true, equalsField: "email" }}
              validationErrors={{
                isEmail: "Invalid email",
                equalsField: "Emails don’t match"
              }}
              required
            />
          </div>
          <div className={styles.halfWidth}>
            <FormPassword
              name="password"
              label="Enter password*"
              validations={{
                matchRegexp: validPasswordRegex()
              }}
              validationError="Please choose an 8-character password with one upper case letter, one lower case letter, one number and one special character."
              required
            />

            <FormPassword
              name="confirmPassword"
              label="Confirm password*"
              validations={{
                equalsField: "password",
                matchRegexp: validPasswordRegex(),
              }}
              validationErrors={{
                equalsField: "Passwords don’t match",
                matchRegexp: "Please choose an 8-character password with one upper case letter, one lower case letter, one number and one special character"
              }}
              required
            />
          </div>
          <div className={styles.marginBottom}>
            <FormCheckBox
              name="checkboxConditions"
              validations="isTrue"
              validationError="You must agree to terms of service"
            >
              <span>
                By creating an account, I agree to the <Link to="/terms-use">Terms of Use</Link> and acknowledge that I have read the <Link to="/privacy-policy">Privacy Policy</Link>.
              </span>
            </FormCheckBox>
          </div>
          <div className={styles.buttons}>
            <Button
              type="submit"
              color="white"
              title="Create my account"
              disabled={isDisabled}
            />
          </div>
        </Formsy>
      </div>
    </AppContainer>
  );
};
