import React, { ReactNode, useState } from "react";
import styles from "./FaqItem.module.css";
import { ReactComponent as ArrowUpImage } from "../static/arrowUp.svg";
import { ReactComponent as ArrowDownImage } from "../static/arrowDown.svg";

interface Props {
  title: string | ReactNode;
  children: string | ReactNode;
}

export const FaqItem: React.FC<Props> = ({ title, children }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className={styles.root} onClick={() => setOpen(!open)}>
      <div className={styles.header}>
        <h3>{title}</h3>
        {open ? <ArrowUpImage /> : <ArrowDownImage />}
      </div>
      {open && (
        <div className={styles.content}>{children}</div>
      )}
      <hr />
    </div>
  );
};
