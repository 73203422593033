import React from "react";
import styles from "./TermsOfUse.module.css";
import { AppContainer } from "../layout";
import { Link } from "react-router-dom";

export const TermsOfUse: React.FC = () => {
  const mailLink = <a href="mailto:hello@uunn.co.uk">hello@uunn.co.uk</a>;

  return (
    <AppContainer>
      <div className={styles.root}>
        <h1>Onaria Technologies Ltd - Terms and Conditions of Website and App Use</h1>
        {/* Section 1 */}
        <div className={styles.section}>
          <p><i>This policy is drafted in English. If there is a conflict between a translated version and the English version of these terms then, to the extent permitted under applicable law, the English version shall prevail.</i></p>
          <p>PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING OUR WEBSITE AND OUR APP.</p>
          <p>BY USING OUR WEBSITE YOU AGREE THAT SUCH USE IS SUBJECT TO THESE TERMS. BY DOWNLOADING OUR APP YOU AGREE THAT YOUR USE OF THE APP IS SUBJECT TO THESE TERMS.</p>
          <p>This document is a legally binding agreement between you and Onaria Technologies Ltd. This agreement constitutes the terms of use on which you may make use of our uunn app (the “App”) and the www.uunn.co.uk website (together “our Platforms”).</p>
          <p>Please read these terms of use carefully before you use our Platforms. We recommend that you keep a copy of these terms of use for future reference. If you do not agree to these terms, you must not use our website or the App.</p>
          <p>PLEASE NOTE: We provide information on our Platforms to help you get the most out of our products and services. However if you have or suspect you might have an oral or dental health condition the information on our Platforms is not intended to replace medical advice. A qualified dental professional will always be in the best position to assess your condition and make recommendations.</p>
          <p>Please consult our <Link to="/faq">FAQs</Link> page or contact customer service at {mailLink} for any further information you need about our Platforms and the uunn product. You may also contact us if you would like clarification of any of these terms of use.</p>
          <p><b>1. Information about us</b></p>
          <p>Our Platforms are operated by Onaria Technologies Ltd (“we/our/us”). We are a company registered in England and Wales with company number 09750427. Our registered office address is Cambridge House, 16 High Street, Saffron Walden, Essex, England, CB10 1AX. Our registered VAT number is 274 9141 84.</p>
          <p>To contact us, please email {mailLink}.</p>
          <p><b>2. The effect of these terms</b></p>
          <p><b>Ensure that others are aware of these terms of use.</b> You are responsible for ensuring that all persons who access our Platforms through your internet connection or mobile devices are aware of these terms of use and other applicable terms and conditions (such as our privacy policy, cookie policy, or terms of sale), and that those individuals comply with such terms.</p>
          <p><b>We may make changes to these terms.</b> We may amend these terms of use from time to time. Every time you wish to use our Platforms the terms which are in place at that time will apply. Please check these terms of use to ensure you understand the terms that apply at that time.</p>
          <p><b>3. Your rights and obligations when you use our Platforms</b></p>
          <p><b>Licence to use the App.</b></p>
          <p>We license you to use the App and any updates or supplements to it, as permitted in these terms of use. In return for you agreeing to comply with these terms of use you may download a copy of the App onto one handheld device and view, use and display the App on such device for your personal purposes only.</p>
          <p><b>How you may use material on our Platforms.</b></p>
          <p>We are the owner or the licensee of all intellectual property rights in the Platforms, and in the layout, design, content and graphics on our Platforms, and in the material published on them. Those works are protected by copyright laws and treaties around the world. All such rights are reserved.</p>
          <p>The trade mark ‘uunn’ is a UK registered trademarks belonging to us. You may not use these trademarks in any form (including framing, as meta tags or other text) without our prior written approval.</p>
          <p>You may print off one copy, take screenshots or download extracts, of any page(s) from our Platforms for your personal use and you may draw the attention of others within your organisation to content posted on our Platforms.</p>
          <p>You must not modify the copies of any materials you have printed off or downloaded in any way, and you must not use any illustrations, photographs, video or audio sequences or any graphics separately from any accompanying text.</p>
          <p>You may not create any derivative work or make any adaptation to any work displayed on our Platforms without our prior written consent.</p>
          <p>Our status (and that of any identified contributors) as the authors of content on our Platforms must always be acknowledged.</p>
          <p>You must not use any part of the content on our Platforms for commercial purposes without obtaining a licence to do so from us or our licensors.</p>
          <p>If you print off, copy or download any part of our Platforms in breach of these terms of use, your right to use our Platforms will cease immediately and you must, at our option, return or destroy any copies of the materials you have made.</p>
          <p><b>Keep your account details safe</b></p>
          <p>Account details include your name, email address, DOB, shipping address and account password. Your account details may also include your delivery and billing address when you use the www.uunn.co.uk website.</p>
          <p>You must treat your account details as confidential. You must not disclose your account details to any third party.</p>
          <p>We have the right to disable any user identification code or password, whether chosen by you or allocated by us, at any time, if in our reasonable opinion you have failed to comply with any of the provisions of these terms of use.</p>
          <p>If you know or suspect that anyone other than you knows your user identification name or password, you must promptly notify us at {mailLink}.</p>
          <p><b>Accuracy and completeness of information</b></p>
          <p>The content on our Platforms is provided for general information only. It is not intended to amount to advice on which you should rely.</p>
          <p>We recommend that you obtain professional, medical or specialist advice before taking, or refraining from, any action on the basis of the content on our Platforms.</p>
          <p>Although we make reasonable efforts to update the information on our Platforms, we make no representations, warranties or guarantees, whether express or implied that the content on our Platforms is accurate, complete or up to date.</p>
          <p>Please consult the <Link to="/faq">FAQs</Link> page or contact customer service at {mailLink} if you would like us to clarify any of the information provided by us on our Platforms.</p>
          <p>We only provide our Platforms for domestic and private use. You agree not to use our site for any commercial or business purposes, and we have no liability to you for any loss of profit, loss of business, business interruption, or loss of business opportunity.</p>
          <p><b>4. Our rights and obligations when you use our Platforms</b></p>
          <p><b>Termination.</b> We reserve the right to terminate our contract with you, and/or the right to terminate or suspend your access to our Platforms or disable your account, at any time if in our reasonable opinion your use of the Platforms breaches any of our terms and conditions, which can include a breach of these terms of use, or a breach of our Terms Of Sale. If what you have done can be put right we will give you a reasonable opportunity to do so.</p>
          <p>If we end your rights to use the Platforms:</p>
          <ol type="a">
            <li>You must stop all activities authorised by these terms of use, including your use of the Platforms.</li>
            <li>You must delete or remove the App from all devices in your possession and immediately destroy all copies of the App which you have and confirm to us that you have done this.</li>
            <li>We may remotely access your devices and remove the App from them and cease providing you with access to the App.</li>
          </ol>
          <p><b>Privacy.</b> We will only use your personal information as set out in our Privacy Policy. Please be aware that internet transmissions are never completely private or secure and that any message or information you send using the App may be read or intercepted by others, even if there is a special notice that a particular transmission is encrypted.</p>
          <p><b>We are not responsible for website(s)/content we link to.</b> Where our Platforms contain links to other websites and resources provided by third parties, these links are provided for your information only. Such links should not be interpreted as approval by us of those linked websites or information you may obtain from them. We make no representation or warranty, and accept no liability, for material published on any website which is not under our control.</p>
          <p><b>User generated content is not approved by us.</b> Content uploaded to, or linked through, our Platforms should not be interpreted as approval by us of that content or information. We make no representation or warranty, and accept no liability, for material published on our Platforms which is user-generated.</p>
          <p><b>We may make changes to our Platforms.</b> We may automatically update and change our website and our App from time to time to reflect changes to the advice we recommend, information, products, our users' needs and our business priorities, to improve performance, enhance functionality, reflect changes to the operating system or address security issues. Alternatively we may ask you to update the App for these reasons. We will try to give you reasonable notice of any major changes.</p>
          <p>If you choose not to install such updates or if you opt out of automatic updates you may not be able to continue using the App. The App will always work with the current or previous version of the operating system (as it may be updated from time to time) and  match the description of it provided to you when you bought it.</p>
          <p><b>We may suspend or withdraw our Platforms.</b> We do not guarantee that our Platforms, or any content on them, will always be available or be uninterrupted. We may suspend or withdraw or restrict the availability of all or any part of our Platforms where you have breached these terms of use, or our terms of sale. Where, in our view, it is appropriate to do so, we will try to give you reasonable notice of any suspension or withdrawal.</p>
          <p><b>We have rights to use the material you upload.</b> When you upload or post content to our Platforms, you grant us a non-exclusive, worldwide, royalty free, revocable licence to use that content, provided that such use complies with our requirements under privacy and data protection laws.</p>
          <p><b>We may transfer this agreement to someone else.</b> We may transfer our rights and obligations under these terms of use to another organisation. We will always tell you in writing if this happens and we will ensure that the transfer will not affect your rights under the contract.</p>
          <p><b>5. Acceptable use of our Platforms</b></p>
          <p><b>Prohibited uses of our Platforms.</b> The following uses of our Platforms are prohibited:</p>
          <ol type="a">
            <li>Use in any way that breaches any applicable local, national or international law or regulation.</li>
            <li>Use in any way that is unlawful or fraudulent, or has any unlawful or fraudulent purpose or effect.</li>
            <li>Use the purpose of harming or attempting to harm minors in any way.</li>
            <li>Use in order to bully, insult, intimidate or humiliate any person. </li>
            <li>Sending, knowingly receiving, uploading, downloading, using or re-using any material which does not comply with these terms of use.</li>
            <li>Transmitting, or procuring the sending of, any unsolicited or unauthorised advertising or promotional material or any other form of similar solicitation (spam).</li>
            <li>Transmitting any materials which are not your own, or that you do not have a right to transmit.</li>
            <li>Knowingly transmitting any data, send or upload any material that contains viruses, Trojan horses, worms, time-bombs, keystroke loggers, spyware, adware or any other harmful programs or similar computer code designed to adversely affect the operation of any computer software or hardware.</li>
            <li>Impersonating any entity or person, or otherwise falsely state or misrepresent an affiliation with us, our affiliates or any other person or entity.</li>
            <li>Accessing or attempting to access another user’s account without that person’s consent.</li>
            <li>Allowing others to access your account, selling access to, reproducing, duplicating or copying any content on the Platforms and/or your account in contravention of these terms of use.</li>
            <li>Use in a way that could damage, disable, overburden, impair or compromise our systems or security or interfere with other users.</li>
            <li>collecting or harvesting any information or data from our Platforms or our systems or attempt to decipher any transmissions to or from the servers running our Platforms.</li>
          </ol>
          <p>You agree that you will:</p>
          <ol type="a">
            <li>not rent, lease, sub-license, loan, provide, or otherwise make available, the App in any form, in whole or in part to any person without prior written consent from us;</li>
            <li>not copy the App except as part of the normal use of the App or where it is necessary for the purpose of back-up or operational security;</li>
            <li>not translate, merge, adapt, vary, alter or modify, the whole or any part of the App, nor permit the App or any part of it to be combined with, or become incorporated in, any other programs, except as necessary to use the App on devices as permitted in these terms of use;</li>
            <li>not disassemble, de-compile, reverse engineer or create derivative works based on the whole or any part of the App nor attempt to do any such things, except to the extent that (by virtue of sections 50B and 296A of the Copyright, Designs and Patents Act 1988) such actions cannot be prohibited because they are necessary to decompile the App to obtain the information necessary to create an independent program that can be operated with the App or with another program (Permitted Objective), and provided that the information obtained by you during such activities:</li>
            <ol type="i">
              <li>is not disclosed or communicated without our prior written consent to any third party to whom it is not necessary to disclose or communicate it in order to achieve the Permitted Objective; and</li>
              <li>is not used to create any software that is substantially similar in its expression to the App;</li>
              <li>is kept secure; and</li>
              <li>is used only for the Permitted Objective;</li>
            </ol>
            <li>comply with all applicable technology control or export laws and regulations that apply to the technology used or supported by the App.</li>
          </ol>
          <p><b>6. Linking to our Platforms</b></p>
          <p>You may link to our Platforms, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it. You must not establish a link in such a way as to suggest any form of association, approval or endorsement on our part where none exists, and you must not establish a link to our Platforms in any other platform or website that is not owned by you.</p>
          <p>Our Platforms must not be framed on any other platform or website, nor may you create a link to any part of our website other than the home page.</p>
          <p>We reserve the right to withdraw linking permission without notice.</p>
          <p>The platforms and websites in which you are linking must comply in all respects with the acceptable use standards set out under the heading “Acceptable Use” of these terms of use.</p>
          <p>If you wish to link to or make any use of content on our Platforms other than that set out above, please contact {mailLink} for our permission which may, at our sole discretion, be withheld.</p>
          <p><b>7. Your health</b></p>
          <p>We care about your health and all the information we provide is intended to help you make the best decisions about your own care and wellbeing.</p>
          <p>uunn aims to enable customers to better understand how well they are brushing their teeth. It does this by identifying any teeth that have early-level plaque located on them, quantifying the percentage of plaque on the teeth on the facial labial and facial buccal surfaces that can be photographed using the uunn lens at that time, as well as providing an aggregate of these plaque percentages. The sole purpose of this is to help the user understand possible lifestyle interventions that may help to reduce plaque levels.</p>
          <p>However, no two customers are the same, and advice that may be right for one user may not be right for another. There is no replacement for specialist medical advice from a qualified professional and we strongly recommend you seek such advice before using our products, or relying on the information provided via our Platforms. This is particularly important if you have any known pre-existing conditions.</p>
          <p>The information provided by the uunn Platforms does not constitute medical advice and/or recommendations for dental/medical treatment, but instead focuses on general dental care and lifestyle advice.</p>
          <p>The uunn lens should only be used by adults over 16 years old and residents in the UK.</p>
          <p>By agreeing to these terms of use you agree that you will seek specialist medical advice from a qualified professional before using our products and before relying on information provided via our Platform.</p>
          <p><b>8. Our responsibility for loss or damage suffered by you</b></p>
          <p><b>We do not exclude or limit in any way our liability to you where it would be unlawful to do so.</b> This includes liability for death or personal injury caused by our negligence or the negligence of our employees, agents or subcontractors and for fraud or fraudulent misrepresentation.</p>
          <p><b>We are responsible for foreseeable losses.</b> If we fail to comply with these terms of use, we are responsible for loss or damage you suffer that is a foreseeable result of our breaking this contract or our failing to use reasonable care and skill, but we are not responsible for any loss or damage that is not foreseeable. Loss or damage is foreseeable if either it is obvious that it will happen or if, at the time the contract was made, both we and you knew it might happen, for example, if you discussed it with us during the sales process.</p>
          <p><b>Defective content.</b> If the App is defective and damages a device or digital content belonging to you and this is caused by our failure to use reasonable care and skill we will either repair the damage or pay you compensation. However, we will not be liable for damage which you could have avoided by following our advice to apply an update offered to you free of charge or for damage which was caused by you failing to correctly follow installation instructions or to have in place the minimum system requirements advised by us.</p>
          <p><b>We are not responsible for events outside our control.</b> If our provision of support for the App is delayed by an event outside our control then we will contact you as soon as possible to let you know and we will take steps to minimise the effect of the delay. Provided we do this we will not be liable for delays caused by the event  but if there is a risk of substantial delay you may contact us to end your contract with us and receive a refund for any products you have paid for but not received.</p>
          <p><b>Check that the App is suitable for you.</b> The App has not been developed to meet your individual requirements. Please check that the facilities and functions of the App (as described on the App store or Play store site and in the Documentation) meet your requirements.</p>
          <p><b>Please back-up content and data used with the App.</b> We recommend that you back up any content and data used in connection with the App, to protect yourself in case of problems with the App.</p>
          <p><b>9. General Terms</b></p>
          <p><b>No rights for third parties.</b> This agreement does not give rise to any rights under the Contracts (Rights of Third Parties) Act 1999 to enforce any term of this agreement.</p>
          <p><b>If a court finds part of this contract illegal, the rest will continue in force.</b> Each of the paragraphs of these terms of use operates separately. If any court or relevant authority decides that any of them are unlawful, the remaining paragraphs will remain in full force and effect.</p>
          <p><b>Even if we delay in enforcing this contract, we can still enforce it later.</b> If we do not insist immediately that you do anything you are required to do under these terms of use, or if we delay in taking steps against you in respect of your breaking this contract, that will not mean that you do not have to do those things and it will not prevent us taking steps against you at a later date.</p>
          <p><b>You need our consent to transfer your rights to someone else.</b> You may only transfer your rights or your obligations under these terms of use to another person if we agree in writing.</p>
          <p><b>10. Governing law and jurisdiction</b></p>
          <p>These terms of use, their subject matter and their formation, are governed by English law. You and we both agree that the courts of England and Wales will have exclusive jurisdiction except that if you are a resident of Northern Ireland you may also bring proceedings in Northern Ireland, and if you are resident of Scotland, you may also bring proceedings in Scotland.</p>
        </div >
      </div >
    </AppContainer >
  );
};
