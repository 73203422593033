import { Address } from "../../preOrder/components/CheckoutForm";
import environment from "../../../configurations/environment";

export const SUB_FIELD = "sub";
export const EMAIL_FIELD = "email";
export const NAME_FIELD = "name";
export const BIRTH_DATE_FIELD = "birthdate";
export const CARD_FIELD = "custom:cardLast4";
export const ADDRESS_FIELD = "custom:addressLine1";
export const CITY_FIELD = "custom:addressCity";
export const STATE_FIELD = "custom:addressState";
export const COUNTRY_FIELD = "custom:addressCountry";
export const POSTAL_CODE_FIELD = "custom:addressPostalCode";

export interface SubscriptionPlan {
  priceId: string;
  amount: number;
  price: string;
  recommended?: boolean;
}

export interface ActiveSubscription {
  id: string;
  perMonth: string;
  amount: number;
  nextBillingDate: string;
  status: "Active" | "PendingCancel" | "Cancelled" | "PaymentFailed";
  updatedAt: string;
}

export const AVAILABLE_PLAN_SUBSCRIPTIONS: SubscriptionPlan[] = [
  {
    priceId: environment.subscriptionPlanIds["plan1"],
    amount: 2,
    price: "£5.00",
    recommended: true
  },
  {
    priceId: environment.subscriptionPlanIds["plan2"],
    amount: 4,
    price: "£7"
  },
  {
    priceId: environment.subscriptionPlanIds["plan3"],
    amount: 8,
    price: "£10"
  }
];

export interface BillingInformation {
  name: string;
  address: Address;
  card: {
    last4: string;
    tokenId?: string;
    paymentMethodId?: string;
  }
}
