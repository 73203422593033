import { withFormsy, FormsyInjectedProps } from 'formsy-react';
import React, { useState } from 'react';
import styles from "./Input.module.css";
import { ReactComponent as ShowTextImage } from "./static/show-text.svg";
import { ReactComponent as HideTextImage } from "./static/hide-text.svg";
import { InputError } from './InputError';

export type InputProps = FormsyInjectedProps<string> & {
  label: string;
  placeholder?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const FormPassword = (props: InputProps) => {
  // An error message is passed only if the component is invalid
  const { value, errorMessage, label, isRequired, name, isFormSubmitted, placeholder, onChange } = props;
  const error = isFormSubmitted && isRequired && !value ? "Info missing!" : isFormSubmitted && errorMessage ? errorMessage : undefined;
  const id = Math.random().toString(36).substring(7);
  let classes = `${styles.root}`;

  if (error) {
    classes += ` ${styles.error}`;
  }

  const [showText, setShowText] = useState(false);

  return (
    <div className={`${classes}`}>
      {label && (
        <div className={styles.label}>
          <label htmlFor={id}>{label}</label>             
        </div>
      )}
      <div className={styles.buttonContainer}>
        <input
          onChange={(event) => {
            props.setValue(event.currentTarget.value);
            onChange?.(event);
          }} 
          type={showText ? "text" : "password"} 
          value={props.value || ''}
          placeholder={placeholder}
          name={name}
          id={id}
        />
        {(!showText) && <ShowTextImage onClick={() => setShowText(true)} />}
        {(showText) && <HideTextImage onClick={() => setShowText(false)} />}
      </div>
      {error && <InputError error={error as string} className={styles.errorText} />}
    </div>
  );
};
  
export default withFormsy(FormPassword);
