import axios from "../../../network";
import { ActiveSubscription } from "../models";

interface GetBalanceResponse {
  balance: number;
  loading: boolean;
  subscription?: ActiveSubscription;
}

export const getBalance = async (): Promise<GetBalanceResponse> => {
  return (await axios.get(`/balance`)).data;
};

export const createSubscription = async (paymentMethodId: string, priceId: string): Promise<void> => {
  return axios.post(`/subscriptions`, { paymentMethodId, priceId });
};

export const updateSubscription = async (subscriptionId: string, priceId: string): Promise<void> => {
  return axios.put(`/subscriptions/${subscriptionId}`, { priceId });
};

export const deleteSubscription = async (subscriptionId: string): Promise<void> => {
  return axios.delete(`/subscriptions/${subscriptionId}`);
};

export const updateCardNumber = async (userId: string, paymentMethodId: string): Promise<void> => {
  return axios.put(`/users/${userId}/cardNumber`, { paymentMethodId });
};
