import { configureStore } from "@reduxjs/toolkit";
import cartCounterReducer from "../app/layout/header/reducers/cartCounterSlice";

const store = configureStore({
  reducer: {
    cartCounter: cartCounterReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store;
