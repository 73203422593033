export const testimonialsExperts = {
  title: "Here’s what our community had to say:",
  testimonials: [
    {
      name: "Marius",
      text: "“I already pay a lot of attention to my teeth but now I can quantify that effort.”"
    },
    {
      name: "Mylen",
      text: "“My mouth hides my canines and leaves more plaque there, which I now realise thanks to uunn.”"
    },
    {
      name: "Adrian",
      text: "“My plaque level is 0.8%. Woohoo I’ve been brushing my teeth well!”"
    },
    {
      name: "Wendi",
      text: "“I had more plaque on the left side of my mouth so I’m going to spend more time brushing there now.”"
    }
  ]
};

export const testimonialsPeople = {
  title: "Word of mouth",
  testimonials: [
    {
      name: "Name",
      text: "“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.”"
    },
    {
      name: "Name",
      text: "“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.”"
    },
    {
      name: "Name",
      text: "“Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.”"
    }
  ]
};
