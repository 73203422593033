import React, { Component } from "react";
import styles from "./CheckoutForm.module.css";
import { Button, Steps } from "../../shared";
import CartStep, { Product } from "./CartStep";
import PaymentStep from "./PaymentStep";
import CountryOptions from "../../shared/select/models/countries.json";
import AddressStep from "./AddressStep";
import ReviewStep from "./ReviewStep";
import { ElementsConsumer } from "@stripe/react-stripe-js";
import { Stripe } from "@stripe/stripe-js";
import { createPayment, getProducts } from "../services";
import { connect } from "react-redux";
import { reset } from "../../layout/header/reducers/cartCounterSlice";
import { RootState } from "../../../store";
import { InputError } from "../../shared/input/InputError";

export type Address = {
  line?: string,
  postalCode?: string,
  stateProvence?: string,
  city?: string,
  country?: string
}

export type FormModel = {
  firstName?: string,
  lastName?: string,
  email?: string,
  shippingAddress?: Address,
  billing?: {
    sameAsShipping?: boolean,
    address?: Address,
    card?: {
      token: string,
      last4: string
    },
  },
  subscribeNewsLetter?: boolean,
  selectedProduct?: Product,
  coupon?: {
    id: string,
    amount: number,
    code: string    
  }
}

type CheckoutFormState = {
  // Control fields
  step: number,
  clientSecret: string,
  paymentId: string,
  submitError: string,
  formModel: FormModel,
  products: Array<Product>,
  disabled: boolean,
  loading: boolean,
  shippingCountries: Array<any>,
  billingCountries: Array<any>,
}

class CheckoutForm extends Component<any, CheckoutFormState> {
  state: CheckoutFormState = {
    // Control fields
    step: 1,
    formModel: {},
    submitError: "",
    clientSecret: "",
    paymentId: "",
    products: [],
    loading: true,
    disabled: false,
    shippingCountries: CountryOptions.filter((country => country["alpha-2"] === "GB" )).map((country) => {
      return { value: country["alpha-2"], name: country.name };
    }),
    billingCountries: CountryOptions.map((country) => {
      return { value: country["alpha-2"], name: country.name };
    }),
  };  

  componentDidMount = () => {
    getProducts().then(
      (products) => {
        //console.log(this.props, products)
        this.setState((prevState) => ({         
          // @ts-ignore
          loading: false,
          products: products,
          formModel: {
            ...prevState.formModel,
            selectedProduct: this.props.cartCounter > 0 ? products.find(it => it.amount === this.props.cartCounter) : products[0]
          }
        }));
      }
    );
  }

  async handleSubmit(step: number) {
    const hasNoErrors = Object.keys({}).length === 0;

    this.setState({  }, async () => {
      if (hasNoErrors) {
        this.setState({ disabled: true, submitError: "" }, async () => {
          if (step === 1) {
            const selectedProduct = this.state.formModel.selectedProduct;

            if (selectedProduct) {
              createPayment(selectedProduct.value).then(
                (response) => {
                  this.setState({ 
                    step: 2,
                    disabled: false,        
                    clientSecret: response.clientSecret,
                    paymentId: response.paymentId
                  });
                }
              ).catch(err => this.setState({ submitError: err.response.data.message ?? "Error occurred" }));
            }
          } else if (step === 2) {      
            this.setState({ 
              step: 3,
              disabled: false
            });  
          } else if (step === 3) {    
            this.setState({
              step: 4,
              disabled: false
            });            
          } else if (step === 4) {  
            const stripe: Stripe = this.props.stripe;
          
            const token = this.state.formModel.billing?.card?.token;

            if (!token) {
              return;
            }

            const { paymentIntent, error } = await stripe.confirmCardPayment(this.state.clientSecret, {
              // eslint-disable-next-line camelcase
              payment_method: {
                card: {
                  token
                },
              }
            });

            if (error) {
              console.error(error.message);
              this.setState({ submitError: error.message ?? "Error occurred" })
            } else if (paymentIntent) {
              console.log(JSON.stringify(paymentIntent));
              this.setState({ step: 5 });
            }
          }

          // Scroll to top for next steps or to see errors
          window.scrollTo(0, 0);
        });
      }
    });
  }

  handlePrevious() {
    const { step } = this.state;
    const newPage = step - 1;

    if (newPage >= 1 && newPage <= 3) {
      this.setState({ step: newPage, submitError: "" });
    }
  }

  goToStep(step: number) {
    this.setState({ step });
  }

  render() {
    const { step, billingCountries, shippingCountries, disabled, submitError } = this.state;

    return (
      <div className={styles.root}>
        <div className={styles.header}>
          {step <= 4 ? (
            <Steps
              disabled={disabled}
              steps={["Cart", "Shipping", "Payment", "Review"]}
              onClick={step => this.setState({ step, submitError: "" })}
              currentStep={step}
            />
          ) : (
            <h1>Hurrah! Your order has been confirmed.</h1>
          )}
          {step === 1 && (
            <h1>Your cart</h1>
          )}
          {step === 2 && (
            <h1>Tell us a little about you</h1>
          )}
          {step === 3 && (
            <h1>What are your card details?</h1>
          )}
          {step === 4 && (
            <h1>Everything look good?</h1>
          )}
          {step <= 4 && (
            <hr />
          )}
        </div>
        <div className={styles.content}>
          {submitError && <InputError error={submitError} />}
          {step === 1 && (
            <CartStep 
              submitStep={(step) => this.handleSubmit(step)}
              loading={this.state.loading}
              products={this.state.products} 
              selectedProduct={this.state.formModel.selectedProduct} 
              updateProduct={(product) => {
                this.setState((prevState) => ({
                  formModel: {
                    ...prevState.formModel,
                    selectedProduct: product
                  }
                }));
              }} 
            />
          )}
          {step === 2 && (
            <AddressStep
              previous={() => this.handlePrevious()}
              shippingCountries={shippingCountries}
              submitStep={(step) => this.handleSubmit(step)}
              formModel={this.state.formModel}
              setFormModel={(model) => {
                this.setState((prevState) => ({
                  formModel: {
                    ...prevState.formModel,
                    ...model
                  }
                }));
              }}
            />
          )}
          <div style={{ display: step === 3 ? "block" : "none" }}>
            <PaymentStep
              previous={() => this.handlePrevious()}
              billingCountries={billingCountries}
              submitStep={(step) => this.handleSubmit(step)}
              formModel={this.state.formModel}
              setFormModel={(model) => {
                this.setState((prevState) => ({
                  formModel: {
                    ...prevState.formModel,
                    ...model
                  }
                }));
              }}
            />
          </div>
          {step === 4 && (
            <ReviewStep
              goToStep={(step) => this.goToStep(step)}
              paymentId={this.state.paymentId}
              submitStep={(step) => this.handleSubmit(step)}
              products={this.state.products} 
              updateProduct={(product) => {
                this.setState((prevState) => ({
                  formModel: {
                    ...prevState.formModel,
                    selectedProduct: product
                  }
                }));
              }}
              selectedProduct={this.state.formModel.selectedProduct}
              formModel={this.state.formModel}              
              setFormModel={(model) => {
                this.setState((prevState) => ({
                  formModel: {
                    ...prevState.formModel,
                    ...model
                  }
                }));
              }}
            />            
          )}
          {step === 5 && (
            <div className={styles.step4}>
              <p>We’re so happy that you’ve joined our comm<i>uunn</i>ity.</p>
              <p>You can expect an order confirmation email from us in your inbox (check your spam just in case) and very soon,<br />we’ll send another to let you know that your order is on its way!”</p>
              <Button
                color="blue"
                title="Return Home"
                to="/"
                fullWidth
                className={styles.button}
                onClick={() => this.props.resetCardCounter()}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

const CheckoutFormConnected = connect((state: RootState) => ({
  cartCounter: state.cartCounter.value
}), {
  resetCardCounter: () => reset(),
})(CheckoutForm);

const InjectedCheckoutForm = () => {
  return (
    <ElementsConsumer>
      {({ elements, stripe }) => (
        <CheckoutFormConnected elements={elements} stripe={stripe} />
      )}
    </ElementsConsumer>
  );
};

export default InjectedCheckoutForm;
