import "react-app-polyfill/ie11";
import "react-app-polyfill/stable"; // polyfills: leave as first import
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import smoothscroll from "smoothscroll-polyfill";
import store from "./store";
import { Provider } from "react-redux";

// Remove when behaviour smooth is supported in Safari: https://caniuse.com/#feat=scrollintoview
smoothscroll.polyfill();

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
