import Formsy from "formsy-react";
import React from "react";
import FormCheckBox from "../../shared/checkbox/FormCheckBox";
import FormInput from "../../shared/input/FormInput";
import FormSelect from "../../shared/select/FormSelect";
import { FormModel } from "./CheckoutForm";
import styles from "./AddressStep.module.css";
import { Button } from "../../shared";
import { Link } from "react-router-dom";

type AddressStepProps = {
  shippingCountries: Array<{ name: string, value: string }>,
  previous: () => void,
  submitStep: (step: number) => void,
  setFormModel: (formModel: FormModel) => void,
  formModel: FormModel
}

const AddressStep = (props: AddressStepProps) => {
  return (
    <Formsy
      onValidSubmit={(model) => {
        props.submitStep(2);
        props.setFormModel({
          firstName: model.step1FirstName,
          lastName: model.step1LastName,
          email: model.step1Email,
          subscribeNewsLetter: model.step1SubscribeNewsletter,
          shippingAddress: {
            line: model.step1Line1,
            city: model.step1City,
            country: model.step1Country,
            stateProvence: model.step1StateProvence,
            postalCode: model.step1PostalCode
          }
        });
      }}
    >
      <div className={styles.root}>
        <div className={styles.halfWidth}>
          <FormInput
            name="step1FirstName"
            label="First name *"
            value={props.formModel.firstName}
            required
          />
          <FormInput
            name="step1LastName"
            value={props.formModel.lastName}
            required
            label="Last name *"
          />
        </div>
        <div className={styles.halfWidth}>
          <FormInput
            name="step1Email"
            label="Email address *"
            value={props.formModel.email}
            validations="isEmail"
            validationError="Invalid email"
            required
          />
          <FormInput
            name="step1EmailConfirm"
            label="Confirm email address *"
            value={props.formModel.email}
            validations={{
              isEmail: true,
              equalsField: "step1Email" }}
            validationErrors={{
              isEmail: "Invalid email",
              equalsField: "Emails don't match"
            }}
            required
          />
        </div>
        <div className={styles.marginBottom}>
          <FormCheckBox
            name="step1SubscribeNewsletter"
            value={props.formModel.subscribeNewsLetter || false}
          >
            <span>
              Tick this box to receive the latest happenings, exclusive deals and top-tips in your inbox.<br />No spam, we promise. Unsubscribe anytime. For more information, see our <Link to="/privacy-policy">Privacy Policy</Link>.
            </span>
          </FormCheckBox>
        </div>
        <div className={styles.halfWidth}>
          <FormSelect
            name="step1Country"
            label="Country *"
            value={props.formModel.shippingAddress?.country}
            options={props.shippingCountries}
          />
          <FormInput
            name="step1Line1"
            label="Street address *"
            value={props.formModel.shippingAddress?.line}
            required
          />
        </div>
        <div className={styles.halfWidth}>
          <FormInput
            name="step1City"
            label="Town/city *"
            value={props.formModel.shippingAddress?.city}
            required
          />   
          <FormInput
            name="step1StateProvence"
            label="State/province (optional)"
            value={props.formModel.shippingAddress?.stateProvence}
          />                            
        </div>
        <div className={styles.halfWidth}>
          <FormInput
            name="step1PostalCode"
            label="Post code / ZIP code *"
            value={props.formModel.shippingAddress?.postalCode}
            required
          />    
        </div>
        <div className={styles.marginBottom}>
          <FormCheckBox name="step1Shipping" value disabled>
            Standard UK second class (2-3 days delivery)
          </FormCheckBox>
        </div>
        <div className={styles.buttons}>
          <Button
            color="white"
            title="Back"
            onClick={() => props.previous()}
            fullWidth
          />
          <Button
            type="submit"
            color="blue"
            title="Next"
            fullWidth
          />
        </div>
      </div>
    </Formsy>
  );
};

export default AddressStep;
