/**
 * This method will scroll smoothly to the anchor link.
 * If the element is found it will play the animation and prevent the default behaviour.
 * @param {*} event the link event
 */
export const animateOnAnchorClick = (event) => {
  const elementName = event.currentTarget.href &&
    event.currentTarget.href.substring(event.currentTarget.href.indexOf("#") + 1);
  const element = document.getElementById(elementName);

  if (element) {
    event.preventDefault();
    element.scrollIntoView({ behavior: "smooth", block: "start" });
  }

  return true;
};
